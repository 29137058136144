import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import Dialog from '../../services/Dialogs'



import moment from 'moment'
import 'moment/locale/es'



const PlaneacionCaptura = () =>{



  const dialog = new Dialog();



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  // const currentUser  = AuthService.getCurrentUser();

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint('planeacion/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip+'&limit='+sessionsPerPage)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([false,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([false,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false])
  }






      React.useEffect(() => {
        busqueda()
      }, []);







const confirmarMandarCaptura = async(e:any, uuid:any) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: '¿Estás Seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`programa/enviar_validacion/${uuid}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Programa')
            cargarMiReporte(currentPage===0?0:currentPage-1)
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas')
    }

  }





      return (
          <>


            <div className="container">

            <h4>Captura de Información de Programas</h4>


            <div className="row">
              <div className="col-12">

                      <div className="mt-">
                        <label className="form-label">Búsqueda</label>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                          <button onClick={busqueda} className="btn btn-default" type="button" ><i className="fa fa-search" /></button>
                        </div>
                      </div>

                      <div>

                        <Form.Check inline name="group1" type="checkbox" label="EN CONFIGURACIÓN" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_0`} />
                        <Form.Check inline name="group1" type="checkbox" label="CAPTURA DE LA DIRECCIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                        <Form.Check inline name="group1" type="checkbox" label="EN RECTIFICACIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                        <Form.Check inline name="group1" type="checkbox" label="EN VALIDACIÓN" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                        <Form.Check inline name="group1" type="checkbox" label="AUTORIZADA" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                        <Form.Check inline name="group1" type="checkbox" label="ARCHIVADA" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                        <Form.Check inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />



                      </div>
                      

                    <ul className="list-inline">
                      <li className="list-inline-item"><small className="cursor" onClick={()=>{todos()}}>Todos</small> / <small className="cursor" onClick={()=>{ninguno()}}>Ninguno</small></li>
                    </ul>





                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Programa</th>
                                              <th scope="col">Eje</th>
                                              <th scope="col">Dirección</th>
                                              <th scope="col">Ejercicio</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (programa:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-center" style={{"width":"140px"}}>
                                                            



                                                      {
                                                        programa.idestatus === 1 && (
                                                            <span className={'badge cursor estatus_'+programa.idestatus }> {programa.estatus}</span>
                                                        )
                                                      }


                                                      {
                                                        programa.idestatus === 2 && (
                                                            <span className={'badge cursor estatus_'+programa.idestatus }> {programa.estatus}</span>
                                                        )
                                                      }

                                                      {
                                                        programa.idestatus === 3 && (
                                                            <span className={'badge cursor estatus_'+programa.idestatus }> {programa.estatus}</span>
                                                        )
                                                      }

                                                      {
                                                        programa.idestatus === 4 && (
                                                            <span className={'badge cursor estatus_'+programa.idestatus }> {programa.estatus}</span>
                                                        )
                                                      }



                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><small><b>{programa.clave_programa}</b></small></p>
                                                        <small>{programa.nombre_programa}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <small>{programa.nombre_eje.toUpperCase()}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <small>{programa.direccion}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"70px"}}>
                                                        <small>{programa.ejercicio_presupuestal}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      {
                                                        [2].indexOf(programa.idestatus) > -1 && (
                                                            <button onClick={(e:any)=>{ confirmarMandarCaptura(e,programa.uuid_programa) }} className="btn btn-sm btn-warning"><i className="fa fa-toggle-right"/></button>
                                                          )
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        
                                                      {
                                                        [2].indexOf(programa.idestatus) > -1 && (
                                                            <button onClick={(e:any)=>{ console.log(e,programa.uuid_programa) }}  className="btn btn-sm btn-primary"><i className="fa fa-keyboard-o"/></button>
                                                          )
                                                      }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




              </div>

            </div>


            </div>
              






          </>

      );
  

}

export default PlaneacionCaptura
