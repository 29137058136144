import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'

import moment from 'moment'




const mensajes_validacion_generico = "Este campo es obligatorio"

const ModalActualizarMemo = ( params : any ) => {

  const { show, handleClose, movimiento } = params


  const dialog = new Dialog();


  const schema = yup.object().shape({
          memorandum : yup.string().required(mensajes_validacion_generico),
  });


const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `memorandum/${movimiento.uuid_movimiento}` , { ...registro })

        if(res_){
          toast.warning('El Memorandum fue actualizado correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  return (
    <>
      <Modal show={show} className="fade" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambio de Memorandum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(movimiento)
          }

          <h6>Empleado</h6>
          <p className="mb-2">{movimiento.nombres} {movimiento.paterno} {movimiento.materno}</p>

          <h6>Movimiento</h6>
          <p className="mb-2">{movimiento.movimiento}</p>



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            memorandum : movimiento.memorandum,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="memorandum">
              <Form.Label>Memorandum</Form.Label>
              <Form.Control
                type="text"
                required
                name="memorandum"
                value={values.memorandum}
                onChange={(e)=>{ setFieldValue('memorandum', e.target.value ) }}
                isInvalid={!!errors.memorandum}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




 


        </Form>
      )}
    </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalActualizarMemo;