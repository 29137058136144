import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'



import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'


const ModalAsignarFechaFalta = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, incidencia } = params


  const [startDate, setStartDate] = useState<any>( incidencia.fecha_aplica_nomina !== null ? new Date(incidencia.fecha_aplica_nomina) : new Date(incidencia.fecha_retardo));



  const dialog = new Dialog();

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const enviarFormulario = async (event:any,fecha:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `incidencias/asignar_fecha/${incidencia.uuid_incidencia}` , { fecha_asignacion : moment(fecha).format('YYYY-MM-DD') })

        if(res_){
          toast.success('La fecha fue asignada')
          setTimeout(()=>{
            // cargar()
            handleClose(moment(fecha).format('YYYY-MM-DD') )
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}



 



  return (
    <>
      <Modal show={show} size="sm" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(incidencia.fecha_aplica_nomina)
          }
          {
            // JSON.stringify(startDate)
          }

          <p className="nm"><b>Número de Empleado</b></p>
          <p className="nm"><small>{incidencia.numero_empleado}</small></p>

          <p className="nm"><b>Nombre(s)</b></p>
          <p className="nm"><small>{incidencia.nombres}</small></p>

          <p className="nm"><b>Apellidos</b></p>
          <p className="nm"><small>{incidencia.paterno} {incidencia.materno}</small></p>


          <br/>
          <p className="nm"><b>Tipo de Incidencia</b></p>
          <p className="nm"><small>{incidencia.retardo ? 'RETARDO' : 'FALTA'}</small></p>
 
          <p className="nm"><b>Fecha de la Incidencia</b></p>
          <p className="nm"><small>{moment(incidencia.fecha_retardo).format('DD/MM/YYYY') }</small></p>
 

  <br/>

          <div className="mb-3">
            <Form.Label>Fecha para Aplicar</Form.Label>
            <DatePicker className="form-control"  dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />

          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e:any)=>{handleClose(null)}}>
            Cancelar
          </Button>
          <Button variant="success" type="button" onClick={(e:any)=>{enviarFormulario(e,startDate)}}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAsignarFechaFalta;