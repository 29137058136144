import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'


const ModalResponderOficio = ( params : any ) => {

  const { show, handleClose, oficio } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = 'Este campo es obligatorio'
  const schema = yup.object().shape({
          folio_respuesta : yup.string(),
          comentarios_respuesta : yup.string(),
          // fecha_respuesta : yup.string().required(mensajes_validacion_generico),
  });


const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (nuevo_registro:any,event:any,) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update(`respuesta_oficio/${oficio.uuid}`, { ...nuevo_registro })

        if(res_nuevo_usuario){
          toast.success('La recepción de oficio de actualizó Correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Responder Oficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>Remitente</small>
          <h6>{oficio.remitente}</h6>
          <small>Asunto</small>
          <h6>{oficio.asunto}</h6>
          <small>Número de Oficio</small>
          <h6>{oficio.numero_oficio}</h6>
          {
            // JSON.stringify(oficio)
          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

          folio_respuesta : "",
          comentarios_respuesta : ""
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }


            <div className="row">
              <div className="col-sm-6">
                  <Form.Group   className="mb-3" controlId="folio">
                    <Form.Label>Folio de la Respuesta</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="folio_respuesta"
                      value={values.folio_respuesta}
                      onChange={(e)=>{ setFieldValue('folio_respuesta', (e.target.value || "").toUpperCase()) }}
                      isInvalid={!!errors.folio_respuesta}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
              </div>
            </div>





            <Form.Group   className="mb-3" controlId="asunto">
              <Form.Label>Observación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="comentarios_respuesta"
                value={values.comentarios_respuesta}
                onChange={(e)=>{ setFieldValue('comentarios_respuesta', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.comentarios_respuesta}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalResponderOficio;