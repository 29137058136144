import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Form, Col } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'



const ModalCreacionCapturaNuevoPrograma = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ programas_presupuestos, setProgramasPresupuestos] = useState<any>([])

  const cargarProgramasPresupuestos = async() => {

    try{
      let res = await RequestService.getEndPoint('opciones/programas_presupuestos')
      setProgramasPresupuestos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }

  const [ ejes, setEjes] = useState<any>([])

  const cargarEjes = async() => {

    try{
      let res = await RequestService.getEndPoint('opciones/ejes')
      setEjes(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }

  const [ direcciones, setDirecciones] = useState<any>([])

  const cargarDirecciones = async() => {

    try{
      let res = await RequestService.getEndPoint('opciones/direcciones')
      setDirecciones(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }

  React.useEffect(() => {
    cargarProgramasPresupuestos()
    cargarEjes()
    cargarDirecciones()
  }, []);


var mensaes_validacion_generico = 'Este campo es obligatorio'




  const dialog = new Dialog();


const schema = yup.object().shape({
  ejercicio_presupuestal: yup.number().required(mensaes_validacion_generico),
  idprograma: yup.string().required(mensaes_validacion_generico),
  ideje: yup.string().required(mensaes_validacion_generico),
  iddireccion: yup.string().required(mensaes_validacion_generico),
});

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (nuevo_registro:any,event:any,) =>{
      await dialog.open({
        accept: 'Sí, Crear',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `---`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res = await RequestService.create(`matriz_indicador_resultados/crear`, {   ...nuevo_registro })

        if(res){
          toast.warning('El Registro para Captura de Programa fue creado correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const currentYear = new Date().getFullYear();
  const options = [
    { value: currentYear - 1, label: `Año pasado (${currentYear - 1})` },
    { value: currentYear, label: `Año actual (${currentYear})` },
    { value: currentYear + 1, label: `Año siguiente (${currentYear + 1})` },
  ];


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Captura Registro de Programa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(programas_presupuestos)
          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

        ejercicio_presupuestal: currentYear,
        idprograma: "",

        ideje: "",
        iddireccion: "",



      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        
        {
          // JSON.stringify(values)
        }

          <h5>Información Básica</h5>



            <Form.Group  className="mb-3">
              <Form.Label>Selecciona el Ejercicio Presupuestal </Form.Label>
              <Form.Select value={values.ejercicio_presupuestal} onChange={(e)=>{ setFieldValue('ejercicio_presupuestal', (e.target.value) ); }}>
                  <option value="" disabled={true}>Selecciona una opción</option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>


            <Form.Group  className="mb-3">
              <Form.Label>Selecciona el Programa </Form.Label>
                <Form.Select value={values.idprograma} isInvalid={!!errors.idprograma} required name="idprograma" onChange={(e)=>{ setFieldValue('idprograma', (e.target.value) ); }}>
                  <option value="" disabled={true}>Selecciona una opción</option>
                  {
                    programas_presupuestos.map( (programa:any,indice_programa:number) =>
                        <option key={indice_programa} value={programa.uuid}>{programa.clave_programa} {programa.nombre_programa}</option>
                      )
                  }
                </Form.Select>
              <Form.Control.Feedback type="invalid"> {<>{errors.idprograma}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group  className="mb-3">
              <Form.Label>Selecciona el Eje </Form.Label>
                <Form.Select isInvalid={!!errors.ideje} value={values.ideje} required name="ideje" onChange={(e)=>{ setFieldValue('ideje', (e.target.value) ); }}>
                  <option value="" disabled={true}>Selecciona una opción</option>
                  {
                    ejes.map( (eje:any,indice_eje:number) =>
                        <option key={indice_eje} value={eje.uuid}>{indice_eje+1} - {eje.nombre_eje}</option>
                      )
                  }
                </Form.Select>
              <Form.Control.Feedback type="invalid"> {<>{errors.ideje}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group  className="mb-3">
              <Form.Label>Selecciona la Dirección </Form.Label>
                <Form.Select value={values.iddireccion} required name="iddireccion" isInvalid={!!errors.iddireccion} onChange={(e)=>{ setFieldValue('iddireccion', (e.target.value) ); }}>
                  <option value="" disabled={true}>Selecciona una opción</option>
                  {
                    direcciones.map( (direccion:any,indice_direccion:number) =>
                        <option key={indice_direccion} value={direccion.uuid}>{direccion.direccion}</option>
                      )
                  }
                </Form.Select>
              <Form.Control.Feedback type="invalid"> {<>{errors.iddireccion}</>} </Form.Control.Feedback>
            </Form.Group>



        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCreacionCapturaNuevoPrograma;