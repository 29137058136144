import React, { useState } from 'react';

import RequestService from "../../services/request.service";


import {Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalFaltasRetardos = ( params : any ) => {

  const dialog = new Dialog()

  const [key, setKey] = useState('home');

  const { show, handleClose, empleado } = params

const [retardo, setRetardo] = useState<boolean>(false);
const [justificada, setJustificada] = useState<boolean>(false);
const [detalles, setDetalles] = useState<string>('');
const [startDate, setStartDate] = useState(new Date());
const [startDate_aplicar, setStartDate_aplicar] = useState(new Date());



  const [ cargando_, setCargando ] = useState( false )

  const enviar = async()=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.getEndPointPost('reportar/faltas_retardos/'+empleado.uuid_ciudadano, { fecha : startDate,fecha_aplicar : startDate_aplicar, retardo : retardo, observaciones : detalles, justificada : justificada })
        if(res.data){
          toast.success('La falta/retardo fueron notificados al departamento de Recursos Humanos')        
          setJustificada(false); setRetardo(false); setDetalles(''); setStartDate(new Date()); 
          cargarFaltas()
          // handleClose()
        }
        setCargando(false)
      }
      catch(e:any){
        setCargando(false)
        // console.log(e.response.data)
        toast.error(e.response.data+ '. Problemas al intentar notificar a Recursos Humanos')
      }
    }
  }


      React.useEffect(() => {
        cargarFaltas()
      }, []);
  const [ lista_faltas, setListaFaltas ] = useState<any>({
    historico_incidencias : [],
    faltas : [],
    faltas_movimiento : null,
    retardos : [],
    retardos_movimiento : null
  })

  const cargarFaltas = async()=>{
      try{
        setCargando(true)
        let res = await RequestService.getEndPoint('faltas/'+empleado.uuid_ciudadano)
        if(res.data){
          setListaFaltas(res.data)
        }
        setCargando(false)
      }
      catch(e:any){
        setCargando(false)
        // console.log(e.response.data)
        toast.error(e.response.data+ '. Problemas al intentar notificar a Recursos Humanos')
      }
  }


 const eliminarFalta = async(uuid_falta:string)=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.delete('falta_retardo/'+uuid_falta)
        if(res.data){
          toast.warning('Esta registro fue eliminado de la lista')        
          cargarFaltas()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar la lista')
      }
    }
  }





const [ envio_server, setSeEnvio ] = useState<boolean>(false)



const eliminarMovimiento = async (event:any,uuid_movimiento:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `movimiento/eliminar/${uuid_movimiento}`)

        if(res_){
          toast.warning('El movimiento fue eliminado',{ icon : '⚠️' })
          setTimeout(()=>{
            cargarFaltas()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con el servidor")
          }
          setSeEnvio(false)

        }
        
      }
     
}




  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Faltas y Retardos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }

            <h5>Empleado</h5>


                <small>Número de Empleado</small>
                <h6>{empleado.numero_empleado}</h6>

            <div className="row">
              <div className="col-sm-4">
                <small>Nombre(s)</small>
                <h6>{empleado.nombres}</h6>
              </div>
              <div className="col-sm-4">
                <small>Apellido Paterno</small>
                <h6>{empleado.paterno}</h6>
              </div>
              <div className="col-sm-4">
                <small>Apellido Materno</small>
                <h6>{empleado.materno}</h6>
              </div>
            </div>

            <hr className="border border-primary border-3 opacity-75 mb-4"/>


            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k:any) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="home" title="Registro Faltas y Retardos">


                <div className="row">
                  <div className="col-sm-6">
                        <div className="mb-3">
                          <label className="form-label">Fecha del Retardo</label>
                          <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={startDate}   onChange={(date:any) => setStartDate(date)} />
                        </div>
                  </div>
                  <div className="col-sm-6">
                        <div className="mb-3">
                          <label className="form-label">Fecha para aplicar la falta</label>
                          <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={startDate_aplicar} disabled={ retardo || justificada }   onChange={(date:any) => setStartDate_aplicar(date)} />
                        </div>
                  </div>
                </div>
                




                    <div className="mb-3">
                      <Form.Check
                        checked={retardo}
                        onChange={(e:any)=>{setRetardo(e.target.checked)}}
                        type="checkbox"
                        id={`default-retardo`}
                        label={`Marcar como retardo`}
                      />
                    </div>

                    <div className="mb-3">
                      <Form.Check
                        checked={justificada}
                        onChange={(e:any)=>{setJustificada(e.target.checked)}}
                        type="checkbox"
                        id={`default-justificada`}
                        label={`Marcar como justificada`}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="razon" className="form-label">Detalles de la falta:</label>
                      <textarea className="form-control" name="observaciones" value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>


              </Tab>
              <Tab eventKey="profile" title="Faltas y Retardos Registradas">
                
                  {
                    // JSON.stringify(lista_faltas)
                  }


                  {
                    (lista_faltas.faltas.length == 0 && lista_faltas.retardos.length == 0 ) && (

                      <React.Fragment>

                        <p> <i className="fa fa-warning"/> No hay registradas faltas ni retardos en esta quincena</p>

                      </React.Fragment>

                    )
                  }




                  {
                    lista_faltas.faltas.length > 0 && (

                      <React.Fragment>

                          
                          {
                            // JSON.stringify(lista_faltas.faltas_movimiento)
                          }
                          <small>Faltas</small>
                          <h6>Memorandum {lista_faltas.faltas_movimiento.memorandum} <i onClick={(e:any)=>{eliminarMovimiento(e,lista_faltas.faltas_movimiento.uuid)}} className="fa fa-trash pointer"/></h6>

                          <table className="table">
                            <thead style={{fontSize:10}}>
                              <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Fecha Aplicar</th>
                                <th scope="col">Detalles</th>
                                <th scope="col">Justificado</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                                {
                                  lista_faltas.faltas.map((falta:any)=>
                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                                    <td className="v" style={{width:100}}>{moment(falta.fecha_retardo).format('YYYY-MM-DD')}</td>
                                    <td className="v" style={{width:100}}>{ falta.fecha_aplica_nomina ? moment(falta.fecha_aplica_nomina).format('YYYY-MM-DD') : ''}</td>
                                    <td className="v" >{falta.detalles}</td>
                                    <td className="v text-center" style={{width:50}}>{falta.justificada?'SI':'NO'}</td>
                                    <td className="v" style={{width:30}}>
                                    {
                                      lista_faltas.faltas.length > 1 && (
                                        <Button size="sm" onClick={(e:any)=>{eliminarFalta(falta.uuid)}} variant="danger"><i className="fa fa-trash"/></Button>
                                      )
                                    }
                                    </td>
                                  </tr>
                                    )
                                }
                            </tbody>
                          </table>


                      </React.Fragment>

                    )
                  }

                  {
                    lista_faltas.retardos.length > 0 && (

                      <React.Fragment>

                          <small>Retardos</small>
                          <h6>Memorandum {lista_faltas.retardos_movimiento.memorandum} <i onClick={(e:any)=>{eliminarMovimiento(e,lista_faltas.retardos_movimiento.uuid)}} className="fa fa-trash pointer"/></h6>


                            <table className="table">
                              <thead style={{fontSize:10}}>
                                <tr>
                                  <th scope="col">Fecha</th>
                                  <th scope="col">Detalles</th>
                                  <th scope="col">Justificado</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    lista_faltas.retardos.map((falta:any)=>
                                    <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                                      <td className="v" style={{width:100}}>{moment(falta.fecha_retardo).format('YYYY-MM-DD')}</td>
                                      <td className="v" >{falta.detalles}</td>
                                      <td className="v text-center" style={{width:50}}>{falta.justificada?'SI':'NO'}</td>
                                      <td className="v" style={{width:30}}>
                                      {
                                        lista_faltas.retardos.length > 1 && (
                                          <Button size="sm" onClick={(e:any)=>{eliminarFalta(falta.uuid)}} variant="danger"><i className="fa fa-trash"/></Button>
                                        )
                                      }
                                      </td>
                                    </tr>
                                      )
                                  }
                              </tbody>
                            </table>

                      </React.Fragment>

                    )
                  }





              </Tab>


              <Tab eventKey="historico" title="Histórico de Faltas y Retados">

                {
                  // JSON.stringify(lista_faltas.historico_incidencias)
                }


                          <table className="table">
                            <thead style={{fontSize:10}}>
                              <tr>
                                <th scope="col">Memorandum</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Fecha Aplicar</th>
                                <th scope="col">Detalles</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Justificado</th>
                              </tr>
                            </thead>
                            <tbody>
                                {
                                  lista_faltas.historico_incidencias.map((falta:any)=>
                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                                    <td className="v" style={{width:100}}>{falta.idregistro_movimiento.memorandum}</td>
                                    <td className="v" style={{width:100}}>{moment(falta.fecha_retardo).format('YYYY-MM-DD')}</td>
                                    <td className="v" style={{width:100}}>{ falta.fecha_aplica_nomina ? moment(falta.fecha_aplica_nomina).format('YYYY-MM-DD') : ''}</td>
                                    <td className="v" >{falta.detalles}</td>
                                    <td className="v text-center" style={{width:50}}>{falta.retardo?'RETARDO':'FALTA'}</td> 
                                    <td className="v text-center" style={{width:50}}>{falta.justificada?'SI':'NO'}</td> 
                                  </tr>
                                    )
                                }
                            </tbody>
                          </table>




              </Tab>
            </Tabs>





        </Modal.Body>
        {
          key === 'home' && (
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancelar {}
                  </Button>
                  <Button variant="success" onClick={enviar} type="button">Ok</Button>
                </Modal.Footer>
            )
        }

      </Modal>
    </>
  );
}


export default ModalFaltasRetardos;