import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Row, Col, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'



const ModalBaja = ( params : any ) => {

  const dialog = new Dialog();




  const { show, handleClose, empleado } = params


  const [ observaciones, setObservaciones ] = useState('') 
  // const [ baja_cargo, setBajaCargo ] = useState(false) 

  const [ tipo_baja, setTipoMovimiento ] = useState('baja') 

  const bajaCargoEmpleado = async(e:any)=>{
    try{

        dialog.open({
          accept: 'ACEPTAR',
          cancel: 'ESPERA',
          message: '¿Estás seguro?',
          target: e.target,
          template: ``
        })
        let respuesta : any = await dialog.waitForUser()
        // console.log(respuesta)
        if(respuesta){

          let URL = `personal/bajas/${empleado.uuid_ciudadano}/${tipo_baja}`

            let response : any = await RequestService.post( URL , { fecha_movimiento : fecha_movimiento, observaciones : observaciones } )
            if(response){ 
              toast.warning('Este empleado fue dado de baja'); 
              handleClose() 
          }

        }
        // else toast.error('Para poder dar de baja es obligatoria los detalles de la baja')

    }
    catch(e){
      // console.log(e)
      toast.error('Problemas al intentar dar de baja este empleado')
    }
  }


  const [ fecha_movimiento, setFechaMovimiento ] = useState<any>(new Date())


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Bajas</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{empleado.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{empleado.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          {
            // JSON.stringify(empleado)
          }

          <h5>Información Laboral</h5>



          <div className="row">
            <div className="col-sm-12">
              <small>Departamento</small>
              <h6>{empleado.departamento.clave_departamento} {empleado.departamento.departamento}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-4">
              <small>Plaza</small>
              <h6 className="nm">{empleado.nomina.nomina}</h6>
              <p className="nm">{}</p>
            </div>
            <div className="col-sm-8">
              <small>Puesto</small>
              <h6>{empleado.puesto.clave_puesto} {empleado.puesto.puesto} </h6>
            </div>
          </div>



                <hr className="border border-primary border-3 opacity-75 mb-4"/>

          <h5>Detalles del Movimiento</h5>

 


          <div key={`inline-radio`} className="mb-3">
            <Form.Check inline checked={tipo_baja==='baja'} onChange={(e:any)=>{setTipoMovimiento('baja')}} label="BAJA" name="group1" type={'radio'} id={`inline-radio-1`} />
            <Form.Check inline checked={tipo_baja==='baja_cargo'} onChange={(e:any)=>{setTipoMovimiento('baja_cargo')}} label="BAJA DEL CARGO" name="group1" type={'radio'} id={`inline-cargo-1`} />
            <Form.Check inline checked={tipo_baja==='baja_imss'} disabled={!empleado.asegurado} onChange={(e:any)=>{setTipoMovimiento('baja_imss')}} label="BAJA DEL IMSS" name="group1" type={'radio'} id={`inline-imss-1`} />
            <Form.Check inline checked={tipo_baja==='baja_issste'} disabled={!empleado.asegurado} onChange={(e:any)=>{setTipoMovimiento('baja_issste')}} label="BAJA DEL ISSSTECAM" name="group1" type={'radio'} id={`inline-issste-1`} />
          </div>




          <Row>
            <Col md="6">
              <Form.Label>Fecha del Movimiento</Form.Label>
              <DatePicker selected={fecha_movimiento} className="form-control" inline dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFechaMovimiento(date) } />
            </Col>
          </Row>



                <div className="mb-3">
                  <label className="form-label">Descripción de la baja:</label>
                  <textarea className="form-control" name="observaciones" value={observaciones} onChange={(e:any)=>{setObservaciones(e.target.value.toUpperCase())}} rows={5}></textarea>
                </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={bajaCargoEmpleado} type="button">Aplicar Baja</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalBaja;