import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Table, Form, Dropdown } from 'react-bootstrap'

import { Tab, Tabs, Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';

import { toast } from 'react-toastify'



import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'
// import 'moment/locale/es';


import { SERVER_ } from  '../../config';


import Dialog from '../../services/Dialogs'


import ModalActualizarMemo from '../Recursos_humanos/ModalActualizarMemo'
import ModalEditarMovimientoEmpleado from '../Recursos_humanos//Modales_edicion/ModalEditarMovimientoEmpleado'


import { numberWithCommas } from '../../services/funcionesPublicas'


import './reporte_incidencias.scss'

const ReporteIncidencias = () =>{


  // const { show, handleClose,  } = params
const dialog = new Dialog();


  const [ cargando_, setCargando ] = useState<boolean>(false)
  const [ reporte, setReporte ] = useState<any>({ movimientos : [], reporte_faltas : [], reporte_licencias : [], reporte_devolutivos : [], reporte_dias_economicos : [] , reporte_otras_incidencias : [] , reporte_cumpleanos : [] })


  // const [ anio_nomina, setAnioNomina ] = useState<string>('2023')
  // const [ quincena_nomina, setQuincena ] = useState<string>('2')


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());


  const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]


  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`reportes/incidencias/${arreglo_movimientos}/${arreglo_nominas}/${arreglo_quincenas}/${anio_seleccionado}/busqueda?where=${text_busqueda}`)
      if(!response){
        throw "El servidor no dio respuesta"
      }
      setReporte(response.data)
      setStartDate_(startDate)
      setEndDate_(endDate)

      setCargando(false)
    }
    catch(e){
      console.log(e)
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }

 



  const [ arreglo_nominas, setArregloNominas ] = useState<any>([true,true,true,true])


  const todos = () =>{
    setArregloNominas([true,true,true,true])
  }
  const ninguno = () =>{
    setArregloNominas([false,false,false,false])
  }



  // const [ arreglo_movimientos, setArregloMovimientos ] = useState<any>([true, true, true, true, true, true, true, true, true, true, true, true, true, true])
  const [ arreglo_movimientos, setArregloMovimientos ] = useState<any>([false, false, false, false, true, true, true, true, true, true, false, false, false, true, false, false, true, true])

  const [ arreglo_quincenas, setArregloQuincenas ] = useState<any>([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])







  const todosQuincenas = () =>{
    setArregloQuincenas([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true])
  }
  const ningunoQuincenas = () =>{
    setArregloQuincenas([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
  }



  const todosMovimientos = () =>{
    setArregloMovimientos([false, false, false, false, true, true, true, true, true, true, false, false, false, true, false, false, true, true])
  }
  const ningunoMovimientos = () =>{
    setArregloMovimientos([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  }

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [todos_ninguno, setTodosNinguno ] = useState<boolean>(true)
  const changeTodosNinguno = () =>{
    let movs = {...reporte}
      for(let movimiento of movs.movimientos) movimiento.checked = todos_ninguno
    setTodosNinguno(!todos_ninguno)
    setReporte(movs)
  }

  const busqueda = () =>{}

 


  const [ cargando_reporte, setGenerandoReporte ] = useState<boolean>(false)

 

  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };




  const [ show_ModalActualizarMemo, setModalActualizarMemo ] = useState<boolean>(false)
  const [ movimientos_seleccionados, setMovimiento ] = useState<any>({})
  const handleShow_ModalActualizarMemo = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalActualizarMemo(true)
  }
  const handleClose_ModalActualizarMemo = () => {
    cargarMiReporte()
    setModalActualizarMemo(false)
  }









  const abrirReporteExcel = async () => {

    try{
        
        setGenerandoReporte(true)

        // console.log(reporte.movimientos)

        let checados = []
        for(let checado of reporte.movimientos) if(checado.checked) checados.push(checado.uuid_movimiento)
        // console.log(checados)

        let res = await RequestService.downloadFile(`nomina/reporte_movimientos/excel/${arreglo_movimientos}/${arreglo_nominas}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { filtrados : checados })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte de Movimientos `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }




  const abrirReportePDF = async () => {

    try{
        
        setGenerandoReporte(true)

        // console.log(reporte.movimientos)
        let movimientos = []
        for(let movimiento of reporte.movimientos) if(movimiento.checked) movimientos.push(movimiento.uuid_movimiento)

        let checados = []
        for(let checado of reporte.movimientos) if(checado.checked) checados.push(checado.uuid_movimiento)
        // console.log(checados)

        let res = await RequestService.downloadFile(`fichas/${arreglo_movimientos}/${arreglo_nominas}/${arreglo_quincenas}/${anio_seleccionado}/busqueda?where=${text_busqueda}`, { movimientos_seleccionados : movimientos, filtrados : checados })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte de Movimientos `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }


  const [ mostrar_filtros, setMostrarFiltros ] = useState<boolean>(true)




  const [ show_ModalEditarMovimientoEmpleado, setModalEditarMovimientoEmpleado ] = useState<boolean>(false)
  const handleShow_ModalEditarMovimientoEmpleado = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalEditarMovimientoEmpleado(true)
  }
  const handleClose_ModalEditarMovimientoEmpleado = () => {
    cargarMiReporte()
    setModalEditarMovimientoEmpleado(false)
  }


  // const [ niveles, setNiveles ] = useState<any>([])
  const [ config, setConfig ] = useState<any>({})

  const cargarConfig = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`config`)
      // console.log(response.data)
      setConfig(response.data)
      let nominas = []
      for(let i = 1; i < 25; i++){
        if((response.data.nomina_contador) === (i)) nominas.push(true)
          else nominas.push(false)
      }
      setArregloQuincenas(nominas)
      // console.log(nominas)
      // console.log(nominas.length)
      // setNiveles(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }

  React.useEffect(() => {
        cargarMiReporte()
    cargarConfig()
  }, []);


const obtenerArregloAnios = () => {
  var anioActual = new Date().getFullYear();
  var arregloAnios = [anioActual];

  for (var i = 1; i < 4; i++) {
    arregloAnios.push(anioActual - i);
  }

  return arregloAnios;
}


  const [ anio_seleccionado, setAnioSeleccionado ] = useState<any>(moment().format('YYYY'))



const ordenarPorIncidencias = (reporte_faltas:any, ascendente:any) => {
  // Utiliza el método sort para ordenar el arreglo según total_incidencias
  reporte_faltas.sort(function (a:any, b:any) {
    const incidenciasA = a.total_incidencias;
    const incidenciasB = b.total_incidencias;

    // Determina el orden según el parámetro ascendente
    if (ascendente) {
      return incidenciasA - incidenciasB;
    } else {
      return incidenciasB - incidenciasA;
    }
  });

  return reporte_faltas;
}

const sumarIncidenciasTotales = (reporte_faltas:any,tipo:string) => {
  // Utiliza el método reduce para sumar las incidencias de cada objeto
  const totalIncidencias = reporte_faltas.reduce(function (acumulador:any, elemento:any) {
    let acumulado = 0 
    if(tipo==='total_incidencias'){ acumulado = acumulador + elemento.total_incidencias;}
    if(tipo==='total_faltas'){ acumulado = acumulador + elemento.faltas;}
    if(tipo==='total_faltas_justificadas'){ acumulado = acumulador + elemento.faltas_justificadas;}
    if(tipo==='total_retardos'){ acumulado = acumulador + elemento.retardos;}
    if(tipo==='total_retardos_justificadas'){ acumulado = acumulador + elemento.retardos_justificados;}
    return acumulado
  }, 0);

  return totalIncidencias;
}

  const [ orden_reporte_faltas, setOrdenReporteFaltas ] = useState<boolean>(false)
  const [ orden_reporte_incidencias, setOrdenReporteIncidencias ] = useState<boolean>(false)


const contarOcurrenciasDigito = (num1:number, num2:number) => {
  // console.log(num1)
  // console.log(num2)
  // Inicializar el contador de repeticiones
  let contador = 0;

  // Recorrer los valores desde 1 hasta el valor de num2
  for (let i = 1; i <= num2; i++) {
    // Obtener el dígito actual de i
    const digito = i;

    // Verificar si el dígito es igual a num1
    if (digito === num1) {
      contador++;
    }
  }

  // Devolver el contador de repeticiones
  return contador;
}







const [ envio_server, setSeEnvio ] = useState<boolean>(false)



const eliminarMovimiento = async (event:any,uuid_movimiento:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `movimiento/eliminar/${uuid_movimiento}`)

        if(res_){
          toast.warning('El movimiento fue eliminado',{ icon : '⚠️' })
          setTimeout(()=>{
            cargarMiReporte()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con el servidor")
          }
          setSeEnvio(false)

        }
        
      }
     
}







  return (
    <>




            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4></h4>
                    <hr/>

                      <div className="row">
                        <div className="col-8">
                          
                            <h3>Incidencias</h3>
                            <p>Registro de las Incidencias del Personal</p>

                        </div>
                        <div className="col-2  text-right">

                            <h4>QUINCENA</h4>
                            <p>Q{config.nomina_contador}</p>

                        </div>
                        <div className="col-2 text-right">

                            <h4>AÑO</h4>
                            <p>{config.year}</p>                            

                        </div>
                      </div>





                     <div className="mb-3">

                                <label htmlFor="exampleFormControlInput1" className="form-label">Búsqueda</label>
                                <div className="hstack gap-3 mb-3">
                                  <input className="form-control me-auto" type="text" onKeyDown={(e)=>{if(e.keyCode === 13){ cargarMiReporte() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button type="button" onClick={cargarMiReporte} className="btn btn-secondary"><i className="fa fa-search"/></button>
                                  <div className="vr"></div>
                                  <button type="button" onClick={()=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-times"/></button>
                                  <button type="button" onClick={()=>{setMostrarFiltros(!mostrar_filtros)}} className="btn btn-outline-primary"><i className="fa fa-filter"/></button>
                                  <button type="button" onClick={abrirReportePDF} className="btn btn-outline-primary" disabled={true}>CÉDULAS</button>
                                </div>


                     </div>


                     {
                       mostrar_filtros && (

                           <>

                              <div className="row">
                                  <div className="col-sm-2">

                                      <label className="form-label">Plaza</label>

                                        <Form.Check inline
                                          name="group1"
                                          type="checkbox"
                                          label="BASE"
                                          checked={arreglo_nominas[0]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[0] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__1`}
                                        />

                                        <Form.Check inline
                                          name="group2"
                                          type="checkbox"
                                          label="CONFIANZA"
                                          checked={arreglo_nominas[1]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[1] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__2`}
                                        />

                                        <Form.Check inline
                                          name="group3"
                                          type="checkbox"
                                          label="SUPERNUMERARIO"
                                          checked={arreglo_nominas[2]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[2] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__3`}
                                        />

                                        <Form.Check inline
                                          name="group4"
                                          type="checkbox"
                                          label="OTROS"
                                          checked={arreglo_nominas[3]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[3] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__4`}
                                        />


                                      <ul className="list-inline">
                                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                      </ul>




                                  </div>
                                  <div className="col-sm-1">

                                      <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Año</label>
                                        {
                                          obtenerArregloAnios().map( (year:any,indice_year:number) => <React.Fragment key={indice_year}>
                                                       <div  className="form-check form-check-inline">
                                                          <input className="form-check-input" type="radio" id={`inlineCheckbox1_${year}`} checked={anio_seleccionado==year} onChange={(e:any)=>{setAnioSeleccionado(year)}} />
                                                          <label className="form-check-label" htmlFor={`inlineCheckbox1_${year}`}>{year}</label>
                                                        </div>
                                          </React.Fragment> )
                                        }
                                      </div>

                                  </div>
                                  <div className="col-sm-5">




                                        <label className="form-label">Quincenas</label>

                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q1" checked={arreglo_quincenas[0]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[0] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q1`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q2" checked={arreglo_quincenas[1]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[1] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q2`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q3" checked={arreglo_quincenas[2]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[2] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q3`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q4" checked={arreglo_quincenas[3]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[3] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q4`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q5" checked={arreglo_quincenas[4]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[4] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q5`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q6" checked={arreglo_quincenas[5]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[5] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q6`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q7" checked={arreglo_quincenas[6]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[6] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q7`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q8" checked={arreglo_quincenas[7]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[7] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q8`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q9" checked={arreglo_quincenas[8]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[8] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q9`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q10" checked={arreglo_quincenas[9]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[9] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q10`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q11" checked={arreglo_quincenas[10]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[10] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q11`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q12" checked={arreglo_quincenas[11]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[11] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q12`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q13" checked={arreglo_quincenas[12]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[12] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q13`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q14" checked={arreglo_quincenas[13]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[13] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q14`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q15" checked={arreglo_quincenas[14]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[14] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q15`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q16" checked={arreglo_quincenas[15]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[15] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q16`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q17" checked={arreglo_quincenas[16]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[16] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q17`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q18" checked={arreglo_quincenas[17]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[17] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q18`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q19" checked={arreglo_quincenas[18]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[18] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q19`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q20" checked={arreglo_quincenas[19]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[19] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q20`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q21" checked={arreglo_quincenas[20]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[20] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q21`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q22" checked={arreglo_quincenas[21]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[21] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q22`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q23" checked={arreglo_quincenas[22]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[22] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q23`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q24" checked={arreglo_quincenas[23]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[23] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q24`} />


                                        <ul className="list-inline">
                                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todosQuincenas()}}>Todos</small> / <small className="pointer" onClick={()=>{ningunoQuincenas()}}>Ninguno</small></li>
                                        </ul>







                                  </div>
                                  <div className="col-sm-4">


                                      <label className="form-label">Movimiento</label>

                                        <Form.Check inline name="group1" type="checkbox" label="LICENCIA CON GOCE" checked={arreglo_movimientos[4]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[4] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__4`} />
                                        <Form.Check inline name="group1" type="checkbox" label="LICENCIA SIN GOCE" checked={arreglo_movimientos[5]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[5] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__5`} />
                                        <Form.Check inline name="group1" type="checkbox" label="VACACIONES" checked={arreglo_movimientos[6]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[6] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__6`} />
                                        <Form.Check inline name="group1" type="checkbox" label="DEVOLUTIVOS" checked={arreglo_movimientos[7]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[7] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__7`} />
                                        <Form.Check inline name="group1" type="checkbox" label="FALTAS" checked={arreglo_movimientos[8]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[8] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__8`} />
                                        <Form.Check inline name="group1" type="checkbox" label="RETARDOS" checked={arreglo_movimientos[9]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[9] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__9`} />
                                        <Form.Check inline name="group1" type="checkbox" label="DÍAS ECONÓMICOS" checked={arreglo_movimientos[13]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[13] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__13`} />
                                        <Form.Check inline name="group1" type="checkbox" label="DÍA DE CUMPLEAÑOS" checked={arreglo_movimientos[16]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[16] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__15`} />
                                        <Form.Check inline name="group1" type="checkbox" label="OTRAS INCIDENCIAS" checked={arreglo_movimientos[17]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[17] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__16`} />




                                        <ul className="list-inline">
                                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todosMovimientos()}}>Todos</small> / <small className="pointer" onClick={()=>{ningunoMovimientos()}}>Ninguno</small></li>
                                        </ul>

                                    </div>
                                </div>


                           </>

                         )
                     }

                    
                    









                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                                {


                                  !cargando_ && (

                                      <>

                                        {
                                            // JSON.stringify(reporte)

                                          reporte.movimientos.length > 0 && (

                                              <React.Fragment>

                                                  <h4 className="nm-">
                                                    REPORTE DE INCIDENCIAS
                                                  </h4>

                                                  {
                                                    // <a className="btn btn-link" onClick={abrirReporteExcel}>Reporte Excel</a>
                                                  }




                                                    <Tabs
                                                      defaultActiveKey="home"
                                                      id="uncontrolled-tab-example"
                                                      className="mb-3"
                                                    >
                                                      <Tab eventKey="home" title="Movimientos">


                                                            <Table  style={{fontSize:12}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col"><i onClick={changeTodosNinguno} className="fa pointer fa-check-square"/></th>
                                                                  <th scope="col"></th>
                                                                  <th scope="col">Memorandum</th>
                                                                  <th scope="col">Núm Empleado</th>
                                                                  <th scope="col">Nómina</th>
                                                                  <th scope="col">Empleado</th>
                                                                  <th scope="col">Movimiento</th>
                                                                  <th scope="col">Plaza</th>
                                                                  <th scope="col">Puesto</th>
                                                                  <th scope="col">Adscripción</th>
                                                                  <th scope="col">Fecha Captura</th>
                                                                  <th scope="col"></th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {
                                                                  reporte.movimientos.map( (movimiento:any,indice:number) =>
                                                                        <tr key={indice }>
                                                                          <td className="v" style={{width:10}}>
                                                                            <input className="form-check-input" type="checkbox" checked={movimiento.checked} onChange={(e:any)=>{ let cr = { ...reporte }; cr.movimientos[indice].checked = e.target.checked; setReporte(cr); }} />
                                                                          </td>

                                                                          <td className="v" style={{width:10}}>
                                                                            <a target="_blank" href={`${SERVER_}ficha/${movimiento.uuid_movimiento}`}><i className="fa fa-file-pdf-o"/></a>
                                                                          </td>

                                                                          <th className="v pointer" onClick={(e:any)=>{handleShow_ModalActualizarMemo(movimiento)}} scope="row" style={{width:100}}>
                                                                              <p className="nm text-center"><small>{movimiento.memorandum}</small></p>
                                                                             
                                                                          </th>
                                                                          <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{movimiento.numero_empleado}</small></p>
                                                                             
                                                                          </th> 
                                                                          <td className="v" style={{width:60}}>
                                                                            <p className="nm">Q{movimiento.quincena_movimiento}</p>
                                                                            <small>{movimiento.anio_movimiento}</small>
                                                                          </td>
                                                                          <td className="v">
                                                                            <p className="nm">
                                                                            {
                                                                              `${movimiento.nombres} ${movimiento.paterno} ${movimiento.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v" style={{width:140}}>
                                                                            {movimiento.movimiento}
                                                                          </td>
                                                                          <td className="v" style={{width:240}}>
                                                                            <p className="nm">
                                                                              <b>{ movimiento.nomina }</b>
                                                                            </p>
                                                                            <small>{movimiento.subnomina}</small>
                                                                          </td>
                                                                          <td className="v" style={{width:200}}>
                                                                            <p className="nm">
                                                                              <small> { /*movimiento.clave_puesto.padStart(2,'0')*/ } </small> <b>{ movimiento.puesto }</b>
                                                                            </p>
                                                                            
                                                                          </td> 
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                              <small>{ movimiento.departamento }</small>
                                                                            </p>
                                                                            <small><b>{movimiento.direccion}</b></small>
                                                                          </td>
                                                                          <td className="v" style={{width:100}}>
                                                                            <p className="nm">{moment(movimiento.fecha_creacion).format('YYYY-MM-DD')}</p>
                                                                            <small>{moment(movimiento.fecha_creacion, "YYYYMMDD").fromNow()}</small>
                                                                          </td>



                                                                          <td className="v text-left" style={{"width":"20px"}}>
                                                                                    <button type="button" onClick={(e:any)=>{eliminarMovimiento(e,movimiento.uuid_movimiento)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                                          </td>


           
                                                                        </tr>
                                                                   )
                                                                }
                                                              </tbody>
                                                            </Table>




                                                      </Tab>
                                                      <Tab eventKey="faltas_y_retardos" title="Reporte de Faltas y Retardos" disabled={reporte.reporte_faltas.length===0} >
                                                        
                                                          {
                                                            // JSON.stringify(reporte.reporte_faltas)
                                                          }

                                                          <table className="table table-sm" style={{fontSize:12}}>
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">Núm Empleado</th>
                                                                <th scope="col">Empleado </th>
                                                                <th className="text-center total_incidencias" scope="col">Total Incidencias <i className={`${ ( orden_reporte_faltas ? 'fa fa-caret-up' : 'fa fa-caret-down' ) }`} onClick={(e:any)=>{setOrdenReporteFaltas(!orden_reporte_faltas)}} />  </th>
                                                                <th className="text-center faltas" scope="col">Para Asignar</th>
                                                                <th className="text-center faltas" scope="col">Faltas</th>
                                                                <th className="text-center faltas" scope="col">Justificadas</th>
                                                                <th className="text-center faltas" scope="col"> </th>
                                                                <th className="text-center retardos" scope="col">Para Asignar</th>
                                                                <th className="text-center retardos" scope="col">Retardos</th>
                                                                <th className="text-center retardos" scope="col">Justificados</th>
                                                                <th className="text-center retardos" scope="col"> </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>

                                                              {
                                                                ordenarPorIncidencias(reporte.reporte_faltas,orden_reporte_faltas).map( ( empleado:any, indice_empleado:number ) =>

                                                                        <tr key={indice_empleado}>

                                                                          <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                             
                                                                          </th> 
                                                                          <td className="v">
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.total_incidencias}</b></p>
                                                                          </td>
                                                                          <td className="v text-center faltas" style={{width:100}}>
                                                                            {
                                                                              ( empleado.faltas - empleado.faltas_justificadas )
                                                                            }
                                                                          </td> 
                                                                          <td className="v text-center faltas" style={{width:100}}>
                                                                            <p className="nm"><b>{empleado.faltas}</b></p>
                                                                          </td>
                                                                          <td className="v text-center faltas" style={{width:100}}>
                                                                            <p className="nm">{empleado.faltas_justificadas}</p>
                                                                          </td>
                                                                          <td className="v text-left faltas" style={{width:300}}>

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.faltas_fechas.map( (fecha:any,fecha_indice:number) => <span style={{fontWeight : ( fecha.justificada ? 'normal' : 'bold' ) }} key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td> 
                                                                          <td className="v text-center retardos" style={{width:100}}>
                                                                            <p className="nm"><b>{empleado.retardos - empleado.retardos_justificados}</b></p>
                                                                          </td>
                                                                          <td className="v text-center retardos" style={{width:100}}>
                                                                            <p className="nm"><b>{empleado.retardos}</b></p>
                                                                          </td>
                                                                          <td className="v text-center retardos" style={{width:100}}>
                                                                            <p className="nm">{empleado.retardos_justificados}</p>
                                                                          </td>
                                                                          <td className="v text-left retardos" style={{width:300}}>
                                                                            {
                                                                              // JSON.stringify(empleado.retardos_fechas)
                                                                            }
                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.retardos_fechas.map( (fecha:any,fecha_indice:number) => <span key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>
                                                                          </td>
                                                                        </tr>

                                                                 )
                                                              }

                                                                <tr>
                                                                  <td className="v"></td>
                                                                  <td className="v"></td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_incidencias')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_faltas') - sumarIncidenciasTotales(reporte.reporte_faltas,'total_faltas_justificadas')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_faltas')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_faltas_justificadas')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v"></td>
                                                                  <td className="v text-center">
                                                                    -
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_retardos') - sumarIncidenciasTotales(reporte.reporte_faltas,'total_retardos_justificadas')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_retardos')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v text-center">
                                                                            <h5 className="nm">
                                                                                {
                                                                                  sumarIncidenciasTotales(reporte.reporte_faltas,'total_retardos_justificadas')
                                                                                }
                                                                            </h5>
                                                                  </td>
                                                                  <td className="v"></td>
                                                                </tr>

                                                            </tbody>
                                                          </table>


                                                      </Tab>

                                                      <Tab eventKey="licencias" title="Reporte de Licencias" disabled={reporte.reporte_licencias.length===0} >
                                                        
                                                         
                                                        {
                                                          // JSON.stringify(reporte.reporte_licencias)
                                                        }


                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th scope="col">Empleado</th>
                                                                <th className="text-center total_incidencias" scope="col">Total Días <i className={`${ ( orden_reporte_incidencias ? 'fa fa-caret-up' : 'fa fa-caret-down' ) }`} onClick={(e:any)=>{setOrdenReporteIncidencias(!orden_reporte_incidencias)}} />  </th>
                                                              <th scope="col">Con Goce</th>
                                                              <th scope="col"></th>
                                                              <th scope="col">Sin Goce</th>
                                                              <th scope="col"></th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_licencias.map((empleado:any,indice_licencia:number)=>
                                                                      <tr key={indice_licencia}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v">
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.total_licencias}</b></p>
                                                                          </td>
                                                                        <td className="v text-center faltas" style={{width:100}}>
                                                                            <p className="nm"><b>{empleado.licencias_con_goce}</b></p>
                                                                        </td> 
                                                                          <td className="v text-left faltas" style={{width:400}}>

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.fechas_licencias_con_goce.map( (fecha:any,fecha_indice:number) => <span style={{fontWeight : ( fecha.justificada ? 'normal' : 'bold' ) }} key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                        <td className="v text-center retardos" style={{width:100}}>
                                                                            <p className="nm"><b>{empleado.licencias_sin_goce}</b></p>
                                                                        </td> 
                                                                          <td className="v text-left retardos" style={{width:400}}>

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.fechas_licencias_sin_goce.map( (fecha:any,fecha_indice:number) => <span style={{fontWeight : ( fecha.justificada ? 'normal' : 'bold' ) }} key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>










                                                      </Tab>

                                                      <Tab eventKey="vacaciones" title="Reporte de Vacaciones" disabled={reporte.reporte_vacaciones.length===0}>


                                                        {
                                                          // JSON.stringify(reporte.reporte_vacaciones)
                                                        }



                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th scope="col">Empleado</th>
                                                              <th className="text-center total_incidencias" scope="col">Total Días <i className={`${ ( orden_reporte_incidencias ? 'fa fa-caret-up' : 'fa fa-caret-down' ) }`} onClick={(e:any)=>{setOrdenReporteIncidencias(!orden_reporte_incidencias)}} />  </th>
                                                              <th scope="col"></th> 
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_vacaciones.map((empleado:any,indice_licencia:number)=>
                                                                      <tr key={indice_licencia}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.total_vacaciones}</b></p>
                                                                          </td> 
                                                                          <td className="v text-left retardos"  >

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.dias_vacaciones.map( (fecha:any,fecha_indice:number) => <span   key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>



                                                      </Tab>

                                                      <Tab eventKey="devolutivos" title="Reporte de Devolutivos" disabled={reporte.reporte_devolutivos.length===0}>


                                                        {
                                                          // JSON.stringify(reporte.dias_economicos)
                                                        }



                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th scope="col">Empleado</th>
                                                              <th className="text-center total_incidencias" scope="col">Total Días <i className={`${ ( orden_reporte_incidencias ? 'fa fa-caret-up' : 'fa fa-caret-down' ) }`} onClick={(e:any)=>{setOrdenReporteIncidencias(!orden_reporte_incidencias)}} />  </th>
                                                              <th scope="col"></th> 
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_devolutivos.map((empleado:any,indice_devolutivo:number)=>
                                                                      <tr key={indice_devolutivo}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.total_devolutivos}</b></p>
                                                                          </td> 
                                                                          <td className="v text-left retardos"  >

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.dias_devolutivos.map( (fecha:any,fecha_indice:number) => <span   key={fecha_indice}> ■ {fecha.fecha_trabajo} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>

                                                      </Tab>



                                                      <Tab eventKey="dias_economicos" title="Reporte de Días Económicos" disabled={reporte.reporte_dias_economicos.length===0}>



                                                        {
                                                          // JSON.stringify(reporte.reporte_dias_economicos)
                                                        }


                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th scope="col">Empleado</th>
                                                              <th className="text-center total_incidencias" scope="col">Total Días <i className={`${ ( orden_reporte_incidencias ? 'fa fa-caret-up' : 'fa fa-caret-down' ) }`} onClick={(e:any)=>{setOrdenReporteIncidencias(!orden_reporte_incidencias)}} />  </th>
                                                              <th scope="col"></th> 
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_dias_economicos.map((empleado:any,indice_devolutivo:number)=>
                                                                      <tr key={indice_devolutivo}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.total_dias_economicos}</b></p>
                                                                          </td> 
                                                                          <td className="v text-left retardos"  >

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.dias_economicos.map( (fecha:any,fecha_indice:number) => <span   key={fecha_indice}> ■ {fecha.fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>

                                                        
                                                      </Tab>

                                                      <Tab eventKey="otras_incidencias" title="Reporte de Otras Incidencias" disabled={reporte.reporte_otras_incidencias.length===0}>
                                                        
                                                        {
                                                          JSON.stringify(reporte.reporte_otras_incidencias)
                                                        }



                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th scope="col">Empleado</th>
                                                              <th className="text-center total_incidencias" scope="col">Total Días  </th>
                                                              <th scope="col"></th> 
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_otras_incidencias.map((empleado:any,indice_otras_incidencias:number)=>
                                                                      <tr key={indice_otras_incidencias}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{empleado.fechas.length}</b></p>
                                                                          </td> 
                                                                          <td className="v text-left retardos"  >

                                                                            <p className="nm">
                                                                              {
                                                                                (empleado.fechas.map( (fecha:any,fecha_indice:number) => <span   key={fecha_indice}> ■ {fecha} </span> ))
                                                                              }
                                                                            </p>

                                                                          </td>
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>



                                                      </Tab>

                                                      <Tab eventKey="cumpleanos" title="Reporte de Cumpleaños" disabled={reporte.reporte_cumpleanos.length===0}>

                                                        {
                                                          // JSON.stringify(reporte.reporte_cumpleanos)
                                                        }
                                                        

                                                        <table className="table table-sm" style={{fontSize:12}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Núm Empleado</th>
                                                              <th className="text-center  " scope="col">Empleado</th>
                                                              <th className="text-center total_incidencias" scope="col">Día de Cumpleaños</th>
                                                              <th className="text-center total_incidencias" scope="col">Día tomado</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>{
                                                                  reporte.reporte_cumpleanos.map((empleado:any,indice_cumpleanos:number)=>
                                                                      <tr key={indice_cumpleanos}>
                                                                        <th className="v " scope="row" style={{width:120}}>
                                                                              <p className="nm text-center"><small>{empleado.idciudadano.numero_empleado}</small></p>
                                                                          {
                                                                            // JSON.stringify(empleado.idciudadano)
                                                                          }
                                                                        </th>
                                                                          <td className="v" style={{width:350}}>
                                                                            <p className="nm">
                                                                            {
                                                                              `${empleado.idciudadano.nombres} ${empleado.idciudadano.paterno} ${empleado.idciudadano.materno||''} `
                                                                            }
                                                                            </p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{moment(empleado.idciudadano.fecha_nacimiento).format(' DD [DE] MMMM').toUpperCase()}</b></p>
                                                                          </td> 
                                                                          <td className="v text-center total_incidencias" style={{width:150}}>
                                                                            <p className="nm"><b>{moment(empleado.fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</b></p>
                                                                          </td> 
                                                                      </tr> 
                                                                    )

                                                                }
                                                          </tbody>
                                                        </table>


                                                      </Tab>



                                                    </Tabs>

              







                                              </React.Fragment>

                                            )


                                        }


                                      </>
                                    )



                                }






                </div>
              </div>

            </div>



  {
    show_ModalActualizarMemo && (
      <ModalActualizarMemo show={show_ModalActualizarMemo} handleClose={handleClose_ModalActualizarMemo} movimiento={movimientos_seleccionados} />
      )

  }


  {
    show_ModalEditarMovimientoEmpleado && (
      <ModalEditarMovimientoEmpleado show={show_ModalEditarMovimientoEmpleado} handleClose={handleClose_ModalEditarMovimientoEmpleado} movimiento={movimientos_seleccionados}  />
      )

  }


    </>
  );
}

export default ReporteIncidencias