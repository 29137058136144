export enum ROLE {
  ADMIN = "ROLE_ADMIN",


EJECUTIVO_PRIMARIO = "EJECUTIVO_PRIMARIO",
MOVIMIENTOS_NOMINA = "MOVIMIENTOS_NOMINA",
CONSTANCIAS = "CONSTANCIAS",
INCIDENCIAS = "INCIDENCIAS",
EJECUTIVO_SECUNDARIO = "EJECUTIVO_SECUNDARIO",
CONTRATOS = "CONTRATOS",
ARCHIVOS = "ARCHIVOS",
RECEPCION = "RECEPCION",
CAPTURISTA = "CAPTURISTA",
OFICIOS = "USUARIO_OFICIOS",
NOMINA = "NOMINA",
PENSIONADOS = "PENSIONADOS",


CARGA_FOTOS = "CARGA_FOTOS",


PLANEACION = "PLANEACION",
PLANEACION_REVISION = "PLANEACION_REVISION",
PLANEACION_DIRECCIONES = "PLANEACION_DIRECCIONES",





}