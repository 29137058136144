import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalReingreso from './ModalReingreso'



const ModalReingresoEmpleado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`reingresos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }




  const [ show_ModalReingreso, setModalReingreso ] = useState<boolean>(false)
  const handleShow_ModalReingreso = (empleado:any) => {
    setEmpleado(empleado)
    setModalReingreso(true)
  }
  const handleClose_ModalReingreso = () => {
    setModalReingreso(false)
    handleClose()
  }



  const [ empleado_seleccionado, setEmpleado ] = useState<any>({})


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reingresar al Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            


        </div>





                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Número Empleado</th>
                                              <th scope="col">Nombre(s)</th>
                                              <th scope="col">Apellido(s)</th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"80px"}}>
                                                        <p className="nm"><small><b>{cual.numero_empleado}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{cual.nombres}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <small>{cual.paterno} {cual.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        {
                                                          !cual.activo && (<button onClick={(e:any)=>{handleShow_ModalReingreso(cual)}} className="btn btn-sm btn-primary"><i className="fa fa-handshake-o"/></button>)
                                                        }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
        </Modal.Body>
      </Modal>



  {
    show_ModalReingreso && (
      <ModalReingreso show={show_ModalReingreso} handleClose={handleClose_ModalReingreso} empleado={empleado_seleccionado}  />
      )

  }




    </>
  );
}


export default ModalReingresoEmpleado;