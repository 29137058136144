import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { Button } from 'react-bootstrap'

import ModalSetConfig from './ModalSetConfig'

import ModalFirmas from './ModalFirmas'

import Dialog from '../../services/Dialogs'


const Administracion = () =>{


  const dialog = new Dialog();



      const [ config, setConfig ] = useState<any>({ director_general_firma_en_ausencia : false, director_rh_firma_en_ausencia : false })

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint('config')
            setConfig(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);



  const actualizarConfig = async (e:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: `<small>Este proceso de adelantar quincena no podrá ser regresado a la anterior una vez adelantado</small>`
        })
        let respuestas : any = await dialog.waitForUser()
        if(respuestas){
          let res = await RequestService.update('configuracion/avanzar_quincena', {} )
          toast.warning('La información se actualizó correctamente')
          window.location.reload()
        }
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }



  const [ show_ModalSetConfig, setModalSetConfig ] = useState<boolean>(false)
  const handleShow_ModalSetConfig = () => {
    setModalSetConfig(true)
  }
  const handleClose_ModalSetConfig = () => {
    cargar()
    setModalSetConfig(false)
  }


  const [ show_ModalFirmas, setModalFirmas ] = useState<boolean>(false)
  const [ tipo_usuario, setTipoUsuario ] = useState('')
  const handleShow_ModalFirmas = (tipo_usuario:string) => {
    setTipoUsuario(tipo_usuario)
    setModalFirmas(true)
  }
  const handleClose_ModalFirmas = () => {
    setModalFirmas(false)
  }


      return (
          <>

          
            <div className="container">
              <div className="row">
              
                  <h2>Administracion</h2>

                  <hr/>

                  {
                    // JSON.stringify(config)
                  }

                  <div className="row mb-4"> 
                    <div className="col-sm-4 mb-4">

                      <h4>QUINCENA</h4>
                      <p>Q{config.nomina_contador}</p>

                      <h4>AÑO</h4>
                      <p>{config.year}</p>

                      <Button className="btn-lg" type="button" onClick={actualizarConfig}>AVANZAR QUINCENA</Button>
                      
                    </div>
                    <div className="col-sm-6 mb-4">

                        <h5>Director General</h5>
                        <p>{ config.director_general } <i onClick={()=>{handleShow_ModalFirmas('director_general')}} className="fa fa-id-card pointer"/> </p>

                        <h5>Director de Recursos Humanos </h5>
                        <p>{ config.director_rh } <i onClick={()=>{handleShow_ModalFirmas('director_rh')}} className="fa fa-id-card pointer"/></p>
 
                        {
                          config.director_rh_firma_en_ausencia && (<p><i className="fa fa-warning"/> Se usará la rúbrica digital en ausencia</p>)
                        }

                        <h5>Coordinación de Planeación y Gestión de Personal</h5>
                        <p>{ config.coordinador_planeacion }</p>
 
                        <h5>Jefe de Departamento de Control de Personal</h5>
                        <p>{ config.jefe_departamento }</p>
 
                        <h5>Jefe Departamento de Enlace Administrativo</h5>
                        <p>{ config.coordinador_horas_faltas }</p>

                      <Button className="btn-lg" type="button" onClick={handleShow_ModalSetConfig}><i className="fa fa-edit"/></Button>

                    </div>
                    <div className="col-sm-2 mb-4">

                      {
                        !config.permitir_reportes && (

                              <div className="alert alert-danger" role="alert">
                                <h4 className="alert-heading"><i className=""/> Reportes fuera de tiempo </h4>
                                <p>Mientras el reporte este fuera de tiempo serán movidos hasta la siguiente nómina  </p>
                                <hr/>
                                <p className="mb-0">Puedes volver habilitar los reportes de movimiento en quincena al dar clic <button type="button" className="btn btn-link">Aquí</button></p>
                              </div>

                          )
                      }

                      {
                        config.permitir_reportes && (

                              // <button type="button" className="btn btn-primary">ADELANTAR MOVIMIENTOS</button>
                              <></>

                          )
                      }



                    </div>
                  </div>


                



              </div>
            </div>
              




  {
    show_ModalSetConfig && (
      <ModalSetConfig show={show_ModalSetConfig} handleClose={handleClose_ModalSetConfig} config={config} />
      )

  }


  {
    show_ModalFirmas && (
      <ModalFirmas show={show_ModalFirmas} handleClose={handleClose_ModalFirmas} tipo_usuario={tipo_usuario} />
      )

  }

          </>

      );
  

}

export default Administracion
