import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalGestionDocumentacion from '../Archivos/ModalGestionDocumentacion'

import ModalCambiarFoto from '../ModalCambiarFoto'


import { SERVER_ } from  '../../config';


const Pensionados = () => {

      React.useEffect(() => {
        cargarMiReporte(0)
      }, []);


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  // const currentUser  = AuthService.getCurrentUser();

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint('pensionados/busqueda/'+arreglo_nominas+'/busqueda?where='+text_busqueda+'&skip='+skip+'&limit='+sessionsPerPage)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_nominas, setArregloNominas ] = useState<any>([false,false,false,true,false])


  const todos = () =>{
    setArregloNominas([false,false,false,true,false])
  }
  const ninguno = () =>{
    setArregloNominas([false,false,false,false,false,false])
  }



  const [ empleado_seleccionado, setEmpleadoSeleccionado ] = useState<any>({})
 

   const [ show_ModalGestionDocumentacion, setModalGestionDocumentacion ] = useState<boolean>(false)
  const handleShow_ModalGestionDocumentacion = (ciudadano:any) => {
    setEmpleadoSeleccionado(ciudadano)
    setModalGestionDocumentacion(true)
  }
  const handleClose_ModalGestionDocumentacion = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalGestionDocumentacion(false)
  }



  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }


  
      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Gestión de Pensionados</h4>
                    <hr/>



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
            <div>

                        {
                          // <Form.Check inline name="group1" type="checkbox" label="BASE" checked={arreglo_nominas[0]} onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[0] = e.target.checked;  setArregloNominas(ne);}} id={`opciones_1`} />
                        }

                        {
                          // <Form.Check inline name="group2" type="checkbox" label="CONFIANZA" checked={arreglo_nominas[1]} onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[1] = e.target.checked;  setArregloNominas(ne);}} id={`opciones_2`}  />
                        }

                        {
                          // <Form.Check inline name="group3" type="checkbox" label="SUPERNUMERARIO" checked={arreglo_nominas[2]} onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[2] = e.target.checked;  setArregloNominas(ne);}} id={`opciones_3`} />
                        }

                        {
                          // <Form.Check inline name="group4" type="checkbox" label="OTROS" checked={arreglo_nominas[3]} onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[3] = e.target.checked;  setArregloNominas(ne);}} id={`opciones_4`} />
                        }

            </div>
            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
          </ul>

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col">Nombre</th>
                                              <th scope="col">Nivel Jerárquico / Grupo Funcional</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (ciudadano:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      <img src={`${SERVER_}empleados/foto/${ciudadano.uuid_ciudadano}?${Math.random().toString(36).substr(2, 9)}`}  onClick={()=>{handleShow_ModalCambiarFoto(ciudadano)}}  style={{width:'100%'}} />
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <small>{ciudadano.numero_empleado}</small>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{ciudadano.nombres}</b></small></p>
                                                        <small>{ciudadano.paterno} {ciudadano.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"280px"}}>
                                                        <p className="nm"><small><b>{ciudadano.grupo_funcional.grupo_funcional}</b></small></p>
                                                        <small>{ciudadano.nivel_jerarquico.id} {ciudadano.nivel_jerarquico.nivel_jerarquico}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"60px"}}>
                                                        <button className="btn btn-sm btn-primary" onClick={(e:any)=>{handleShow_ModalGestionDocumentacion(ciudadano)}} ><i className="fa fa-files-o"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                    
                </div>
              </div>

            </div>



  {
    show_ModalGestionDocumentacion && (
      <ModalGestionDocumentacion show={show_ModalGestionDocumentacion} handleClose={handleClose_ModalGestionDocumentacion} ciudadano={empleado_seleccionado}  />
      )

  }

 
   {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={empleado_seleccionado} />
      )

  }


          </>

      );
  

}

export default Pensionados