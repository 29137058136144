import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



const ModalDiaCumpleanos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, empleado } = params


    const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          observaciones : yup.string(),
          // fecha_movimiento : yup.string().required(mensajes_validacion_generico),
          fecha : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `cumpleanos/agregar/${empleado.uuid_ciudadano}` , { ...registro, fecha : moment(registro.fecha).format('YYYY-MM-DD') })

          if(res_){
            toast.success('El día libre por cumpleaños fue notificado al departamento de Recursos Humanos')        
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Día por Cumpleados</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <h5>Empleado</h5>




          <div className="row">
            <div className="col-sm-6">
              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>
            </div>
            <div className="col-sm-6">
              <small>Fecha de Nacimiento</small>
              <h6>{empleado.fecha_nacimiento}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            observaciones : "",
            // fecha_movimiento : new Date( ),
            fecha : new Date( ),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="observaciones">
              <Form.Label>Observaciones del Movimiento</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="observaciones"
                value={values.observaciones}
                onBlur={(e)=>{ setFieldValue('observaciones', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('observaciones', e.target.value ) }}
                isInvalid={!!errors.observaciones}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.observaciones}</>} </Form.Control.Feedback>
            </Form.Group>


            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Día que tomó</Form.Label>
                    <DatePicker selected={values.fecha} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha', date ) } />
                  </div>
              </div>
            </div>

 



        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalDiaCumpleanos;