import React, { useState } from 'react';

import RequestService from "../../services/request.service";

// import AuthService from './features/auth/auth.service'

import {Button, Modal, Table } from 'react-bootstrap';

import { toast } from 'react-toastify'


const ModalDetallesDepartamento = ( params : any ) => {

  // const user = AuthService.getCurrentUser()

  const { show, handleClose, departamento } = params


      const [ departamento_detalles, setDepartamento ] = useState<any>({ acumulado_puestos: [] })

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`departamento/detalles/${departamento.uuid}`)
            setDepartamento(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);





  return (
    <>
      <Modal show={show} className="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{departamento.departamento.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            departamento_detalles.numero_empleados > 0 && (

                <React.Fragment>
                  
          {
            // JSON.stringify(departamento)
          }
          {
            // JSON.stringify(departamento_detalles)
          }

              <p>Acumulado de Empleados por Puesto</p>

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Puesto</th>
                        <th scope="col">Empleados</th>
                        <th scope="col">Hombres</th>
                        <th scope="col">Mujeres</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      departamento_detalles.acumulado_puestos.map((puesto:any) =>
                      <tr  key={ Math.random().toString(36).substr(2, 9) }>
                        <td>{puesto.puesto.toUpperCase()}</td>
                        <td className="text-right" style={{width:'140px'}}>{puesto.cantidad}</td>
                        <td className="text-right" style={{width:'140px'}}>{puesto.hombres}</td>
                        <td className="text-right" style={{width:'140px'}}>{puesto.mujeres}</td>
                      </tr>
                      )
                    }

                    </tbody>
                  </table>


              <p className="mt-4">Acumulado de Empleados por Nivel Jerárquico</p>

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Nivel Jerárquico</th>
                        <th scope="col">Empleados</th>
                        <th scope="col">Hombres</th>
                        <th scope="col">Mujeres</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      departamento_detalles.acumulado_nivel_jerarquico.map((nivel_jerarquico:any) =>
                      <tr  key={ Math.random().toString(36).substr(2, 9) }>
                        <td>{nivel_jerarquico.nivel_jerarquico.toUpperCase()}</td>
                        <td className="text-right" style={{width:'140px'}}>{nivel_jerarquico.cantidad}</td>
                        <td className="text-right" style={{width:'140px'}}>{nivel_jerarquico.hombres}</td>
                        <td className="text-right" style={{width:'140px'}}>{nivel_jerarquico.mujeres}</td>
                      </tr>
                      )
                    }

                    </tbody>
                  </table>



                </React.Fragment>

            )
          }

          {
            departamento_detalles.numero_empleados === 0 && (

              <div className="alert alert-warning" role="alert">
                <i className="fa fa-warning" /> Este Departamento no cuenta con empleados registrados
              </div>

            )
          }

        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalDetallesDepartamento;