import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import AuthService from '../../features/auth/auth.service'


import Dialog from '../../services/Dialogs'



import Pagination from "../../services/Pagination";
import { Table, Form, Button, Dropdown } from 'react-bootstrap'




import moment from 'moment'
import 'moment/locale/es'


import { SERVER_ } from  '../../config';


import ModalCambiarFoto from '../ModalCambiarFoto'

import ModalDevolutivos from './ModalDevolutivos'

import ModalFaltasRetardos from './ModalFaltasRetardos'

import ModalVacaciones from './ModalVacaciones'

import ModalDias_economicos from './ModalDias_economicos'

import ModalLicencias from './ModalLicencias'


import ModalFichaEmpleado from './ModalFichaEmpleado'


import ModalReporteFaltasRetardos from './ModalReporteFaltasRetardos'


import ModalOtrasIncidencias from './ModalOtrasIncidencias'


import ModalDiaCumpleanos from './ModalDiaCumpleanos'


import ModalReporteListadoDeIncidencias from './ModalReporteListadoDeIncidencias'


const Incidencias = () =>{

  const user = AuthService.getCurrentUser()
 
  const [ config, setConfig ] = useState<any>({})
  const cargarConfig = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`config`)
      setConfig(response.data)
      // if(config.periodo==1) setMonths(periodo_1)
      // if(config.periodo==2) setMonths(periodo_2)
      setCargando(false)
    }
    catch(e:any){
      setCargando(false)
      toast.error(e.response.data + '. Problemas al intentar cargar la lista de vacaciones')
    }
  }

      React.useEffect(() => {
        busqueda()
        cargarConfig()
      }, []);



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const currentUser  = AuthService.getCurrentUser();

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint('incidencias/empleados/busqueda?where='+text_busqueda+'&skip='+skip)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }



  const [ empleado_seleccionado, setEmpleadoSeleccionado ] = useState<any>({})


  const [ show_ModalDevolutivos, setModalDevolutivos ] = useState<boolean>(false)
  const handleShow_ModalDevolutivos = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalDevolutivos(true)
  }
  const handleClose_ModalDevolutivos = () => {
    setModalDevolutivos(false)
  }

  // const [ arreglo_nominas, setArregloNominas ] = useState<any>([true,true,true,true,true])


  // const todos = () =>{
  //   setArregloNominas([true,true,true,true,true])
  // }
  // const ninguno = () =>{
  //   setArregloNominas([false,false,false,false,false,false])
  // }


  const [ show_ModalFaltasRetardos, setModalFaltasRetardos ] = useState<boolean>(false)
  const handleShow_ModalFaltasRetardos = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalFaltasRetardos(true)
  }
  const handleClose_ModalFaltasRetardos = () => {
    setModalFaltasRetardos(false)
  }




  const [ show_ModalDias_economicos, setModalDias_economicos ] = useState<boolean>(false)
  const handleShow_ModalDias_economicos = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalDias_economicos(true)
  }
  const handleClose_ModalDias_economicos = () => {
    setModalDias_economicos(false)
  }




  const [ show_ModalVacaciones, setModalVacaciones ] = useState<boolean>(false)
  const handleShow_ModalVacaciones = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalVacaciones(true)
  }
  const handleClose_ModalVacaciones = () => {
    setModalVacaciones(false)
  }



  const [ show_ModalLicencias, setModalLicencias ] = useState<boolean>(false)
  const handleShow_ModalLicencias = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalLicencias(true)
  }
  const handleClose_ModalLicencias = () => {
    setModalLicencias(false)
  }



  const [ uuid_empleado_seleccionado, setUUIDEmpleadoSeleccionado ] = useState<string>('')
  const [ show_ModalFichaEmpleado, setModalFichaEmpleado ] = useState<boolean>(false)
  const handleShow_ModalFichaEmpleado = (uuid_empleado:string) => {
    setUUIDEmpleadoSeleccionado(uuid_empleado)
    setModalFichaEmpleado(true)
  }
  const handleClose_ModalFichaEmpleado = () => {
    setModalFichaEmpleado(false)
  }




  const [ show_ModalReporteIncidencias, setModalReporteIncidencias ] = useState<boolean>(false)
  const handleShow_ModalReporteIncidencias = () => {
    setModalReporteIncidencias(true)
  }
  const handleClose_ModalReporteIncidencias = () => {
    setModalReporteIncidencias(false)
  }





  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }





  const [ show_ModalOtrasIncidencias, setModalOtrasIncidencias ] = useState<boolean>(false)
  const handleShow_ModalOtrasIncidencias = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalOtrasIncidencias(true)
  }
  const handleClose_ModalOtrasIncidencias = () => {
    setModalOtrasIncidencias(false)
  }





  const [ show_ModalDiaCumpleanos, setModalDiaCumpleanos ] = useState<boolean>(false)
  const handleShow_ModalDiaCumpleanos = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid : empleado.uuid_usuario })
    setModalDiaCumpleanos(true)
  }
  const handleClose_ModalDiaCumpleanos = () => {
    setModalDiaCumpleanos(false)
  }





  const [ show_ModalReporteListadoDeIncidencias, setModalReporteListadoDeIncidencias ] = useState<boolean>(false)
  const handleShow_ModalReporteListadoDeIncidencias = () => {
    setModalReporteListadoDeIncidencias(true)
  }
  const handleClose_ModalReporteListadoDeIncidencias = () => {
    setModalReporteListadoDeIncidencias(false)
  }

  
      return (
          <>
              
            <div className="container">

                <h3>Reporte de Incidencias</h3>
                <p>{currentUser.iddepartamento.departamento}</p>
                <hr className="border border-primary border-3 opacity-75 mb-4"/>




                <div className="row">
                  <div className="col-2">

                    {
                      // JSON.stringify(currentUser.iddepartamento.departamento)
                    }
                    

                      <h4>QUINCENA</h4>
                      <p>Q{config.nomina_contador}</p>

                      <h4>AÑO</h4>
                      <p>{config.year}</p>



                      <label htmlFor="exampleFormControlInput1" className="form-label">Opciones</label>

                      <ul className="list-group list-group-flush">
                          <li className="list-group-item"><a href="./incidencias/reporte" style={{fontSize:12}} className="btn btn-link">Reporte de Incidencias</a></li>
                          <li className="list-group-item"><a onClick={handleShow_ModalReporteIncidencias} style={{fontSize:12}} className="btn btn-link">Cumpleaños</a></li>
                          <li className="list-group-item"><a onClick={handleShow_ModalReporteListadoDeIncidencias} style={{fontSize:12}} className="btn btn-link">Listado General</a></li>
                      </ul>




                  </div>
                  <div className="col">
                    



              
                      <label htmlFor="exampleFormControlInput1" className="form-label">Búsqueda de Personal</label>
                      <div className="hstack gap-3">
                        <input className="form-control me-auto" type="text" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                        <button type="button" onClick={busqueda} className="btn btn-secondary"><i className="fa fa-search"/></button>
                        <div className="vr"></div>
                        <button type="button" onClick={()=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-times"/></button>
                      </div>






                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col">Nombre</th>
                                              <th scope="col">Puesto</th>
                                              <th scope="col">Nivel Jerárquico / Grupo Funcional</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (ciudadano:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"60px"}}>
                                                      <img src={`${SERVER_}empleados/foto/${ciudadano.uuid_ciudadano}?${Math.random().toString(36).substr(2, 9)}`}   onClick={()=>{handleShow_ModalCambiarFoto(ciudadano)}}  style={{width:'100%'}} />
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{ciudadano.numero_empleado} {ciudadano.nombres}</b></small></p>
                                                        <small>{ciudadano.paterno} {ciudadano.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"240px"}}>
                                                        <p className="nm"><small><b>{ciudadano.puesto.clave_puesto}</b></small></p>
                                                        <small>{ciudadano.puesto.puesto}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"280px"}}>
                                                        <p className="nm"><small><b>{ciudadano.grupo_funcional.grupo_funcional}</b></small></p>
                                                        <small>{ciudadano.nivel_jerarquico.nivel_jerarquico}</small>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"35px"}}>
                                                        <button type="button" onClick={()=>{handleShow_ModalFichaEmpleado(ciudadano.uuid_ciudadano)}} className="btn btn-sm btn-primary"><i className="fa fa-id-card-o" /></button>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"35px"}}>

                                                        <Dropdown>
                                                          <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                                                            <i className="fa fa-gears"/>
                                                          </Dropdown.Toggle>

                                                          <Dropdown.Menu>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalFaltasRetardos(ciudadano)}}>Faltas</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalLicencias(ciudadano)}}>Licencias</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalVacaciones(ciudadano)}}>Vacaciones</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalDevolutivos(ciudadano)}}>Devolutivos</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalDias_economicos(ciudadano)}}>Días Económicos</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalOtrasIncidencias(ciudadano)}}>Otras Incidencias</Dropdown.Item>
                                                            <Dropdown.Item onClick={(e)=>{handleShow_ModalDiaCumpleanos(ciudadano)}}>Días de Cumpleaños</Dropdown.Item>
                                                          </Dropdown.Menu>
                                                        </Dropdown>

                                                    </td>



                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
                  </div>
                </div>

              
            </div>






  {
    show_ModalDevolutivos && (
      <ModalDevolutivos show={show_ModalDevolutivos} handleClose={handleClose_ModalDevolutivos} empleado={empleado_seleccionado} />
      )

  }


  {
    show_ModalFaltasRetardos && (
      <ModalFaltasRetardos show={show_ModalFaltasRetardos} handleClose={handleClose_ModalFaltasRetardos} empleado={empleado_seleccionado} />
      )

  }


  {
    show_ModalVacaciones && (
      <ModalVacaciones show={show_ModalVacaciones} handleClose={handleClose_ModalVacaciones} empleado={empleado_seleccionado} config={config} />
      )

  }

  {
    show_ModalDias_economicos && (
      <ModalDias_economicos show={show_ModalDias_economicos} handleClose={handleClose_ModalDias_economicos} empleado={empleado_seleccionado} config={config} />
      )

  }


  {
    show_ModalLicencias && (
      <ModalLicencias show={show_ModalLicencias} handleClose={handleClose_ModalLicencias}  empleado={empleado_seleccionado} config={config} />
      )

  }



  {
    show_ModalFichaEmpleado && (
      <ModalFichaEmpleado show={show_ModalFichaEmpleado} handleClose={handleClose_ModalFichaEmpleado} uuid_empleado={uuid_empleado_seleccionado} />
      )

  }



  {
    show_ModalReporteIncidencias && (
      <ModalReporteFaltasRetardos show={show_ModalReporteIncidencias} handleClose={handleClose_ModalReporteIncidencias}  />
      )

  }



  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={empleado_seleccionado} />
      )

  }



  {
    show_ModalOtrasIncidencias && (
      <ModalOtrasIncidencias show={show_ModalOtrasIncidencias} handleClose={handleClose_ModalOtrasIncidencias}  empleado={empleado_seleccionado} />
      )

  }






  {
    show_ModalDiaCumpleanos && (
      <ModalDiaCumpleanos show={show_ModalDiaCumpleanos} handleClose={handleClose_ModalDiaCumpleanos}  empleado={empleado_seleccionado} />
      )

  }






  {
    show_ModalReporteListadoDeIncidencias && (
      <ModalReporteListadoDeIncidencias show={show_ModalReporteListadoDeIncidencias} handleClose={handleClose_ModalReporteListadoDeIncidencias} config={config} />
      )

  }




          </>

      );
  

}

export default Incidencias
