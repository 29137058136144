import React, { useState } from "react";
import AuthService from "../features/auth/auth.service";


import { Button, Modal } from 'react-bootstrap'

import PasswordChecklist from "react-password-checklist"

import Dialog from '../services/Dialogs'

import { toast } from 'react-toastify'

import RequestService from "../services/request.service";
import { rol } from "../services/rol";




import ModalCambiarFoto from './ModalCambiarFoto'


import { SERVER_ } from  '../config';



import './Profile.scss';


const Profile = () => {

  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const dialog = new Dialog();

  const handleLogout = async () => {
    if(await dialog.confirm('Desear cerrar el acceso?',{}) ){
        localStorage.clear();
        AuthService.logout();
        window.location.reload();
    }
  }

  const currentUser  = AuthService.getCurrentUser();

  const [show, setShow] = useState(false);
  const [send, setSend] = useState(false);

  const handleClose = () => setShow(false);
  const cambiarContrasenaModal = () =>{ 
      setPassword("")
      setPasswordAgain("")
      setShow(true);
  }
  
  const cambiarContrasena = async () =>{ 
      
      try{
          if(await dialog.confirm('¿Estás seguro que quieres cambiar tu contraseña?',{}) ){
              setShow(false);
              await RequestService.update('cambiar_password/'+currentUser.uuid,{ psw : password })
              toast.warning('Debes volver a entrar al sistema')
              setTimeout(()=>{
                  AuthService.logout();
                  window.location.reload();
              },1500)
          }
      }
      catch(e){
          toast.error('Error al cambiar la contraseña')
      }
  }





  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = () => {
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }


      return (

        <React.Fragment>
          
            <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-xl-4">
                      <div className="card-box text-center">

                             <div className="userMain">
                               <div className="userBlock">
                                   <div className="backgrounImg">
                                   </div>
                                   <div className="userImg">
                                       <img src={`${SERVER_}perfil/foto/${currentUser.uuid}`} onClick={handleShow_ModalCambiarFoto} />
                                   </div>
                                   <div className="userDescription">
                                       <h5>{currentUser.nombres}</h5>
                                       <p>{currentUser.uuid}</p>

                                       {
                                        // rol(currentUser.rol)
                                       }
                                   </div>


                                    <div className="text-left ms-3 me-3 mt-3 mb-3">

                                        <h6>Información de Usuario</h6>

                                        <p className="text-muted mb-2 font-13"><strong>Nombre : </strong> <span className="ml-2">{currentUser.nombres} {currentUser.paterno} {currentUser.materno}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Rol : </strong> <span className="ml-2">{rol(currentUser.rol)}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Celular : </strong><span className="ml-2">{(currentUser.celular)}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Email : </strong> <span className="ml-2 ">{currentUser.email}</span></p>

                                    </div>


                               </div>
                             </div>


                              <p className="nm mt-4"><small>Opciones</small></p>
                              <ul className="list-inline">
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{cambiarContrasenaModal()}} className="btn btn-link">Cambiar Contraseña</button>
                                  </li>
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{handleLogout()}} className="btn btn-link">Salir</button>
                                  </li>
                              </ul>

                                    
                      </div> 


                  </div> 
                  <div className="col-lg-8 col-xl-8">

                      {
                        // JSON.stringify(currentUser)
                      }


                          <React.Fragment>

                            <h5>Módulos del Uso</h5>
                            <hr/>

                            <div className="row align-items-center">





                      {
                        (['CARGA_FOTOS','ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-users"/> Fotografías</h5>
                                      <p className="card-text">Carga de Foto de Personal</p>
                                      <a href="./carga_fotos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','NOMINA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-users"/> Recursos Humanos</h5>
                                      <p className="card-text">Control y Gestión del personal</p>
                                      <a href="./rh" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','EJECUTIVO_SECUNDARIO','NOMINA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-suitcase"/> Movimientos</h5>
                                      <p className="card-text">Reporte de Movimientos</p>
                                      <a href="./movimientos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_ADMIN','ARCHIVOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-star"/> Archivos</h5>
                                      <p className="card-text">Gestión de Documentación</p>
                                      <a href="./archivos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','PENSIONADOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-files-o"/> Pensionados</h5>
                                      <p className="card-text">Gestión de Documentación</p>
                                      <a href="./pensionados" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-star"/>Incidencias</h5>
                                      <p className="card-text">Control de Incidencias</p>
                                      <a href="./incidencias" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS','NOMINA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-suitcase"/> Incidencias</h5>
                                      <p className="card-text">Reporte de Incidencias</p>
                                      <a href="./incidencias/reporte" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS','NOMINA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Faltas y Retardos</h5>
                                      <p className="card-text">Control de Incidencias</p>
                                      <a href="./incidencias/control_faltas_retardos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-institution"/> Departamentos</h5>
                                      <p className="card-text">Áreas administrativas</p>
                                      <a href="./departamentos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-institution"/> Puestos</h5>
                                      <p className="card-text">Gestión de Puestos</p>
                                      <a href="./puestos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-gears"/> Usuarios</h5>
                                      <p className="card-text">Control de Accesos</p>
                                      <a href="./usuarios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','CONSTANCIAS','INCIDENCIAS','EJECUTIVO_SECUNDARIO','CONTRATOS','ARCHIVOS','NOMINA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-users"/> Mis Oficios</h5>
                                      <p className="card-text">Buzón de Oficios</p>
                                      <a href="./oficios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-gears"/> Administración</h5>
                                      <p className="card-text">Gestíon de la plataforma</p>
                                      <a href="./administracion" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','RECEPCION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-file-o"/> Recepción</h5>
                                      <p className="card-text">Captura y Gestión de Oficios</p>
                                      <a href="./recepcion_oficios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','CONTRATOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-file-o"/> Contratos</h5>
                                      <p className="card-text">Contratos de Personal</p>
                                      <a href="./contratos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





















                      {
                        (['CAPTURISTA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-file-o"/> Captura ?</h5>
                                      <p className="card-text">Recepción de Oficios y Documentos</p>
                                      <a href="./capturista" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['OFICIOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-file-o"/> Mis Oficios</h5>
                                      <p className="card-text">Gestión de Oficios y Documentos</p>
                                      <a href="./mis_oficios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['PLANEACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-gears"/> Gestión</h5>
                                      <p className="card-text">Gestión de Planeación</p>
                                      <a href="./planeacion" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['PLANEACION_DIRECCIONES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-keyboard-o"/> Captura</h5>
                                      <p className="card-text">Captura MIR</p>
                                      <a href="./planeacion_captura" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['PLANEACION_REVISION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-keyboard"/> Revisión</h5>
                                      <p className="card-text">Revisión de MIR</p>
                                      <a href="./planeacion_revision" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                            </div>

                          </React.Fragment>






                  </div> 

              </div>
            </div>


          <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar de Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                  <form>
                      <div className="form-group mb-3">
                          <label className="form-label">Password:</label>
                          <input className="form-control" type="password" onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                          <label className="form-label">Repetir Password:</label>
                          <input className="form-control" type="password" onChange={e => setPasswordAgain(e.target.value)} />
                      </div>

                      <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match"]}
                          messages={{
                              minLength: "La contraseña tiene más de 8 carácteres.",
                              specialChar: "La contraseña debe contener carácteres especiales.",
                              number: "La contraseña debe tener un número.",
                              capital: "La contraseña debe tener una letra mayúscula.",
                              match: "Las contraseñas coinciden.",
                          }}
                          minLength={5}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {
                              setSend(!isValid)
                          }}
                      />
                  </form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" disabled={send} onClick={cambiarContrasena}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>









  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={currentUser} />
      )

  }


        </React.Fragment>


      );
    
}

export default Profile
