import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import './login.scss';

import { useNavigate } from "react-router"

import AuthService from '../features/auth/auth.service'

function Login() {

  const isAuthenticated = AuthService.selectIsAuthenticated

  let navigate = useNavigate();

  const [ username, setUsername ] = useState('')
  const [ password, setPass ] = useState('')

  const loginUser = async() =>{
      AuthService.login( username, password).then(
        () => {
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error("NO PUDISTE ACCEDER. INTENTALO DE NUEVO.")
        }
      );
  }

  const setearUsername = (event:any)=> setUsername(event.target.value)
  const setearContrasena = (event:any)=> setPass(event.target.value)

  useEffect(() => {
    if(AuthService.selectIsAuthenticated){
      navigate('/profile')
    }
  }, [ navigate ]);

const handleTest = (e:any) => { if (e.charCode === 13) { loginUser() } if (e.keyCode === 13) { loginUser() } }

  return (
    <div className="content">

      {isAuthenticated ? (
        <>
          <p>You're logged-in.</p>

        </>
      ) : (
        <>

          <div className="sidenav">
             <div className="login-main-text">
                 <img src="./img/logo_naranja.png" alt="" width="150px" />
             </div>
          </div>
          <div className="main">
             <div className="col-md-6 col-sm-12">
                <div className="login-form">
                   <form>

                     <h2>SARH</h2>
                     <p>
                       <small>SISTEMA DE ADMINISTRACIÓN DE RECURSOS HUMANOS</small>
                     </p>



                      <div className="mb-4">
                         <label className="form-label">Usuario</label>
                         <input type="text" className="form-control" onKeyPress={handleTest} onChange={ setearUsername } />
                      </div>
                      <div className="mb-4">
                         <label className="form-label">Contraseña</label>
                         <input type="password" className="form-control" onKeyPress={handleTest} onChange={ setearContrasena } />
                      </div>

                        <button type="button" className="btn btn-lg btn-primary" onClick={loginUser}>ENTRAR</button>

                   </form>
                </div>
             </div>
          </div>

        </>
      )}

    </div>
  )
}

export default Login
