import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


// import ModalCambiarFotoPersonal from './ModalCambiarFotoPersonal'
import ModalCambiarFoto from '../ModalCambiarFoto'


import { SERVER_ } from  '../../config';

const CargarFotosEmpleados = () =>{




  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ ciudadanos, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`carga_fotos_empleados/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    // setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);





  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const [ empleado_seleccionado, setEmpleadoSeleccionado ] = useState<any>({})
  const handleShow_ModalCambiarFoto = (empleado:any) => {
    setEmpleadoSeleccionado({...empleado, uuid_ciudadano : empleado.uuid })
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Carga de Fotos de Empleados</h4>
                    <hr/>


                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>


                    </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     ciudadanos.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"># Empleado</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              ciudadanos.map( (ciudadano:any,indice:number) =>
                                                  <tr key={indice} style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"150px"}}>
                                                      <img src={`${SERVER_}empleados/foto/${ciudadano.uuid}?${Math.random().toString(36).substr(2, 9)}`}  style={{width:'50%'}} />
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><small><b>{ciudadano.numero_empleado}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{ciudadano.nombres}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <small>{ciudadano.paterno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <small>{ciudadano.materno}</small>
                                                    </td>
                                                    <td className="v text-left" >
                                                    </td>
                                                    <td className="v text-left" style={{"width":"150px"}}>
                                                      <button className="btn btn-info" onClick={(e:any)=>{handleShow_ModalCambiarFoto(ciudadano)}}>Subir Foto</button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




                    
                </div>
              </div>

            </div>




  {
    // show_ModalCambiarFotoPersonal && (
    //   <ModalCambiarFotoPersonal show={show_ModalCambiarFotoPersonal} handleClose={handleClose_ModalCambiarFotoPersonal} ciudadano={ciudadano_seleccionado} />
    //   )
  }



  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={empleado_seleccionado} />
      )

  }




          </>

      );
  

}

export default CargarFotosEmpleados
