import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import ModalDetallesDepartamento from './ModalDetallesDepartamento'

import ModalEditarDepartamento from './ModalEditarDepartamento'

import { Accordion } from 'react-bootstrap'


import ModalCrearDepartamento from './ModalCrearDepartamento'


const Departamentos = () =>{

      const [ areas, setAreas ] = useState<any>([])
  const [ cargando_, setCargando ] = useState( true )

      const cargar = async () =>{
        try{
            setCargando(true)
            let res = await RequestService.getEndPoint('departamentos/panel')
            setAreas(res.data)
            setCargando(false)

        }
        catch(e){
          setCargando(false)
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);



  const [ departamento_seleccionado, setDepartamento ] = useState<any>({})
  const [ direccion_seleccionada, setDireccion ] = useState<any>({})
  const [ show_ModalDetallesDepartamento, setModalDetallesDepartamento ] = useState<boolean>(false)
  const handleShow_ModalDetallesDepartamento = (departamento:any) => {
    setDepartamento(departamento)
    setModalDetallesDepartamento(true)
  }
  const handleClose_ModalDetallesDepartamento = () => {
    setModalDetallesDepartamento(false)
  }



  const [ show_ModalEditarDepartamento, setModalEditarDepartamento ] = useState<boolean>(false)
  const handleShow_ModalEditarDepartamento = (departamento:any) => {
    setDepartamento(departamento)
    setModalEditarDepartamento(true)
  }
  const handleClose_ModalEditarDepartamento = () => {
        cargar()
    setModalEditarDepartamento(false)
  }



  const [ show_ModalCrearDepartamento, setModalCrearDepartamento ] = useState<boolean>(false)
  const handleShow_ModalCrearDepartamento = (direccion:any) => {
    setDireccion(direccion)
    setModalCrearDepartamento(true)
  }
  const handleClose_ModalCrearDepartamento = () => {
        cargar()
    setModalCrearDepartamento(false)
  }




      return (
          <>

                            
              <div className="container">
                  

                <h3>Áreas</h3>








                          {  (!cargando_) && (

                              <>

                                {
                                  // JSON.stringify(areas)
                                }
                                <div>
                                  {
                                    areas.map((area:any,index:number)=>

                                          <div key={ Math.random().toString(36).substr(2, 9) } >

                                                <h5>{area.area}</h5>
                                              {
                                                // JSON.stringify(area.direcciones)
                                              }


                                              <div className="card">
                                              {
                                                area.direcciones.map((direccion:any,index_direcciones:number)=>

                                                    <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                                                          <div className="card-header">
                                                              <small>Dirección</small>
                                                              <h6>{direccion.direccion}</h6>
                                                          </div>
                                                          <div className="card-body"  >

                                                            
                                                            
                                                            <ul className="list-inline">
                                                              <li className="list-inline-item">
                                                                <button className="btn btn-link btn-sm" onClick={(e:any)=>{handleShow_ModalCrearDepartamento(direccion)}}><small>Agregar Nuevo Departamento</small></button>
                                                              </li>
                                                            </ul>
                                                            
                                                              {
                                                                // JSON.stringify(direccion)
                                                              }

                                                              <table className="table">
                                                                <thead>
                                                                  <tr>
                                                                    <th scope="col">Clave de Departamento</th>
                                                                    <th scope="col">Departamento</th>
                                                                    <th scope="col">PBR</th>
                                                                    <th scope="col">Empleados</th>
                                                                    <th scope="col"></th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {
                                                                    direccion.departamentos.map((departamento:any,index_departamento:number)=>
                                                                          <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                                            <td className="v" style={{width:200}}>
                                                                              <p className="nm">{departamento.clave_departamento}</p>
                                                                            </td>
                                                                            <td className="v" >
                                                                              <p className="nm"><b>{departamento.departamento}</b></p>
                                                                            </td>
                                                                            <td className="v" style={{width:140}}>
                                                                              <p className="nm">{departamento.pbr}</p>
                                                                              {
                                                                                // JSON.stringify(departamento)
                                                                              }
                                                                            </td>
                                                                            <td className="v" style={{width:100}}>
                                                                              <p className="nm"><b>{departamento.numero_empleados}</b></p>
                                                                            </td>
                                                                            <td className="v" style={{width:40}}>
                                                                              <a onClick={(e:any)=>{handleShow_ModalEditarDepartamento(departamento)}} className="btn btn-sm btn-primary"><i className="fa fa-edit"/></a>
                                                                            </td>
                                                                          </tr>
                                                                      )
                                                                  }
                                                                </tbody>
                                                              </table>

                                                          </div>

                                                    </React.Fragment>

                                                  )
                                              }
                                              </div>





                                          </div>

                                      )
                                  }

                                </div>

                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




              </div>            






  {
    show_ModalDetallesDepartamento && (
      <ModalDetallesDepartamento show={show_ModalDetallesDepartamento} handleClose={handleClose_ModalDetallesDepartamento} departamento={departamento_seleccionado} />
      )

  }


  {
    show_ModalEditarDepartamento && (
      <ModalEditarDepartamento show={show_ModalEditarDepartamento} handleClose={handleClose_ModalEditarDepartamento} departamento={departamento_seleccionado} />
      )

  }



  {
    show_ModalCrearDepartamento && (
      <ModalCrearDepartamento show={show_ModalCrearDepartamento} handleClose={handleClose_ModalCrearDepartamento} direccion={direccion_seleccionada} />
      )

  }

          </>

      );
  

}

export default Departamentos
