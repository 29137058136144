import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

const ModalEditarDireccion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, empleado } = params




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          direccion : yup.string().required(mensajes_validacion_generico),
          telefono : yup.string(), //.required(mensajes_validacion_generico),
          celular : yup.string(), //.required(mensajes_validacion_generico),
          email : yup.string(), //.required(mensajes_validacion_generico),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `contratos/actualizar_direccion/${empleado.uuid_ciudadano}` , { ...registro })

        if(res_){
          toast.success('La información de dirección y contacto se actualizó')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{empleado.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{empleado.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>
            </div>
          </div>





    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            direccion : empleado.direccion,
            telefono : empleado.informacion_personal.telefono||'',
            celular : empleado.informacion_personal.celular||'',
            email : empleado.informacion_personal.email||'',

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="direccion">
              <Form.Label>Dirección Completa</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.direccion}</>} </Form.Control.Feedback>
            </Form.Group>


            <div className="row">
               <div className="col">
                  <Form.Group   className="mb-3" controlId="telefono">
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                      name="telefono"
                      value={values.telefono}
                      onChange={(e)=>{ setFieldValue('telefono', (e.target.value ) ) }}
                      isInvalid={!!errors.telefono}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.telefono}</>} </Form.Control.Feedback>
                  </Form.Group>
               </div>
               <div className="col">
                  <Form.Group   className="mb-3" controlId="celular">
                    <Form.Label>Celular</Form.Label>
                    <Form.Control
                      name="celular"
                      value={values.celular}
                      onChange={(e)=>{ setFieldValue('celular', (e.target.value ) ) }}
                      isInvalid={!!errors.celular}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.celular}</>} </Form.Control.Feedback>
                  </Form.Group>
               </div>
            </div>

 

                  <Form.Group   className="mb-3" controlId="celular">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      value={values.email}
                      onChange={(e)=>{ setFieldValue('email', (e.target.value ) ) }}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.email}</>} </Form.Control.Feedback>
                  </Form.Group>





        </Form>
      )}
    </Formik>




          {
            // JSON.stringify(empleado)
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarDireccion;