import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { calcularDiferenciaEnDias } from '../../services/funcionesPublicas'
import { SERVER_ } from  '../../config';


const ModalContratoEmpleado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, empleado } = params


const sumar6Meses = (fecha:any)=> {
  const fechaActual = fecha;
  fechaActual.setMonth(fechaActual.getMonth() + 6);
  return fechaActual //.toISOString().split('T')[0];
}

const restar6Meses =(fecha:any) => {
  const fechaActual = fecha;
  fechaActual.setMonth(fechaActual.getMonth() - 6);
  return fechaActual //.toISOString().split('T')[0];
}





  const [ fecha_inicio, setFechaInicio ] = useState<any>( new Date() )
  const [ fecha_fin, setFechaFin] = useState<any>( sumar6Meses( new Date() ) )

  const abrirContrato = ()=>{
    window.open(`${SERVER_}contrato_administrativo/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_inicio).format('YYYY-MM-DD')}/${empleado.uuid_ciudadano}`)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Descarga de Contrato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }


          <small>Número de Empleado</small>
          <h5>{empleado.numero_empleado||'-'}</h5>


          <small>Nombre(s)</small>
          <h5>{empleado.nombres} {empleado.paterno} {empleado.materno} </h5>



            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Inicio del Contrato</Form.Label>
                    <DatePicker selected={fecha_inicio} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) =>{ setFechaInicio ( date );  }} />
                  </div>
              </div>
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Fin del Contrato</Form.Label>
                    <DatePicker selected={fecha_fin} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) =>{ setFechaFin( date );  }} />
                  </div>
              </div>
            </div>

            {
              // JSON.stringify(fecha_inicio)
            }


            {
              // JSON.stringify(fecha_fin)
            }


            <p>Total de {calcularDiferenciaEnDias(fecha_inicio,fecha_fin)} días de contrato</p>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={abrirContrato} type="button">Imprimir Contrato</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalContratoEmpleado;