import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';


import { SERVER_ } from '../../config'


const ModalGestionDocumentacion = ( params : any ) => {


  const dialog = new Dialog();


  const user = AuthService.getCurrentUser()

  const { show, handleClose, ciudadano } = params


  const [ lista_archivos, setLista_archivos ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarLista_archivos = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`ciudadano/archivos_anexos/${ciudadano.uuid_ciudadano}`)
      setLista_archivos(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarLista_archivos()
    cargarTipos_anexos()
  }, []);




  const [ tipos_anexos, setTipos_anexos ] = useState<any>([])
  const cargarTipos_anexos = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`lista/tipos_anexos`)
      setTipos_anexos(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }

 





const schema = Yup.object().shape({
  idtipo_anexo: Yup.string().required('El tipo de anexo es obligatorio'),
  archivo: Yup.string().required('El archivo es obligatorio').matches(/^data:(.*?);base64,(.*)$/, 'El archivo debe estar en formato base64')
});

  const [archivoBase64, setArchivoBase64] = useState('');

  const formik = useFormik({
    initialValues: {
      idtipo_anexo: '',
      archivo: ''
    },
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      // Aquí puedes enviar los datos del formulario
      console.log('Valores del formulario:', values);
      

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: null,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `ciudadano/agregar_archivo_anexo/${ciudadano.uuid_ciudadano}` , values )

        if(res_){
          toast.success('El Anexo fue agregado')
          setTimeout(()=>{
            resetForm();
            cargarLista_archivos()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     



    }
  });

  const handleArchivoChange = (event:any) => {
    const archivo : any = event.currentTarget.files[0];
    const reader : any = new FileReader();
    reader.onloadend = () => {
      setArchivoBase64(reader.result);
      formik.setFieldValue('archivo', reader.result);
    };
    reader.readAsDataURL(archivo);
  };






const eliminar_anexo = async (event:any,uuid_anexo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `ciudadano/anexo/eliminar_anexo/${uuid_anexo}`)

        if(res_){
          toast.warning('El anexo se eliminó correctamente')
          setTimeout(()=>{
            cargarLista_archivos()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}
 



  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Gestión de Documentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{ciudadano.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{ciudadano.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{ciudadano.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{ciudadano.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{ciudadano.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{ciudadano.numero_empleado}</h6>
            </div>
          </div>




          <Tabs
            defaultActiveKey="carga"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="archivos" title="Anexos">

                {
                  // JSON.stringify(ciudadano)
                }

                {
                  // JSON.stringify(lista_archivos)
                }





                {
                  lista_archivos.length === 0 && (

                      <div className="alert alert-primary" role="alert">
                        Aún no se cargan archivos anexos para este trabajador
                      </div>
                    )
                }





                {
                  lista_archivos.length > 0 && (

                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Tipo de Anexo</th>
                              <th scope="col">Creación</th>
                              <th scope="col"> </th>
                              <th scope="col"> </th>
                            </tr>
                          </thead>
                          <tbody>

                              {
                                lista_archivos.map((archivo:any,indice_archivo:number)=>
                                    <React.Fragment>
                                        <tr>
                                          <td>
                                            <p className="nm"><b>{archivo.idtipo_anexo.tipo_anexo}</b></p>
                                          </td>
                                          <td className="v text-left" style={{"width":"240px"}}>
                                            <p className="nm"><b>{archivo.fecha_creacion}</b></p>
                                          </td>
                                          <td className="v text-left" style={{"width":"40px"}}>
                                            <a target="_blank" href={`${SERVER_}ciudadanos/archivos/anexos/${archivo.uuid}`}><small>Abrir</small></a>
                                          </td>
                                          <td className="v text-left" style={{"width":"40px"}}>
                                            <button className="btn btn-sm btn-danger" onClick={(e:any)=>{eliminar_anexo(e,archivo.uuid)}}><i className="fa fa-trash"/></button>
                                          </td>
                                        </tr> 


                                    </React.Fragment>
                                  )
                              }


                          </tbody>
                        </table>


                    )
                }




            </Tab>
            <Tab eventKey="carga" title="Cargar Anexo">





                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="idtipo_anexo">
                    <Form.Label>Tipo de anexo:</Form.Label>
                    <Form.Control
                      as="select"
                      name="idtipo_anexo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.idtipo_anexo}
                      isInvalid={!!(formik.touched.idtipo_anexo && formik.errors.idtipo_anexo)}
                    >
                      <option value="">Selecciona un tipo de anexo</option>
                        {
                          tipos_anexos.map((tipo_anexo:any,indice_tipo_anexo:number)=> <option key={indice_tipo_anexo} value={tipo_anexo.uuid}>{tipo_anexo.tipo_anexo}</option> )
                        }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.idtipo_anexo}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="archivo">
                    <Form.Label>Archivo:</Form.Label>
                    <input
                      type="file"
                      accept=".pdf"
                      name="archivo"
                      onChange={handleArchivoChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${formik.touched.archivo && formik.errors.archivo ? 'is-invalid' : ''}`}
                    />
                    {formik.touched.archivo && formik.errors.archivo && (
                      <div className="invalid-feedback">{formik.errors.archivo}</div>
                    )}
                  </Form.Group>

                  <Button className="mt-4" variant="primary" type="submit">
                    Agregar Anexo
                  </Button>
                </Form>

            </Tab> 
          </Tabs>








        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalGestionDocumentacion;