import React, { useState, useRef } from 'react';

import RequestService from "../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../services/Dialogs'



import AvatarEditor from 'react-avatar-editor'

import { SERVER_ } from  '../config';


const ModalCambiarFoto = ( params : any ) => {

  const dialog = new Dialog()



  const { show, handleClose, usuario } = params


  const [imgSrc, setImgSrc] = useState('')
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop({undefined}) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        let imagen = reader.result?.toString() || ''
        if(imagen!==''){
          var imag_ = new Image();
          imag_.src = imagen;
          // console.log(imag_)
          // var newDataUri = imageToDataUri(imag_, targetWidth, targetHeight)
        }
        setImgSrc(imagen)

      })
      reader.readAsDataURL(e.target.files[0])
    }
  }


  const onClickSave = () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.getImageScaledToCanvas()
    }
  }


  const [ editor, setEditorRef ] = useState<any>({})


const confirmarundefined = async(e:any) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
      const canvas = editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.getImageScaledToCanvas()
      // console.log(editor.getImage().toDataURL())

          let res = await RequestService.update('perfil/cambiar_foto/'+usuario.uuid_ciudadano, { foto : editor.getImage().toDataURL().split(',')[1], crop : "crop" } )
          if(res.data){
            toast.success('Se actualizo el estatus del Trámite')
            // await cargarMiReporte(pagina)
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas al intentar finalizar la verificación del trámite')
    }

  }


  const [ skale, setSkale ] = useState<any>(1)


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(usuario)
          }



          <p className="text-center">
            <img src={`${SERVER_}ciudadano/foto/${usuario.uuid}/${new Date().toString()}`} style={{width:'50%'}} />
          </p>

          <p><small>Número de Empleado</small></p>
          <h5 className="mb-4">{usuario.numero_empleado}</h5>


          <p><small>Empleado</small></p>
          <h5 className="mb-4">{usuario.nombres} {usuario.paterno} {usuario.materno}</h5>






          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">Selecciona una foto</label>
            <input className="form-control" type="file" accept="image/*" onChange={onSelectFile} />
          </div>

          {
            imgSrc && (
                <>
                  <AvatarEditor ref={setEditorRef} image={imgSrc} width={400} height={400} border={50} scale={skale} />

                  <p>
                    <input type='range' onChange={(e:any)=>{setSkale(e.target.value)}} min={1} max={10} step={.1} value={skale} className='custom-slider' />
                  </p>

                </>
              )
          }


          
{
        // <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={c => setCrop(c)} aspect={aspect}>
        //   <img src={imgSrc} onLoad={onImageLoad} />
        // </ReactCrop>
      }

{
  // JSON.stringify(imgSrc)
}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success"   onClick={(e)=>{confirmarundefined(e)}} type="button">Cambiar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCambiarFoto;