import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import Dialog from '../../services/Dialogs'


import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import './Calendario.scss'


interface DateObject {
  fecha: string;
  fecha_devolvio: string | null;
}



const ModalDevolutivos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, empleado } = params

const dialog = new Dialog();


  const [selectedDates, setSelectedDates] = useState<DateObject[]>([]);

  // Función para manejar el clic en una fecha
  const handleDateClick = (date: string) => {
    const selectedIndex = selectedDates.findIndex(item => item.fecha === date);
    if (selectedIndex === -1) {
      setSelectedDates([...selectedDates, { fecha: date, fecha_devolvio: null }]);
    } else {
      const updatedDates = [...selectedDates];
      updatedDates.splice(selectedIndex, 1);
      setSelectedDates(updatedDates);
    }
  };

  // Función para renderizar el calendario
  const renderCalendar = (): JSX.Element[] => {
    const months: string[] = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    const daysOfWeek: string[] = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
    const year: number = 2024; // Año para el que se renderiza el calendario
    const calendar: JSX.Element[] = [];
    
    for (let i = 0; i < months.length; i += 2) {
      const month1: string = months[i];
      const month2: string = months[i + 1];
      const month1Days: number[] = getDaysInMonth(i, year);
      const month2Days: number[] = getDaysInMonth(i + 1, year);
      const month1StartingDay: number = new Date(year, i, 1).getDay(); // Día de la semana en que inicia el mes
      const month2StartingDay: number = new Date(year, i + 1, 1).getDay(); // Día de la semana en que inicia el mes

      calendar.push(
        <div key={i} className="calendar-months">
          <div className="calendar-month">
            <div className="month-header">{month1}</div>
            <div className="days">
              {daysOfWeek.map((day, index) => (
                <div key={index} className="day-of-week">{day}</div>
              ))}
              {Array(month1StartingDay).fill(null).map((_, index) => (
                <div key={index} className="empty-day"></div>
              ))}
              {month1Days.map(day => (
                <div
                  key={day}
                  className={`day ${isSelectedDate(`${year}-${formatMonth(i + 1)}-${formatDay(day)}`) ? 'selected' : ''}`}
                  onClick={() => handleDateClick(`${year}-${formatMonth(i + 1)}-${formatDay(day)}`)}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
          <div className="calendar-month">
            <div className="month-header">{month2}</div>
            <div className="days">
              {daysOfWeek.map((day, index) => (
                <div key={index} className="day-of-week">{day}</div>
              ))}
              {Array(month2StartingDay).fill(null).map((_, index) => (
                <div key={index} className="empty-day"></div>
              ))}
              {month2Days.map(day => (
                <div
                  key={day}
                  className={`day ${isSelectedDate(`${year}-${formatMonth(i + 2)}-${formatDay(day)}`) ? 'selected' : ''}`}
                  onClick={() => handleDateClick(`${year}-${formatMonth(i + 2)}-${formatDay(day)}`)}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    
    return calendar;
  };

  // Función para obtener los días en un mes
  const getDaysInMonth = (month: number, year: number): number[] => {
    const date: Date = new Date(year, month, 1);
    const days: number[] = [];
    while (date.getMonth() === month) {
      days.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  // Función para verificar si una fecha está seleccionada
  const isSelectedDate = (date: string): boolean => {
    return selectedDates.some(item => item.fecha === date);
  };

  // Función para formatear el mes
  const formatMonth = (month: number): string => {
    return month < 10 ? `0${month}` : `${month}`;
  };

  // Función para formatear el día
  const formatDay = (day: number): string => {
    return day < 10 ? `0${day}` : `${day}`;
  };



  const handleDateChange = (index: number, tipo : string,  event: React.ChangeEvent<HTMLInputElement>): void => {
    const newDates = [...selectedDates];
    if(tipo === 'fecha_devolvio') newDates[index].fecha_devolvio = event.target.value;
    if(tipo === 'fecha') newDates[index].fecha = event.target.value;
    // Aquí puedes realizar alguna acción con las nuevas fechas (por ejemplo, actualizar el estado)
    console.log(newDates);
    setSelectedDates(newDates);
  };

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const hasAllFechaDevolvios = (dates: any[]): boolean => {
  return dates.every(item => item.fecha_devolvio !== null);
};


  const crearRegistroDevolutivos = async(event:any) => {

    const newDates = [...selectedDates];

    console.log(newDates);

    if(!hasAllFechaDevolvios(newDates)){
      toast.error("Debes seleccionar todos los días que se devuelven en la lista de días trabajados")
      return
    }

    if(newDates.length > 0){

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res = await RequestService.getEndPointPost('devolutivos/agregar/'+empleado.uuid_ciudadano, { dias_seleccionados : newDates, observaciones : detalles })

        if(res){
          toast.success('El Registro de movimiento de Días Devolutivos fueron notificados al departamento de Recursos Humanos')        
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }



    }
    else{
      toast.error('Debes de agregar por lo menos una fecha para aplicar los Días Devolutivos')
    }

  } 


const removeDate = (index: number) => {
  const newDates = [...selectedDates];
  newDates.splice(index, 1);
  setSelectedDates(newDates);
};


const [detalles, setDetalles] = useState<string>('');


 
  const [ dias_disponibles, setDiasDisponibles ] = useState<any>([])

  const cargarDiasDisponibles = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`incidencias/devolutivos/${empleado.uuid_ciudadano}`)
      setDiasDisponibles(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar notificar a Recursos Humanos')
    }
  }




  React.useEffect(() => {
    cargarDiasDisponibles()
  }, []);





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Devolutivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Empleado</h5>

              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>


          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>




    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Calendario">

          <div className="calendar-container mb-4">
            <h2>Calendario</h2>
            <div className="calendar">{renderCalendar()}</div>
          </div>


      </Tab>
      <Tab eventKey="profile" disabled={selectedDates.length === 0}  title="Lista">


          {
            // JSON.stringify(selectedDates)
          }

          {
            selectedDates.length > 0 && (
                <React.Fragment>


                <div className="alert alert-ligh t"  >

                <h6 className="nm">Listado de Días Trabajados y Días de devolución</h6>
                  
                  <div className="row ">
                    <div className="col-md-6">
                      <h5>Fecha</h5>
                    </div>
                    <div className="col-md-6">
                      <h5>Fecha de devolución</h5>
                    </div>
                  </div>
                  {selectedDates.map((item, index) => (
                    <div key={index} className="row">
                      <div className="col-md-6">
                        <input
                          type="date"
                          className="form-control mb-1"
                          value={item.fecha || ''}
                          onChange={(event) => handleDateChange(index, 'fecha', event)}
                        />
                      </div>
                      <div className="col-md-5 mb-1">
                        <input
                          type="date"
                          className="form-control"
                          value={item.fecha_devolvio || ''}
                          onChange={(event) => handleDateChange(index, 'fecha_devolvio', event)}
                        />
                      </div>
                      <div className="col-md-1 mb-1">
                        <button onClick={(event) => removeDate(index)} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                      </div>
                    </div>
                  ))}



                </div>






                    <div className="mb-3 mt-3">
                      <label htmlFor="razon" className="form-label">Detalles de la solicitud:</label>
                      <textarea className="form-control" name="observaciones"  value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>


                </React.Fragment>
              )
          }

      </Tab>
    </Tabs>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={crearRegistroDevolutivos} disabled={selectedDates.length === 0} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalDevolutivos;