import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';

import moment from 'moment'

const ModalReporteNomina = ( params : any ) => {


  const { show, handleClose, config } = params

  const [ quincena, setQuincena ] = useState<number>(config.nomina_contador)

const obtenerArregloAnios = () => {
  var anioActual = new Date().getFullYear();
  var arregloAnios = [anioActual];

  for (var i = 1; i < 4; i++) {
    arregloAnios.push(anioActual - i);
  }

  return arregloAnios;
}

  const [ anio_seleccionado, setAnioSeleccionado ] = useState<any>(moment().format('YYYY'))


  const [ cargando_, setCargando ] = useState<boolean>(false)
  const [ reporte, setReporte ] = useState<any>({ movimientos : [], acumulados_movimientos : [], acumulados_incidencias : [] })


  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`nomina/reporte_layout/${anio_seleccionado}/${quincena}`)
      if(!response){
        throw "El servidor no dio respuesta"
      }
      setReporte(response.data)
      setCargando(false)
    }
    catch(e){
      console.log(e)
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }



  React.useEffect(() => {
    cargarMiReporte()
  }, []);


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>NÓMINA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(config)
          }

          <h6>Descargas</h6>
          <p>Reporte Layout de Nómina</p>


                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Año</label>
                  {
                    obtenerArregloAnios().map( (year:any,indice_year:number) => <React.Fragment>
                                 <div key={indice_year} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id={`inlineCheckbox1_${year}`} checked={anio_seleccionado==year} onChange={(e:any)=>{setAnioSeleccionado(year)}} />
                                    <label className="form-check-label" htmlFor={`inlineCheckbox1_${year}`}>{year}</label>
                                  </div>
                    </React.Fragment> )
                  }
                </div>




                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Quincena</label>
                  <select className="form-select" value={quincena} onChange={(e:any)=>{ if(e.target.value > config.nomina_contador) toast.warning('No puedes seleccionar una nómina mayor a la actual') ; else setQuincena(e.target.value); }} aria-label="Default select example">
                    <option  value={0} disabled>Debes seleccionar una quincena</option>
                    <option disabled={ (config.nomina_contador) < 1  } value={1}>Q1</option>
                    <option disabled={ (config.nomina_contador) < 2  } value={2}>Q2</option>
                    <option disabled={ (config.nomina_contador) < 3  } value={3}>Q3</option>
                    <option disabled={ (config.nomina_contador) < 4  } value={4}>Q4</option>
                    <option disabled={ (config.nomina_contador) < 5  } value={5}>Q5</option>
                    <option disabled={ (config.nomina_contador) < 6  } value={6}>Q6</option>
                    <option disabled={ (config.nomina_contador) < 7  } value={7}>Q7</option>
                    <option disabled={ (config.nomina_contador) < 8  } value={8}>Q8</option>
                    <option disabled={ (config.nomina_contador) < 9  } value={9}>Q9</option>
                    <option disabled={ (config.nomina_contador) < 10  } value={10}>Q10</option>
                    <option disabled={ (config.nomina_contador) < 11  } value={11}>Q11</option>
                    <option disabled={ (config.nomina_contador) < 12  } value={12}>Q12</option>
                    <option disabled={ (config.nomina_contador) < 13  } value={13}>Q13</option>
                    <option disabled={ (config.nomina_contador) < 14  } value={14}>Q14</option>
                    <option disabled={ (config.nomina_contador) < 15  } value={15}>Q15</option>
                    <option disabled={ (config.nomina_contador) < 16  } value={16}>Q16</option>
                    <option disabled={ (config.nomina_contador) < 17  } value={17}>Q17</option>
                    <option disabled={ (config.nomina_contador) < 18  } value={18}>Q18</option>
                    <option disabled={ (config.nomina_contador) < 19  } value={19}>Q19</option>
                    <option disabled={ (config.nomina_contador) < 20  } value={20}>Q20</option>
                    <option disabled={ (config.nomina_contador) < 21  } value={21}>Q21</option>
                    <option disabled={ (config.nomina_contador) < 22  } value={22}>Q22</option>
                    <option disabled={ (config.nomina_contador) < 23  } value={23}>Q23</option>
                    <option disabled={ (config.nomina_contador) < 24  } value={24}>Q24</option>
                  </select>
                </div>



                <small>Layout</small>
              <ul className="list-inline">
                <li className="list-inline-item"><a target="_blank" href={`${SERVER_}nomina/layout/${anio_seleccionado}/${quincena}/0`} className="btn btn-link">Movimientos</a></li>
                <li className="list-inline-item"><a target="_blank" href={`${SERVER_}nomina/layout/${anio_seleccionado}/${quincena}/1`} className="btn btn-link">Extraordinarias</a></li>
                <li className="list-inline-item"><a target="_blank" href={`${SERVER_}nomina/layout_bajas/${anio_seleccionado}/${quincena}`} className="btn btn-link">Bajas</a></li>
                <li className="list-inline-item"><a target="_blank" href={`${SERVER_}nomina/reporte_faltas/${anio_seleccionado}/${quincena}`} className="btn btn-link">Faltas</a></li>
              </ul>






                          <h5>Reporte de Movimientos</h5>
                          <p><small className="pointer" onClick={(e:any)=>{cargarMiReporte()}}>Actualizar Reporte</small></p>

                                        <Tabs
                                          defaultActiveKey="incidencias"
                                          id="uncontrolled-tab-example"
                                          className="mb-3"
                                        >


                                          <Tab eventKey="movimientos" title="Movimientos">


                                                        {
                                                          // JSON.stringify(reporte.acumulados_movimientos)
                                                        }



                                                        {
                                                          reporte.acumulados_movimientos.length === 0 && (
                                                              <p><i className="fa fa-warning"/> No se han registrado movimientos para esta quincena</p>
                                                            )
                                                        }


                                                        {
                                                          reporte.acumulados_movimientos.length > 0 && (

                                                              <table className="table">
                                                                <thead>
                                                                  <tr>
                                                                    <th className="text-center" scope="col">Cantidad</th>
                                                                    <th className="text-center" scope="col">Movimiento</th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {
                                                                    reporte.acumulados_movimientos.map((movimiento:any,indice_movimiento:number)=>

                                                                      <tr>
                                                                        <td className="v text-center" style={{width:'35%'}}>
                                                                          <p className="nm"><b>{movimiento.cantidad}</b></p>
                                                                        </td>
                                                                        <td className="v">
                                                                          <p className="nm">{movimiento.movimiento}</p>
                                                                          {
                                                                            movimiento.idmovimiento === 4 && (
                                                                                <React.Fragment>
                                                                                { movimiento.cambio_adscripcion > 0 && <p className="nm"><span className="badge text-bg-light">CAMBIO DE ADSCRIPCIÓN {movimiento.cambio_adscripcion}</span></p>}
                                                                                { movimiento.cambio_puesto > 0 && <p className="nm"><span className="badge text-bg-light">CAMBIO DE PUESTO {movimiento.cambio_puesto}</span></p>}
                                                                                { movimiento.cambio_plaza > 0 && <p className="nm"><span className="badge text-bg-light">CAMBIO DE PLAZA {movimiento.cambio_plaza}</span></p>}
                                                                                { movimiento.cambio_sueldo > 0 && <p className="nm"><span className="badge text-bg-light">MODIFICACIÓN DE SUELDO {movimiento.cambio_sueldo}</span></p>}
                                                                                { movimiento.cambio_alta_imss > 0 && <p className="nm"><span className="badge text-bg-light">ASEGURAMIENTO {movimiento.cambio_alta_imss}</span></p>}
                                                                                { movimiento.cambio_rfc > 0 && <p className="nm"><span className="badge text-bg-light">CORRECCIÓN DE RFC {movimiento.cambio_rfc}</span></p>}
                                                                                { movimiento.cambio_curp > 0 && <p className="nm"><span className="badge text-bg-light">CORRECCIÓN DE CURP {movimiento.cambio_curp}</span></p>}
                                                                                { movimiento.cambio_fecha_ingreso > 0 && <p className="nm"><span className="badge text-bg-light">CORRECCIÓN DE FECHA DE ALTA {movimiento.cambio_fecha_ingreso}</span></p>}
                                                                                </React.Fragment>
                                                                              )
                                                                          }
                                                                        </td>
                                                                      </tr> 

                                                                      )
                                                                  }

                                                                </tbody>
                                                              </table>

                                                            )
                                                        }







                                          </Tab>
 


                                          <Tab eventKey="incidencias" title="Incidencias">

 

                                                        {
                                                          // JSON.stringify(reporte.acumulados_incidencias)
                                                        }



                                                        {
                                                          reporte.acumulados_incidencias.length === 0 && (
                                                              <p><i className="fa fa-warning"/> No se han registrado movimientos para esta quincena</p>
                                                            )
                                                        }


                                                        {
                                                          reporte.acumulados_incidencias.length > 0 && (

                                                            <table className="table">
                                                              <thead>
                                                                <tr>
                                                                  <th className="text-center" scope="col">Cantidad</th>
                                                                  <th className="text-center" scope="col">Movimiento</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {
                                                                  reporte.acumulados_incidencias.map((movimiento:any,indice_movimiento:number)=>

                                                                    <tr>
                                                                      <td className="v text-center" style={{width:'35%'}}>
                                                                        <p className="nm"><b>{movimiento.cantidad}</b></p>
                                                                      </td>
                                                                      <td className="v">
                                                                        <p className="nm">{movimiento.movimiento}</p> 

                                                                        {
                                                                          movimiento.idmovimiento === 9 && (
                                                                              <React.Fragment>
                                                                              { (movimiento.faltas_justificados + movimiento.faltas) > 0 && <p className="nm"><span className="badge text-bg-light">TOTAL DE FALTAS {movimiento.faltas + movimiento.faltas_justificados}</span></p>}
                                                                              { movimiento.faltas > 0 && <p className="nm"><span className="badge text-bg-light">FALTAS SIN JUSTIFICAR {movimiento.faltas}</span></p>}
                                                                              { movimiento.faltas_justificados > 0 && <p className="nm"><span className="badge text-bg-light">FALTAS JUSTIFICADAS {movimiento.faltas_justificados}</span></p>}
                                                                              </React.Fragment>
                                                                            )
                                                                        }

                                                                        {
                                                                          movimiento.idmovimiento === 10 && (
                                                                              <React.Fragment>
                                                                              { (movimiento.retardos_justificados + movimiento.retardos) > 0 && <p className="nm"><span className="badge text-bg-light">TOTAL DE RETARDOS {movimiento.retardos + movimiento.retardos_justificados}</span></p>}
                                                                              { movimiento.retardos > 0 && <p className="nm"><span className="badge text-bg-light">RETARDOS SIN JUSTIFICAR {movimiento.retardos}</span></p>}
                                                                              { movimiento.retardos_justificados > 0 && <p className="nm"><span className="badge text-bg-light">RETARDOS JUSTIFICADOS {movimiento.retardos_justificados}</span></p>}
                                                                              </React.Fragment>
                                                                            )
                                                                        }


                                                                      </td>
                                                                    </tr> 

                                                                    )
                                                                }

                                                              </tbody>
                                                            </table>

                                                            )
                                                        }





                                          </Tab>
 



                                        </Tabs>






        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteNomina;