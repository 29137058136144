import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Form, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'



const ModalOtrasIncidencias = ( params : any ) => {

  const dialog = new Dialog()

  const { show, handleClose, empleado } = params

  const [ cargando_, setCargando ] = useState( false )

  const [ dias_disponibles, setDiasDisponibles ] = useState<any>([])

 

  const [key, setKey] = useState(0);


  const enviar = async()=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        // let dias_seleccionados = []
        // for(let dias of dias_disponibles[0]){
        //   if(!dias.deshabilitada && dias.checked) dias_seleccionados.push(dias)
        // }

        let res = await RequestService.getEndPointPost('otras_incidencias/agregar/'+empleado.uuid_ciudadano, { dias_seleccionados : fechas_devolutivas, observaciones : detalles })
        if(res.data){
          // console.log(dias_seleccionados)
          toast.success('Las Incidencias fueron notificados al departamento de Recursos Humanos')        
          handleClose()
        }
        setCargando(false)
      }
      catch(e:any){
        setCargando(false)
        console.log(e)
        toast.error(e.response.data)
      }
    }
  }



const [detalles, setDetalles] = useState<string>('');


const [ campo_fecha, setCampoFecha ] = useState<any>(new Date())


const [ fechas_devolutivas, setFechasDevolutivas ] = useState<any>([])

const agregarFecha = (la_fecha:any) => {
  // Verificar si la fecha ya existe en el arreglo
  const fechaExistente = fechas_devolutivas.find((fecha:string) => fecha === la_fecha);

  if (fechaExistente) {
    toast.warning('Esta fecha ya fue agregada');
  } 
  else {
    let fechas = [...fechas_devolutivas]
    fechas.push(moment(campo_fecha).format('YYYY-MM-DD'));
    setFechasDevolutivas(fechas)
  }
}


const eliminarFechaPorPosicion = (posicion:number) => {
  // Verificar si la posición es válida
  if (posicion >= 0 && posicion < fechas_devolutivas.length) {
    // Eliminar la fecha en la posición dada
    let fechas = [...fechas_devolutivas]
    fechas.splice(posicion, 1);
    setFechasDevolutivas(fechas)
    toast.warning('Fecha eliminada correctamente');
  }  
}



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Otras Incidencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }
          {
            // JSON.stringify(dias_disponibles)
          }
          <h5>Empleado</h5>

              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>


          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


              <div className="input-group mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Fechas para Agregar</label>
                <DatePicker selected={campo_fecha} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) => setCampoFecha(date) } />
                <div className="form-text" style={{zIndex:0}} id="basic-addon4"><button type="button" onClick={(e:any)=>{agregarFecha(moment(campo_fecha).format('YYYY-MM-DD'))}} className="btn btn-link">Agregar Fecha</button></div>
              </div>

              {
                fechas_devolutivas.length === 0 && (

                  <div className="alert alert-warning" role="alert">
                    Aún no agregas fechas 
                  </div>

                  )
              }

              {
                fechas_devolutivas.length > 0 && (

                    <ol className="list-group list-group-numbered">
                      {
                        fechas_devolutivas.map((fecha:any,indice_fecha:number)=><li key={indice_fecha} className="list-group-item">{moment(fecha).format('dddd, DD [DE] MMMM [DE] YYYY').toUpperCase()} <span className="badge bg-primary rounded-pill"><i onClick={(e:any)=>{eliminarFechaPorPosicion(indice_fecha)}} className="fa fa-trash" /></span> </li>)
                      }
                    </ol>

                  )
              }




                    <div className="mb-3 mt-3">
                      <label htmlFor="razon" className="form-label">Detalles de la Incidencia:</label>
                      <textarea className="form-control" name="observaciones"  value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={enviar} disabled={ fechas_devolutivas.length === 0 } type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalOtrasIncidencias;