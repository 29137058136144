import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'
import 'moment/locale/es'



import ModalAsignarFechaFalta from './ModalAsignarFechaFalta'




const ControlFaltasRetardos = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`control_faltas_retardos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([false])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);





  const [ show_ModalAsignarFechaFalta, setModalAsignarFechaFalta ] = useState<boolean>(false)
  const [ incidencia_seleccionada, setIncidenciaSeleccionada ] = useState<any>(null)
  const [ incidencia_seleccionada_indice, setIncidenciaSeleccionadaIndice ] = useState<any>(null)
  const handleShow_ModalAsignarFechaFalta = (incidencia:any,incidencia_indice:number) => {
    setIncidenciaSeleccionada(incidencia)
    setIncidenciaSeleccionadaIndice(incidencia_indice)
    setModalAsignarFechaFalta(true)
  }
  const handleClose_ModalAsignarFechaFalta = (fecha_asignada:any) => {
    if(fecha_asignada){
      let i = {...incidencia_seleccionada}
      incidencia_seleccionada.fecha_aplica_nomina = fecha_asignada
    }
    setModalAsignarFechaFalta(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Control de Faltas y Retardos</h4>
                    <hr/>
                    

                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="VER SOLO FALTAS O RETARDOS NO ASIGNADOS" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                        </div>
                        
                      <ul className="list-inline">
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                      </ul>

                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col"># Empleado</th>
                                              <th scope="col">Nombres</th>
                                              <th scope="col">Paterno</th>
                                              <th scope="col">Materno</th>
                                              <th className="text-center" scope="col">Es Falta</th>
                                              <th className="text-center" scope="col">Es Retardo</th>
                                              <th className="text-center" scope="col">Justificada</th>
                                              <th className="text-center" scope="col">F Incidencia</th>
                                              <th className="text-center" scope="col">F Asignada</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (incidencia:any,indice:number) =>
                                                  <tr key={indice} className={ incidencia.fecha_aplica_nomina ? 'table-warning' : '' } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"80px"}}>
                                                      <small>{incidencia.numero_empleado}</small>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{incidencia.nombres}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small>{incidencia.paterno}</small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small>{incidencia.materno}</small></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                      <small>{!incidencia.retardo && ('SI')}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                      <small>{incidencia.retardo && ('SI')}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                      <small>{incidencia.justificada && ('SI')}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"160px"}}>
                                                      <small>{moment(incidencia.fecha_retardo).format('DD/MM/YYYY')}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"160px"}}>
                                                      {
                                                        incidencia.fecha_aplica_nomina && (<small>{moment(incidencia.fecha_aplica_nomina).format('DD/MM/YYYY') }</small>)
                                                      }
                                                    </td>
                                                    <td className="v text-right" style={{"width":"60px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalAsignarFechaFalta(incidencia,indice)}} className="btn btn-sm btn-primary"><i className="fa fa-calendar"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                </div>
              </div>

            </div>




  {
    show_ModalAsignarFechaFalta && (
      <ModalAsignarFechaFalta show={show_ModalAsignarFechaFalta} handleClose={handleClose_ModalAsignarFechaFalta} incidencia={incidencia_seleccionada} />
      )

  }




          </>

      );
  

}

export default ControlFaltasRetardos
