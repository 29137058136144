export const rol = (rol:string)=>{
    let r = '----'

 


    if(rol === 'ROLE_ADMIN') r = 'EJECUTIVO PRINCIPAL'
    if(rol === 'EJECUTIVO_PRIMARIO') r = 'EJECUTIVO 2'
    if(rol === 'MOVIMIENTOS_NOMINA') r = 'MOVIMIENTOS DE NÓMINA'
    if(rol === 'CONSTANCIAS') r = 'CONSTANCIAS'
    if(rol === 'INCIDENCIAS') r = 'INCIDENCIAS'
    if(rol === 'EJECUTIVO_SECUNDARIO') r = 'EJECUTIVO 3'
    if(rol === 'CONTRATOS') r = 'CONTRATOS'
    if(rol === 'ARCHIVOS') r = 'ARCHIVOS'
    if(rol === 'RECEPCION') r = 'RECEPCIÓN'
    if(rol === 'CAPTURISTA') r = 'CAPTURISTA DE OFICIOS'
    if(rol === 'USUARIO_OFICIOS') r = 'MIS OFICIOS'
    if(rol === 'NOMINA') r = 'USUARIO LAYOUT'
    if(rol === 'PENSIONADOS') r = 'GESTIÓN DE PENSIONADOS'

    if(rol === 'CARGA_FOTOS') r = 'CARGA DE FOTOS DE PERSONAL'

        
    if(rol === 'PLANEACION') r = 'ADMINISTRACIÓN PLANEACIÓN'
    if(rol === 'PLANEACION_REVISION') r = 'PLANEACIÓN REVISIÓN'
    if(rol === 'PLANEACION_DIRECCIONES') r = 'PLANEACIÓN DIRECCIONES'


    return r
}