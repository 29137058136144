import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'



import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'


import ModalResetPasswordUsuario from './ModalResetPasswordUsuario'


import ModalEditarUsuario from './ModalEditarUsuario'
import ModalNuevoUsuario from './ModalNuevoUsuario'


import { rol } from "../../services/rol";



import './role_colors.scss';

const Usuarios = () =>{



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})


  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint('listado_usuarios/'+arreglo_activo+'/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip+'&limit='+sessionsPerPage)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_activo, setActivo ] = useState<any>([true,true])

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true])
    setActivo([true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
    setActivo([false,false])
  }




  const [ asesor_editar, setAsesorEditar ] = useState<any>({})
  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }




  const [ show_ModalEditarUsuario, setModalEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalEditarUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalEditarUsuario(true)
  }
  const handleClose_ModalEditarUsuario = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarUsuario(false)
  }


 



  const [ show_ModalNuevoUsuario, setModalNuevoUsuario ] = useState<boolean>(false)
  const handleShow_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(true)
  }
  const handleClose_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(false)
  }


      React.useEffect(() => {
        busqueda()
      }, []);  


      return (
          <>


                  <div className="container">
              
                      <h3>Usuarios</h3>

                      <div className="row">
                        <div className="col-sm-12">





        <div className="row">
        
            <div className="">
              <label className="form-label">Búsqueda</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                <button onClick={busqueda} className="btn btn-default" type="button" ><i className="fa fa-search" /></button>
              </div>
            </div>

            <div>




                    <Form.Check inline name="group1" type="checkbox" label={rol('ROLE_ADMIN')} checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_0`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('EJECUTIVO_PRIMARIO')} checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('MOVIMIENTOS_NOMINA')} checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('CONSTANCIAS')} checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('INCIDENCIAS')} checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('EJECUTIVO_SECUNDARIO')} checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('CONTRATOS')} checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('ARCHIVOS')} checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('RECEPCION')} checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('NOMINA')} checked={arreglo_estatus[9]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[9] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('PENSIONADOS')} checked={arreglo_estatus[10]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[10] = e.target.checked;  setEstatus(ne);}} id={`opciones_10`} />


                    <Form.Check inline name="group1" type="checkbox" label={rol('PLANEACION')} checked={arreglo_estatus[11]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[11] = e.target.checked;  setEstatus(ne);}} id={`opciones_11`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('PLANEACION_REVISION')} checked={arreglo_estatus[12]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[12] = e.target.checked;  setEstatus(ne);}} id={`opciones_12`} />
                    <Form.Check inline name="group1" type="checkbox" label={rol('PLANEACION_DIRECCIONES')} checked={arreglo_estatus[13]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[13] = e.target.checked;  setEstatus(ne);}} id={`opciones_13`} />

                    <Form.Check inline name="group1" type="checkbox" label={rol('CARGA_FOTOS')} checked={arreglo_estatus[14]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[14] = e.target.checked;  setEstatus(ne);}} id={`opciones_14`} />

                    {
                    // <Form.Check inline name="group1" type="checkbox" label={rol('CAPTURISTA')} checked={arreglo_estatus[9]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[9] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                    // <Form.Check inline name="group1" type="checkbox" label={rol('USUARIO_OFICIOS')} checked={arreglo_estatus[10]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[10] = e.target.checked;  setEstatus(ne);}} id={`opciones_10`} />
                    }












{
              // <Form.Check
              //   inline
              //   name="group1"
              //   type="checkbox"
              //   label="ACTIVO"
              //   checked={arreglo_activo[0]}
              //   onChange={(e)=>{ var na = [...arreglo_activo]; na[0] = e.target.checked;  setActivo(na);}}
              //   id={`activo_1`}
              // />
              // <Form.Check
              //   inline
              //   name="group1"
              //   type="checkbox"
              //   label="NO ACTIVO"
              //   checked={arreglo_activo[1]}
              //   onChange={(e)=>{ var na = [...arreglo_activo]; na[1] = e.target.checked;  setActivo(na);}}
              //   id={`activo_2`}
              // />
}

{

              // <Form.Check inline name="group1" type="checkbox" label="ADMINISTRADOR" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
              // <Form.Check inline name="group1" type="checkbox" label="MOVIMIENTOS" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
              // <Form.Check inline name="group1" type="checkbox" label="NÓMINA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
              // <Form.Check inline name="group1" type="checkbox" label="INCIDENCIAS" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
              // <Form.Check inline name="group1" type="checkbox" label="RECEPCIÓN" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
              // <Form.Check inline name="group1" type="checkbox" label="OFICIOS" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
              // <Form.Check inline name="group1" type="checkbox" label="CONTRATOS" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
              // <Form.Check inline name="group1" type="checkbox" label="ARCHIVOS" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
              // <Form.Check inline name="group1" type="checkbox" label="CONSTANCIAS" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
              // <Form.Check inline name="group1" type="checkbox" label="CONTROL" checked={arreglo_estatus[9]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[9] = e.target.checked;  setEstatus(ne);}} id={`opciones_10`} />
}
            </div>
            

          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoUsuario()}}>Nuevo Usuarios</small></li>
          </ul>

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Tipo de Usuario</th>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Acceso</th>
                                              <th scope="col">Departamento</th>
                                              <th scope="col">Nombre</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>

                                                    <td className={`v text-left ${cual.rol}`} style={{"width":"160px"}}>
                                                        <p className="nm"><small><b>{rol(cual.rol)}</b></small></p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        {cual.username}
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                            
                                                      {
                                                        cual.activo === 1 && (
                                                            <span> SI</span>
                                                        )
                                                      }

                                                    </td>

                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm"><small><b>{cual.departamento}</b></small></p>
                                                    </td>

                                                    <td className="v text-left">
                                                        <p className="nm"><small><b>{cual.nombres}</b></small></p>
                                                        <small>{cual.paterno} {cual.materno}</small>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm"><small><b>{cual.email}</b></small></p>
                                                        <small>{cual.celular}</small>
                                                    </td>

                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button  variant="primary" onClick={()=>{handleShow_ModalResetPasswordUsuario(cual)}} size="sm"><i className="fa fa-key"/></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button  variant="warning" onClick={()=>{handleShow_ModalEditarUsuario(cual)}} size="sm"><i className="fa fa-edit"/></Button>
                                                      </td>

                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}





                        </div>
                      </div>
              
                  </div>


 
  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={asesor_editar} />
      )

  }            


    {
      show_ModalEditarUsuario && (
        <ModalEditarUsuario show={show_ModalEditarUsuario} handleClose={handleClose_ModalEditarUsuario}  usuario={asesor_editar} />
        )

    }



  {
    show_ModalNuevoUsuario && (
      <ModalNuevoUsuario show={show_ModalNuevoUsuario} handleClose={handleClose_ModalNuevoUsuario}  />
      )

  }



          </>

      );
  

}

export default Usuarios
