import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'


import { Table, Form, Dropdown } from 'react-bootstrap'

import { Tabs, Tab, Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'



import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'
// import 'moment/locale/es';


import { SERVER_ } from  '../../config';




import ModalActualizarMemo from './ModalActualizarMemo'
import ModalEditarMovimientoEmpleado from './Modales_edicion/ModalEditarMovimientoEmpleado'






import ModalEdicionBajas from './Modales_edicion/ModalEdicionBajas'


import ModalEditarReingreso from './Modales_edicion/ModalEditarReingreso'








import { numberWithCommas } from '../../services/funcionesPublicas'


import * as XLSX from 'xlsx';



const Movimientos = () =>{


const dialog = new Dialog();


  // const { show, handleClose,  } = params
  const user = AuthService.getCurrentUser()


  const [ cargando_, setCargando ] = useState<boolean>(false)
  const [ reporte, setReporte ] = useState<any>({ movimientos : [], acumulados_movimientos : [], acumulados_incidencias : [] })


  // const [ anio_nomina, setAnioNomina ] = useState<string>('2023')
  // const [ quincena_nomina, setQuincena ] = useState<string>('2')


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());


  const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]


  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`reportes/movimientos/${arreglo_movimientos}/${arreglo_nominas}/${arreglo_quincenas}/${anio_seleccionado}/busqueda?where=${text_busqueda}`)
      if(!response){
        throw "El servidor no dio respuesta"
      }
      setReporte(response.data)
      setStartDate_(startDate)
      setEndDate_(endDate)

      setCargando(false)
    }
    catch(e){
      console.log(e)
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }

 



  const [ arreglo_nominas, setArregloNominas ] = useState<any>([true,true,true,true])


  const todos = () =>{
    setArregloNominas([true,true,true,true])
  }
  const ninguno = () =>{
    setArregloNominas([false,false,false,false])
  }



  // const [ arreglo_movimientos, setArregloMovimientos ] = useState<any>([true, true, true, true, true, true, true, true, true, true, true, true, true, true])
  const [ arreglo_movimientos, setArregloMovimientos ] = useState<any>([true, true, true, true, false, false, false, false, false, false, true, true, true, false, true, true])

  const [ arreglo_quincenas, setArregloQuincenas ] = useState<any>([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])






  const todosQuincenas = () =>{
    setArregloQuincenas([true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true])
  }
  const ningunoQuincenas = () =>{
    setArregloQuincenas([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false])
  }



  const todosMovimientos = () =>{
    setArregloMovimientos([true, true, true, true, false, false, false, false, false, false, true, true, true, false, true, true])
  }
  const ningunoMovimientos = () =>{
    setArregloMovimientos([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  }

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [todos_ninguno, setTodosNinguno ] = useState<boolean>(true)
  const changeTodosNinguno = () =>{
    let movs = {...reporte}
      for(let movimiento of movs.movimientos) movimiento.checked = todos_ninguno
    setTodosNinguno(!todos_ninguno)
    setReporte(movs)
  }

  const busqueda = () =>{}

  


  const [ cargando_reporte, setGenerandoReporte ] = useState<boolean>(false)


 

  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };




  const [ show_ModalActualizarMemo, setModalActualizarMemo ] = useState<boolean>(false)
  const [ movimientos_seleccionados, setMovimiento ] = useState<any>({})
  const handleShow_ModalActualizarMemo = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalActualizarMemo(true)
  }
  const handleClose_ModalActualizarMemo = () => {
    cargarMiReporte()
    setModalActualizarMemo(false)
  }









  const abrirReporteExcel = async () => {

    try{
        
        setGenerandoReporte(true)

        // console.log(reporte.movimientos)

        let checados = []
        for(let checado of reporte.movimientos) if(checado.checked) checados.push(checado.uuid_movimiento)
        // console.log(checados)

        let res = await RequestService.downloadFile(`nomina/reporte_movimientos/excel/${arreglo_movimientos}/${arreglo_nominas}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { filtrados : checados })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte de Movimientos `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }




  const abrirReportePDF = async () => {

    try{
        
        setGenerandoReporte(true)

        // console.log(reporte.movimientos)
        let movimientos = []
        for(let movimiento of reporte.movimientos) if(movimiento.checked) movimientos.push(movimiento.uuid_movimiento)

        let checados = []
        for(let checado of reporte.movimientos) if(checado.checked) checados.push(checado.uuid_movimiento)
        // console.log(checados)

        let res = await RequestService.downloadFile(`fichas/${arreglo_movimientos}/${arreglo_nominas}/${arreglo_quincenas}/${anio_seleccionado}/busqueda?where=${text_busqueda}`, { movimientos_seleccionados : movimientos, filtrados : checados })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte de Movimientos `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }


  const [ mostrar_filtros, setMostrarFiltros ] = useState<boolean>(true)




  const [ show_ModalEditarMovimientoEmpleado, setModalEditarMovimientoEmpleado ] = useState<boolean>(false)
  const handleShow_ModalEditarMovimientoEmpleado = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalEditarMovimientoEmpleado(true)
  }
  const handleClose_ModalEditarMovimientoEmpleado = () => {
    cargarMiReporte()
    setModalEditarMovimientoEmpleado(false)
  }


  // const [ niveles, setNiveles ] = useState<any>([])
  const [ config, setConfig ] = useState<any>({})

  const cargarConfig = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`config`)
      // console.log(response.data)
      setConfig(response.data)
      let nominas = []
      for(let i = 1; i < 25; i++){
        if((response.data.nomina_contador) === (i)) nominas.push(true)
          else nominas.push(false)
      }
      setArregloQuincenas(nominas)
      // console.log(nominas)
      // console.log(nominas.length)
      // setNiveles(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }

  React.useEffect(() => {
    cargarConfig()
    cargarMiReporte()
  }, []);


const obtenerArregloAnios = () => {
  var anioActual = new Date().getFullYear();
  var arregloAnios = [anioActual];

  for (var i = 1; i < 4; i++) {
    arregloAnios.push(anioActual - i);
  }

  return arregloAnios;
}


  const [ anio_seleccionado, setAnioSeleccionado ] = useState<any>(moment().format('YYYY'))




 const cadenaMovimientos = (movimiento:any) => {



    let mov = ''
    // if([13,4].indexOf(registro_movimiento.idmovimiento.id) > -1){
      let cambios = []
      if(movimiento.cambio_rfc) cambios.push('CORRECCIÓN DE RFC')
      if(movimiento.cambio_curp) cambios.push('CORRECCIÓN DE CURP')
      if(movimiento.cambio_fecha_ingreso) cambios.push('CORRECCIÓN DE FECHA DE ALTA')

      if(movimiento.cambio_adscripcion) cambios.push('CAMBIO DE ADSCRIPCIÓN')
      if(movimiento.cambio_puesto) cambios.push('CAMBIO DE PUESTO')
      if(movimiento.cambio_plaza) cambios.push('CAMBIO DE PLAZA')
      if(movimiento.cambio_sueldo) cambios.push('MODIFICACIÓN DE SUELDO')
      if(movimiento.cambio_alta_imss) cambios.push('ASEGURAMIENTO')

        mov = cambios.join(', ')
    // }

    return mov 

 }








const [ envio_server, setSeEnvio ] = useState<boolean>(false)



const eliminarMovimiento = async (event:any,uuid_movimiento:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `movimiento/eliminar/${uuid_movimiento}`)

        if(res_){
          toast.warning('El movimiento fue eliminado',{ icon : '⚠️' })
          setTimeout(()=>{
            cargarMiReporte()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con el servidor")
          }
          setSeEnvio(false)

        }
        
      }
     
}








  const exportToExcel = (jsonData:any) => {

    let headerStyle = {
      fill: {
        fgColor: { rgb: '000000' }, // Color de fondo gris claro
      },
      font: {
        color: { rgb: '000000' }, // Color de texto negro
        bold: true,
      },
    };
    // const { jsonData } = this.state;
    let data = jsonData
    let data_excel = []
    for(let registro of data){

      let r = {
        memorandum : registro.memorandum,
        numero_empleado : registro.numero_empleado,
        clave_departamento : registro.clave_departamento,
        departamento : registro.departamento,
        direccion : registro.direccion,
        clave_puesto : registro.puesto_.idnivel_jerarquico,
        puesto : registro.puesto,
        area : registro.area,
        curp : registro.curp,
        rfc : registro.rfc,
        nombres : registro.nombres,
        paterno : registro.paterno,
        materno : registro.materno,
        sexo : registro.sexo,

        email : registro.email,
        celular : registro.celular,
        telefono : registro.telefono,
        domicilio : registro.domicilio,

        movimiento : registro.movimiento,
        fecha_ingreso : moment(registro.fecha_ingreso).format('DD/MMM/YYYY').toUpperCase().replace('.',''),
        fecha_movimiento : moment(registro.fecha_movimiento).format('DD/MMM/YYYY').toUpperCase().replace('.',''),
        anio_movimiento : registro.anio_movimiento,
        quincena_movimiento : registro.quincena_movimiento,
        asegurado : registro.asegurado ? 'SI' : 'NO',
        nomina : registro.nomina,
        subnomina : registro.subnomina,
        observaciones : registro.observaciones,
        extraordinaria : registro.extraordinaria,
        p001 : registro.p001,
        p002 : registro.p002,
        p003 : registro.p003,
        p025 : registro.p025,
        sueldo : registro.sueldo,
        alta_imss : registro.alta_imss ? 'SI' : 'NO',
        cambio_adscripcion : registro.cambio_adscripcion ? 'SI' : 'NO' ,
        cambio_puesto : registro.cambio_puesto ? 'SI' : 'NO' ,
        cambio_plaza : registro.cambio_plaza ? 'SI' : 'NO' ,
        cambio_sueldo : registro.cambio_sueldo ? 'SI' : 'NO' ,

        cambio_alta_imss : registro.cambio_alta_imss ? 'SI' : 'NO' ,
        cambio_rfc : registro.cambio_rfc ? 'SI' : 'NO' ,
        cambio_curp : registro.cambio_curp ? 'SI' : 'NO' ,
        cambio_fecha_ingreso : registro.cambio_fecha_ingreso ? 'SI' : 'NO' ,

        fecha_creacion : moment(registro.fecha_creacion).format('DD/MMM/YYYY').toUpperCase().replace('.',''), 

      }
      data_excel.push(r)
    }

    const ws = XLSX.utils.json_to_sheet(data_excel);

    let columnOrder = [
                      'memorandum',
                      'numero_empleado',
                      'clave_departamento',
                      'departamento',
                      'direccion',
                      'clave_puesto',
                      'puesto',
                      'area',
                      'curp',
                      'rfc',
                      'nombres',
                      'paterno',
                      'materno',
                      'sexo',

                      'email',
                      'telefono',
                      'celular',
                      'domicilio',

                      'movimiento',
                      'fecha_ingreso',
                      'fecha_movimiento',
                      'anio_movimiento',
                      'quincena_movimiento',
                      'asegurado',
                      'nomina',
                      'subnomina',
                      'observaciones',
                      'extraordinaria',
                      'p001',
                      'p002',
                      'p003',
                      'p025',
                      'sueldo',
                      'alta_imss',
                      'cambio_adscripcion',
                      'cambio_puesto',
                      'cambio_plaza',
                      'cambio_sueldo',
                      'cambio_alta_imss',
                      'cambio_rfc',
                      'cambio_curp',
                      'cambio_fecha_ingreso',
                      'fecha_creacion'
                      ]

    XLSX.utils.sheet_add_json(ws, data_excel, { header: columnOrder , skipHeader: false });
    ws['!cols'] = [{ width: 12 }, //memorandum
                  { width: 12 }, //numero_empleado
                  { width: 12 }, //clave_departamento
                  { width: 45 }, //departamento
                  { width: 50 }, //direccion
                  { width: 18 }, //clave_puesto
                  { width: 50 }, //puesto
                  { width: 90 }, //area
                  { width: 50 }, //curp
                  { width: 40 }, //rfc
                  { width: 30 }, //nombres
                  { width: 40 }, //paterno
                  { width: 40 }, //materno
                  { width: 18 }, //sexo

                  { width: 50 }, //correo
                  { width: 50 }, //telefono
                  { width: 50 }, //celular
                  { width: 80 }, //domicilio

                  { width: 30 }, //movimiento
                  { width: 40 }, //fecha_movimiento
                  { width: 40 }, //fecha_movimiento
                  { width: 12 }, //anio_movimiento
                  { width: 12 }, //quincena_movimiento
                  { width: 12 }, //asegurado
                  { width: 24 }, //nomina
                  { width: 25 }, //subnomina
                  { width: 80 }, //observaciones
                  { width: 12 }, //extraordinaria
                  { width: 12 }, //p001
                  { width: 16 }, //p002
                  { width: 16 }, //p003
                  { width: 16 }, //p025
                  { width: 16 }, //sueldo
                  { width: 16 }, //alta_imss
                  { width: 12 }, //cambio_adscripcion
                  { width: 12 }, //cambio_puesto
                  { width: 12 }, //cambio_plaza
                  { width: 12 }, //cambio_sueldo

                  { width: 12 }, //cambio_alta_imss
                  { width: 12 }, //cambio_rfc
                  { width: 12 }, //cambio_curp
                  { width: 12 }, //cambio_fecha_ingreso

                  { width: 20 }] //fecha_creacion

    ws['!cellStyles'] = headerStyle;


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Movimiento de Nómina');

    // Save the file
    XLSX.writeFile(wb, `reporte_movimientos_nomina_${moment().format('YYYY_MM_DD_HH_mm')}.xlsx`);
  };






  const [ show_ModalEdicionBajas, setModalEdicionBajas ] = useState<boolean>(false)
  const handleShow_ModalEdicionBajas = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalEdicionBajas(true)
  }
  const handleClose_ModalEdicionBajas = () => {
    cargarMiReporte()
    setModalEdicionBajas(false)
  }



  const [ show_ModalEditarReingreso, setModalEditarReingreso ] = useState<boolean>(false)
  const handleShow_ModalEditarReingreso = (movimiento:any) => {
    setMovimiento(movimiento)
    setModalEditarReingreso(true)
  }
  const handleClose_ModalEditarReingreso = () => {
    cargarMiReporte()
    setModalEditarReingreso(false)
  }

  return (
    <>




            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4></h4>
                    <hr/>

                      <div className="row">
                        <div className="col-8">
                          
                            <h3>Movimientos</h3>
                            <p>Registro de Móvimientos de Personal</p>

                        </div>
                        <div className="col-2  text-right">

                            <h4>QUINCENA</h4>
                            <p>Q{config.nomina_contador}</p>

                        </div>
                        <div className="col-2 text-right">

                            <h4>AÑO</h4>
                            <p>{config.year}</p>                            

                        </div>
                      </div>





                     <div className="mb-3">

                                <label htmlFor="exampleFormControlInput1" className="form-label">Búsqueda</label>
                                <div className="hstack gap-3 mb-3">
                                  <input className="form-control me-auto" type="text" onKeyDown={(e)=>{if(e.keyCode === 13){ cargarMiReporte() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button type="button" onClick={cargarMiReporte} className="btn btn-secondary"><i className="fa fa-search"/></button>
                                  <div className="vr"></div>
                                  <button type="button" onClick={()=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-times"/></button>
                                  <button type="button" onClick={()=>{setMostrarFiltros(!mostrar_filtros)}} className="btn btn-outline-primary"><i className="fa fa-filter"/></button>
                                  <button type="button" onClick={()=>{exportToExcel(reporte.movimientos)}} className="btn btn-outline-secondary"><i className="fa fa-file-excel-o"/></button>
                                  <button type="button" onClick={abrirReportePDF} className="btn btn-outline-primary">CÉDULAS</button>
                                </div>


                     </div>


                     {
                       mostrar_filtros && (

                           <>

                              <div className="row">
                                  <div className="col-sm-2">

                                      <label className="form-label">Plaza</label>

                                        <Form.Check inline
                                          name="group1"
                                          type="checkbox"
                                          label="BASE"
                                          checked={arreglo_nominas[0]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[0] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__1`}
                                        />

                                        <Form.Check inline
                                          name="group2"
                                          type="checkbox"
                                          label="CONFIANZA"
                                          checked={arreglo_nominas[1]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[1] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__2`}
                                        />

                                        <Form.Check inline
                                          name="group3"
                                          type="checkbox"
                                          label="SUPERNUMERARIO"
                                          checked={arreglo_nominas[2]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[2] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__3`}
                                        />

                                        <Form.Check inline
                                          name="group4"
                                          type="checkbox"
                                          label="OTROS"
                                          checked={arreglo_nominas[3]}
                                          onChange={(e)=>{ var ne = [...arreglo_nominas]; ne[3] = e.target.checked;  setArregloNominas(ne);}}
                                          id={`opciones__4`}
                                        />


                                      <ul className="list-inline">
                                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                      </ul>




                                  </div>
                                  <div className="col-sm-1">

                                      <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Año</label>
                                        {
                                          obtenerArregloAnios().map( (year:any,indice_year:number) => <React.Fragment key={indice_year}>
                                                       <div className="form-check form-check-inline">
                                                          <input className="form-check-input" type="radio" id={`inlineCheckbox1_${year}`} checked={anio_seleccionado==year} onChange={(e:any)=>{setAnioSeleccionado(year)}} />
                                                          <label className="form-check-label" htmlFor={`inlineCheckbox1_${year}`}>{year}</label>
                                                        </div>
                                          </React.Fragment> )
                                        }
                                      </div>

                                  </div>
                                  <div className="col-sm-5">




                                        <label className="form-label">Quincenas</label>

                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q1" checked={arreglo_quincenas[0]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[0] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q1`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q2" checked={arreglo_quincenas[1]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[1] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q2`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q3" checked={arreglo_quincenas[2]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[2] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q3`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q4" checked={arreglo_quincenas[3]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[3] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q4`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q5" checked={arreglo_quincenas[4]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[4] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q5`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q6" checked={arreglo_quincenas[5]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[5] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q6`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q7" checked={arreglo_quincenas[6]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[6] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q7`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q8" checked={arreglo_quincenas[7]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[7] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q8`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q9" checked={arreglo_quincenas[8]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[8] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q9`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q10" checked={arreglo_quincenas[9]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[9] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q10`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q11" checked={arreglo_quincenas[10]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[10] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q11`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q12" checked={arreglo_quincenas[11]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[11] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q12`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q13" checked={arreglo_quincenas[12]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[12] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q13`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q14" checked={arreglo_quincenas[13]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[13] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q14`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q15" checked={arreglo_quincenas[14]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[14] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q15`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q16" checked={arreglo_quincenas[15]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[15] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q16`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q17" checked={arreglo_quincenas[16]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[16] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q17`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q18" checked={arreglo_quincenas[17]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[17] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q18`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q19" checked={arreglo_quincenas[18]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[18] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q19`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q20" checked={arreglo_quincenas[19]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[19] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q20`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q21" checked={arreglo_quincenas[20]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[20] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q21`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q22" checked={arreglo_quincenas[21]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[21] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q22`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q23" checked={arreglo_quincenas[22]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[22] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q23`} />
                                        <Form.Check name="quincenas_checks" inline type="checkbox" label="Q24" checked={arreglo_quincenas[23]} onChange={(e)=>{ var ne = [...arreglo_quincenas]; ne[23] = e.target.checked;  setArregloQuincenas(ne);}} id={`quincenas_checks_Q24`} />


                                        <ul className="list-inline">
                                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todosQuincenas()}}>Todos</small> / <small className="pointer" onClick={()=>{ningunoQuincenas()}}>Ninguno</small></li>
                                        </ul>







                                  </div>
                                  <div className="col-sm-4">


                                      <label className="form-label">Movimiento</label>

                                        <Form.Check inline name="group1" type="checkbox" label="ALTA" checked={arreglo_movimientos[0]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[0] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov__1`} />
                                        <Form.Check inline name="group1" type="checkbox" label="BAJA" checked={arreglo_movimientos[1]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[1] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_2`} />
                                        <Form.Check inline name="group1" type="checkbox" label="REINGRESO" checked={arreglo_movimientos[2]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[2] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_3`} />
                                        <Form.Check inline name="group1" type="checkbox" label="MOVIMIENTOS" checked={arreglo_movimientos[3]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[3] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_4`} />
                                        <Form.Check inline name="group1" type="checkbox" label="BAJA AL CARGO" checked={arreglo_movimientos[10]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[10] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_11`} />
                                        <Form.Check inline name="group1" type="checkbox" label="ALTA IMSS" checked={arreglo_movimientos[11]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[11] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_12`} />
                                        <Form.Check inline name="group1" type="checkbox" label="CORRECCIÓN DE DATOS" checked={arreglo_movimientos[12]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[12] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_13`} />
                                        <Form.Check inline name="group1" type="checkbox" label="BAJA DEL IMSS" checked={arreglo_movimientos[14]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[14] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_14`} />
                                        <Form.Check inline name="group1" type="checkbox" label="BAJA DEL ISSSTECAM" checked={arreglo_movimientos[15]} onChange={(e)=>{ var ne = [...arreglo_movimientos]; ne[15] = e.target.checked;  setArregloMovimientos(ne);}} id={`opciones_mov_15`} />

                                        <ul className="list-inline">
                                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todosMovimientos()}}>Todos</small> / <small className="pointer" onClick={()=>{ningunoMovimientos()}}>Ninguno</small></li>
                                        </ul>

                                    </div>
                                </div>


                           </>

                         )
                     }

                    
                    









                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                                {


                                  !cargando_ && (

                                      <>

                                        {
                                            // JSON.stringify(reporte)

                                          reporte.movimientos.length > 0 && (

                                              <>

                                                  <h4 className="nm-">
                                                    {
                                                      // moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                                    }
                                                    {
                                                      // moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                                    }
                                                    Reporte de Movimientos de Nómina
                                                  </h4>

                                                  {
                                                    // <a className="btn btn-link" onClick={abrirReporteExcel}>Reporte Excel</a>
                                                  }




                                                  <Table  style={{fontSize:12}}>
                                                    <thead>
                                                      <tr>
                                                        <th scope="col"><i onClick={changeTodosNinguno} className="fa pointer fa-check-square"/></th>
                                                        <th scope="col"></th>
                                                        <th scope="col">Memorandum</th>
                                                        <th scope="col">Núm Empleado</th>
                                                        <th scope="col">Fecha Ingreso</th>
                                                        <th scope="col">Nómina</th>
                                                        <th scope="col">Empleado</th>
                                                        <th scope="col"></th>
                                                        <th scope="col">EO</th>
                                                        <th scope="col">Movimiento</th>
                                                        <th scope="col">Plaza</th>
                                                        <th scope="col">Puesto</th>
                                                        <th scope="col">Sueldo</th>
                                                        <th scope="col">Adscripción</th>
                                                        <th scope="col">Fecha Captura</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        reporte.movimientos.map( (movimiento:any,indice:number) =>
                                                              <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                                <td className="v" style={{width:10}}>
                                                                  <input className="form-check-input" type="checkbox" checked={movimiento.checked} onChange={(e:any)=>{ let cr = { ...reporte }; cr.movimientos[indice].checked = e.target.checked; setReporte(cr); }} />
                                                                </td>

                                                                <td className="v" style={{width:10}}>
                                                                  <a target="_blank" href={`${SERVER_}ficha/${movimiento.uuid_movimiento}`}><i className="fa fa-file-pdf-o"/></a>
                                                                </td>

                                                                <th className="v pointer" onClick={(e:any)=>{handleShow_ModalActualizarMemo(movimiento)}} scope="row" style={{width:100}}>
                                                                    <p className="nm text-center"><small>{movimiento.memorandum}</small></p>
                                                                   
                                                                </th>
                                                                <th className="v " scope="row" style={{width:60}}>
                                                                    <p className="nm text-center"><small>{movimiento.numero_empleado}</small></p>
                                                                   
                                                                </th>
                                                                <th className="v" scope="row" style={{width:100}}>
                                                                  {
                                                                    ["ALTA",""].indexOf(movimiento.movimiento) > -1 && (
                                                                        <>
                                                                          <p className="nm"><small>{moment(movimiento.fecha_movimiento).format('YYYY-MM-DD')}</small></p>
                                                                          <small>{moment(movimiento.fecha_movimiento, "YYYYMMDD").fromNow()}</small>
                                                                        </>
                                                                      )
                                                                  }
                                                                </th>
                                                                <td className="v" style={{width:60}}>
                                                                  <p className="nm">Q{movimiento.quincena_movimiento}</p>
                                                                  <small>{movimiento.anio_movimiento}</small>
                                                                </td>
                                                                <td className="v">
                                                                  <p className="nm">
                                                                  {
                                                                    `${movimiento.nombres} ${movimiento.paterno} ${movimiento.materno||''} `
                                                                  }
                                                                  </p>
                                                                  <small>{movimiento.curp}</small>
                                                                </td>
                                                                <td className="v" style={{width:20}}>
                                                                  {
                                                                    movimiento.asegurado && (<i className="fa fa-star" />)
                                                                  }
                                                                </td>
                                                                <td className="v" style={{width:20}}>
                                                                  {
                                                                    movimiento.extraordinaria && (<i className="fa fa-check" />)
                                                                  }
                                                                </td>
                                                                <td className="v" style={{width:140}}>
                                                                  <p className="nm">{movimiento.movimiento}</p>

                                                                  {
                                                                    // JSON.stringify(movimiento.idmovimiento_nomina)
                                                                  }
                                                                  {
                                                                    [4].indexOf(movimiento.idmovimiento_nomina) > -1 && (

                                                                          <small>
                                                                          {
                                                                            cadenaMovimientos(movimiento)
                                                                          }
                                                                          </small>

                                                                      )
                                                                  }
                                                                </td>
                                                                <td className="v" style={{width:140}}>
                                                                  <p className="nm">
                                                                    <b>{ movimiento.nomina }</b>
                                                                  </p>
                                                                  <small>{movimiento.subnomina}</small>
                                                                </td>
                                                                <td className="v" style={{width:200}}>
                                                                  <p className="nm">
                                                                    <small> { /*movimiento.clave_puesto.padStart(2,'0')*/ } </small> <b>{ movimiento.puesto }</b>
                                                                  </p>
                                                                  
                                                                </td>
                                                                <td className="v" style={{width:80}}>
                                                                  <p className="nm">
                                                                    <b>${ numberWithCommas(movimiento.sueldo) }</b>
                                                                  </p>
                                                                </td>
                                                                <td className="v" style={{width:250}}>
                                                                  <p className="nm">
                                                                    <small>{ movimiento.departamento }</small>
                                                                  </p>
                                                                  <small><b>{movimiento.direccion}</b></small>
                                                                </td>
                                                                <td className="v" style={{width:100}}>
                                                                  <p className="nm">{moment(movimiento.fecha_creacion).format('YYYY-MM-DD')}</p>
                                                                  <small>{moment(movimiento.fecha_creacion, "YYYYMMDD").fromNow()}</small>
                                                                </td>



                                                                <td className="v text-left" style={{"width":"20px"}}>

                                                                {
                                                                  // JSON.stringify(movimiento.idmovimiento_nomina)
                                                                }
                                                                
                                                                {
                                                                  [1,3].indexOf(movimiento.idmovimiento_nomina) > -1 && (
                                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalEditarReingreso(movimiento)}} className="btn btn-sm btn-link"><i className="fa fa-edit"/></button>
                                                                    )
                                                                }
                                                                {
                                                                  [4].indexOf(movimiento.idmovimiento_nomina) > -1 && (
                                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalEditarMovimientoEmpleado(movimiento)}} className="btn btn-sm btn-link"><i className="fa fa-edit"/></button>
                                                                    )
                                                                }
                                                                      
                                                                {
                                                                  [2,11,15,16].indexOf(movimiento.idmovimiento_nomina) > -1 && (
                                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalEdicionBajas(movimiento)}} className="btn btn-sm btn-link"><i className="fa fa-edit"/></button>
                                                                    )
                                                                }
                                                                      
 
                                                                      







                                                                </td>
                                                                <td className="v text-left" style={{"width":"20px"}}>


                                                                  {
                                                                    movimiento.es_ultimo_movimiento && (
                                                                          <button type="button" onClick={(e:any)=>{eliminarMovimiento(e,movimiento.uuid_movimiento)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                                      )
                                                                  }

                                                                      
                                                                  {
                                                                    // JSON.stringify(user.rol)
                                                                  }





                                                                </td>

                                                              </tr>
                                                         )
                                                      }

                                                    </tbody>
                                                  </Table>


                                                  









                                              </>

                                            )


                                        }


                                      </>
                                    )



                                }






                </div>
              </div>

            </div>



  {
    show_ModalActualizarMemo && (
      <ModalActualizarMemo show={show_ModalActualizarMemo} handleClose={handleClose_ModalActualizarMemo} movimiento={movimientos_seleccionados} />
      )

  }


  {
    show_ModalEditarMovimientoEmpleado && (
      <ModalEditarMovimientoEmpleado show={show_ModalEditarMovimientoEmpleado} handleClose={handleClose_ModalEditarMovimientoEmpleado} movimiento={movimientos_seleccionados}  />
      )

  }





  {
    show_ModalEdicionBajas && (
      <ModalEdicionBajas show={show_ModalEdicionBajas} handleClose={handleClose_ModalEdicionBajas}  movimiento={movimientos_seleccionados} />
      )

  }




  {
    show_ModalEditarReingreso && (
      <ModalEditarReingreso show={show_ModalEditarReingreso} handleClose={handleClose_ModalEditarReingreso}  movimiento={movimientos_seleccionados}  />
      )

  }





    </>
  );
}

export default Movimientos
