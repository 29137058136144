import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'



import { Form, Col, Row, Button, Modal } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'


const mensaes_validacion_generico = 'Este campo es obligatorio'

const ModalSetConfig = ( params : any ) => {


  const dialog = new Dialog();

  const { show, handleClose, config } = params

const schema = yup.object().shape({
jefe_departamento: yup.string().required(mensaes_validacion_generico),
coordinador_planeacion: yup.string().required(mensaes_validacion_generico),
director_rh: yup.string().required(mensaes_validacion_generico),
director_general: yup.string().required(mensaes_validacion_generico),
coordinador_horas_faltas: yup.string().required(mensaes_validacion_generico),
director_rh_firma_en_ausencia: yup.boolean() ,
});


  const actualizarConfig = async (e:any, setFieldValue:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.username !=='' && respuestas.password !==''){
          let res = await RequestService.update('configuracion', setFieldValue )
          toast.warning('La información se actualizó correctamente')
          handleClose()
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>CAMBIAR CONFIGURACIÓN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{actualizarConfig(e,values)}}
      initialValues={{

      jefe_departamento : config.jefe_departamento, 
      coordinador_planeacion : config.coordinador_planeacion, 
      director_rh : config.director_rh, 
      director_general : config.director_general, 
      coordinador_horas_faltas : config.coordinador_horas_faltas, 
      director_rh_firma_en_ausencia : config.director_rh_firma_en_ausencia,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values.director_rh_firma_en_ausencia)
        }

            <Form.Group className="mb-3" controlId="director_general">
              <Form.Label>Director General</Form.Label>
              <Form.Control
                type="text"
                required
                name="director_general"
                value={values.director_general}
                onChange={(e)=>{ setFieldValue('director_general', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.director_general}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="director_rh">
              <Form.Label>Director de Recursos Humanos</Form.Label>
              <Form.Control
                type="text"
                required
                name="director_rh"
                value={values.director_rh}
                onChange={(e)=>{ setFieldValue('director_rh', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.director_rh}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

                       <div className="form-check mb-2">
                        <input className="form-check-input" type="checkbox" checked={values.director_rh_firma_en_ausencia} onChange={(e)=>{setFieldValue( 'director_rh_firma_en_ausencia', e.target.checked )}} id="director_rh_firma_en_ausencia" />
                        <label className="form-check-label" htmlFor="director_rh_firma_en_ausencia">
                          Usar Firma Electrónica en ausencia
                        </label>
                      </div>



            <Form.Group className="mb-3" controlId="coordinador_planeacion">
              <Form.Label>Coordinación de Planeación y Gestión de Personal</Form.Label>
              <Form.Control
                type="text"
                required
                name="coordinador_planeacion"
                value={values.coordinador_planeacion}
                onChange={(e)=>{ setFieldValue('coordinador_planeacion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.coordinador_planeacion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




            <Form.Group className="mb-3" controlId="jefe_departamento">
              <Form.Label>Jefe de Departamento de Control de Personal</Form.Label>
              <Form.Control
                type="text"
                required
                name="jefe_departamento"
                value={values.jefe_departamento}
                onChange={(e)=>{ setFieldValue('jefe_departamento', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.jefe_departamento}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="coordinador_horas_faltas">
              <Form.Label>Jefe Departamento de Enlace Administrativo</Form.Label>
              <Form.Control
                type="text"
                required
                name="coordinador_horas_faltas"
                value={values.coordinador_horas_faltas}
                onChange={(e)=>{ setFieldValue('coordinador_horas_faltas', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.coordinador_horas_faltas}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



                <Button className="btn-lg" type="submit">ACEPTAR</Button>

        </Form>
      )}
    </Formik>




        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalSetConfig;