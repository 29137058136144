import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import moment from 'moment'
import 'moment/locale/es'


import { SERVER_ } from  '../../config';



const ModalReporteListadoDeIncidencias = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, config } = params

  // const config = {
  //   nomina_contador : 6
  // }


  const [ quincena, setQuincena ] = useState<number>(config.nomina_contador)

const obtenerArregloAnios = () => {
  var anioActual = new Date().getFullYear();
  var arregloAnios = [anioActual];

  for (var i = 1; i < 4; i++) {
    arregloAnios.push(anioActual - i);
  }

  return arregloAnios;
}

  const [ anio_seleccionado, setAnioSeleccionado ] = useState<any>(moment().format('YYYY'))


  const [ cargando_, setCargando ] = useState<boolean>(false)
  const [ reporte, setReporte ] = useState<any>({ movimientos : [], acumulados_movimientos : [], acumulados_incidencias : [] })


  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`nomina/reporte_layout/${anio_seleccionado}/${quincena}`)
      if(!response){
        throw "El servidor no dio respuesta"
      }
      setReporte(response.data)
      setCargando(false)
    }
    catch(e){
      console.log(e)
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }



  React.useEffect(() => {
    cargarMiReporte()
  }, []);


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>REPORTE DE INCIDENCIAS</Modal.Title>
        </Modal.Header>
        <Modal.Body>


                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Año</label>
                  {
                    obtenerArregloAnios().map( (year:any,indice_year:number) => <React.Fragment>
                                 <div key={indice_year} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id={`inlineCheckbox1_${year}`} checked={anio_seleccionado==year} onChange={(e:any)=>{setAnioSeleccionado(year)}} />
                                    <label className="form-check-label" htmlFor={`inlineCheckbox1_${year}`}>{year}</label>
                                  </div>
                    </React.Fragment> )
                  }
                </div>




                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Quincena</label>
                  <select className="form-select" value={quincena} onChange={(e:any)=>{ if(e.target.value > config.nomina_contador) toast.warning('No puedes seleccionar una nómina mayor a la actual') ; else setQuincena(e.target.value); }} aria-label="Default select example">
                    <option  value={0} disabled>Debes seleccionar una quincena</option>
                    <option disabled={ (config.nomina_contador) < 1  } value={1}>Q1</option>
                    <option disabled={ (config.nomina_contador) < 2  } value={2}>Q2</option>
                    <option disabled={ (config.nomina_contador) < 3  } value={3}>Q3</option>
                    <option disabled={ (config.nomina_contador) < 4  } value={4}>Q4</option>
                    <option disabled={ (config.nomina_contador) < 5  } value={5}>Q5</option>
                    <option disabled={ (config.nomina_contador) < 6  } value={6}>Q6</option>
                    <option disabled={ (config.nomina_contador) < 7  } value={7}>Q7</option>
                    <option disabled={ (config.nomina_contador) < 8  } value={8}>Q8</option>
                    <option disabled={ (config.nomina_contador) < 9  } value={9}>Q9</option>
                    <option disabled={ (config.nomina_contador) < 10  } value={10}>Q10</option>
                    <option disabled={ (config.nomina_contador) < 11  } value={11}>Q11</option>
                    <option disabled={ (config.nomina_contador) < 12  } value={12}>Q12</option>
                    <option disabled={ (config.nomina_contador) < 13  } value={13}>Q13</option>
                    <option disabled={ (config.nomina_contador) < 14  } value={14}>Q14</option>
                    <option disabled={ (config.nomina_contador) < 15  } value={15}>Q15</option>
                    <option disabled={ (config.nomina_contador) < 16  } value={16}>Q16</option>
                    <option disabled={ (config.nomina_contador) < 17  } value={17}>Q17</option>
                    <option disabled={ (config.nomina_contador) < 18  } value={18}>Q18</option>
                    <option disabled={ (config.nomina_contador) < 19  } value={19}>Q19</option>
                    <option disabled={ (config.nomina_contador) < 20  } value={20}>Q20</option>
                    <option disabled={ (config.nomina_contador) < 21  } value={21}>Q21</option>
                    <option disabled={ (config.nomina_contador) < 22  } value={22}>Q22</option>
                    <option disabled={ (config.nomina_contador) < 23  } value={23}>Q23</option>
                    <option disabled={ (config.nomina_contador) < 24  } value={24}>Q24</option>
                  </select>
                </div>



                <small>Layout</small>
              <ul className="list-inline">
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/licencias_goce/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Licencias Con Goce</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/licencias_sin_goce/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Licencias Sin Goce</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/vacaciones/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Vacaciones</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/devolutivos/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Días Devolutivos</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/economicos/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Días Económicos</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_movimientos_prestaciones_sociales/cumpleanos/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Días de Cumpleaños</a></li>
                <li className="list-inline-item-"><a target="_blank" href={`${SERVER_}relacion_descuentos_incidencias/${anio_seleccionado}/${quincena}`} className="btn btn-link">Relación de Faltas, Retardos y Descuentos</a></li> 
              </ul>





        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteListadoDeIncidencias;