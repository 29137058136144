import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Table } from 'react-bootstrap';

import { toast } from 'react-toastify'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'





const ModalReporteFaltasRetardos = ( params : any ) => {

  const { show, handleClose } = params

  const [ cargando_, setCargando ] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());

  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };

  const [ reporte, setReporte ] = useState<any>([])

  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`reportes/incidencias/cumpleanos/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
      setReporte(response.data)
      setStartDate_(startDate)
      setEndDate_(endDate)

      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }



  const eliminarMovimiento = async(uuid_movimiento:string)=>{
    try{
      setCargando(true)
      let response : any = await RequestService.delete(`movimiento/eliminar/${uuid_movimiento}`)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar eliminar el movimiento')
    }
  }




  return (
    <>
      <Modal show={show}  className="modal-xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Informe de Cumpleaños</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className="row">
              <div className="col-sm-3">
                


                    <label className="form-label">Fecha del Informe</label>
                    <div className="text-center">
                      <DatePicker onChange={onChangeRango} inline className="form-control" dateFormat="dd/MM/yyyy"  startDate={startDate} endDate={endDate} selectsRange />
                    </div>


                    <div className="d-grid gap-2 d-md-flex justify-content-md-e-nd mt-3">
                        <button className="btn btn-primary" type="button" onClick={cargarMiReporte}><i className="fa fa-search" /> Cargar Informe</button>
                    </div>

              </div>
              <div className="col-sm-9">
                


                    <h4 className="nm-">
                      {
                        moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM').toUpperCase() )
                      }
                      {
                        moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM').toUpperCase())
                      }
                    </h4>


                    {
                      // JSON.stringify(reporte)
                    }


                          {  (!cargando_) && (

                              <>

                                   {
                                     reporte.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                       {
                                        // JSON.stringify(reporte)
                                       }

                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col"># Empleado</th>
                                                <th scope="col">Empleado</th>
                                                <th scope="col"></th>
                                                <th scope="col">Edad</th>
                                                <th scope="col">Cumpleaños</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                reporte.map(((empleado:any,indice_empleado:number)=>
                                                    <tr key={indice_empleado}>
                                                      <td className="v">
                                                        <p className="nm"><small><b>{empleado.numero_empleado}</b></small></p>
                                                      </td>
                                                      <td className="v">
                                                        <p className="nm"><small><b> {empleado.nombres}</b></small></p>
                                                      </td>
                                                      <td className="v">
                                                        <small>{empleado.paterno} {empleado.materno}</small>
                                                      </td>
                                                      <td className="v">
                                                        <p className="nm"><small><b>{moment().diff(empleado.fecha_nacimiento, 'years')}</b></small></p>
                                                      </td>
                                                      <td className="v">
                                                        <p className="nm"><small><b>{moment(empleado.fecha_nacimiento).format('DD/MMM').replace('.','').toUpperCase()}</b></small></p>
                                                      </td>
                                                    </tr>
                                                  ))
                                              }

                                            </tbody>
                                          </table>

                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


              </div>
            </div>



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteFaltasRetardos;