import React, { useState } from "react";
import RequestService from "../../services/request.service";

import {Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'
// import 'moment/locale/es';

import ModalNuevaRecepcion from './ModalNuevaRecepcion'



import ModalDetalleOficio from './ModalDetalleOficio'
import ModalEditarOficio from './ModalEditarOficio'


import { SERVER_ } from  '../../config';

const Recepcion = () => {

  const dialog = new Dialog();


      const [ reporte, setRegistro ] = useState<any>([])


  const [ cargando_, setCargando ] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());





      const cargar = async () =>{
        try{
            setCargando(true)
            let res = await RequestService.getEndPoint(`oficios/${tipo_filtro}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
            setRegistro(res.data)
            setStartDate_(startDate)
            setEndDate_(endDate)
            setCargando(false)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };



  const [ oficio, setOficioSeleccionado ] = useState<any>({ })

  const [ show_ModalNuevaRecepcion, setModalNuevaRecepcion ] = useState<boolean>(false)
  const handleShow_ModalNuevaRecepcion = () => {
    setModalNuevaRecepcion(true)
  }
  const handleClose_ModalNuevaRecepcion = () => {
    setModalNuevaRecepcion(false)
  }

  const [ show_ModalDetalleOficio, setModalDetalleOficio ] = useState<boolean>(false)
  const handleShow_ModalDetalleOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalDetalleOficio(true)
  }
  const handleClose_ModalDetalleOficio = () => {
    setModalDetalleOficio(false)
  }


  const [ show_ModalEditarOficio, setModalEditarOficio ] = useState<boolean>(false)
  const handleShow_ModalEditarOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalEditarOficio(true)
  }
  const handleClose_ModalEditarOficio = () => {
    cargar()
    setModalEditarOficio(false)
  }


 const eliminarOficio = async(uuid_oficio:string)=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.delete('oficio/'+uuid_oficio)
        if(res.data){
          toast.warning('Este oficio fue eliminado de la lista de recepción')        
          cargar()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar los días')
      }
    }
  }

// let addDays = (days) => {
//   let result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result.toISOString().split('T')[0];
// }
  const fecha : any = (new Date()).setDate((new Date()).getDate() + 15 )


  const [ tipo_filtro, setTipoFiltro ] = useState<any>('fecha_recepcion')

      return (
          <>

                  <div className="container-fluid">
              
                      <h3>Recepcion de Oficios</h3>

                      <div className="row">
                        <div className="col-sm-2">



                                <label className="form-label">Fecha del Reporte</label>
                                <div className="text-center mb-2">
                                  <DatePicker onChange={onChangeRango} className="form-control" inline dateFormat="dd/MM/yyyy" maxDate={fecha} startDate={startDate} endDate={endDate} selectsRange />
                                </div>

                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={tipo_filtro==='fecha_recepcion'} onChange={(e:any)=>{setTipoFiltro('fecha_recepcion')}}  />
                                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Por Fecha de Recepción
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={tipo_filtro==='fecha_oficio'} value="fecha_oficio" onChange={(e:any)=>{setTipoFiltro('fecha_oficio')}} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Por Fecha de Oficio
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={tipo_filtro==='fecha_creacion'} value="fecha_creacion" onChange={(e:any)=>{setTipoFiltro('fecha_creacion')}} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                                    Por Fecha de Captura
                                  </label>
                                </div>

                                <Button onClick={cargar} variant="link">Actualizar Lista</Button>



                          <hr className="border border-primary border-1 opacity-75 mb-4"/>

                          <label htmlFor="exampleFormControlInput1" className="form-label">Opciones</label>

                          <ul className="list-group list-group-flush">
                            <li className="list-group-item"><button type="button" onClick={handleShow_ModalNuevaRecepcion} className="btn btn-link" style={{fontSize:12}}>Agregar Oficio</button></li>
                            <li className="list-group-item"><a href={`${SERVER_}oficios/reporte/${tipo_filtro}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`} target="_blank" className="btn btn-link" style={{fontSize:12}}>Descargar Reporte</a></li>
                            <li className="list-group-item"><a href={`${SERVER_}oficios/control_oficios/${tipo_filtro}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`} target="_blank" className="btn btn-link" style={{fontSize:12}}>Descargar Reporte de Control</a></li>
                          </ul>



                        </div>
                        <div className="col-sm-10">
                          


                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                                {


                                  !cargando_ && (

                                      <>

                                                  <h4 className="nm-">
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                                    }
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                                    }
                                                  </h4>



                                        {

                                          
                                            // JSON.stringify(reporte)
                                          

                                        }


                                        {
                                          reporte.length === 0 && (

                                              <p><i className="fa fa-warning" /> No hay oficios capturados para ese rango de fecha</p>

                                          )
                                        }



                                        {
                                          reporte.length > 0 && (

                                            <table className="table">
                                              <thead style={{fontSize:10}}>
                                                <tr>
                                                  <th className="v" scope="col">Folio <br/> Número de Oficio</th>
                                                  <th className="v" scope="col">Remitente</th>
                                                  <th className="v" scope="col">Turnado A</th>
                                                  <th className="v" scope="col">Fecha Recepción <br/> Fecha del Oficio </th>
                                                  <th className="v" scope="col"> </th>
                                                  <th className="v" scope="col"> </th>

                                                </tr>
                                              </thead>
                                              <tbody>
                                              {
                                                reporte.map( (oficio:any)=>
                                                        <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                          <th className="pointer" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}} scope="row" style={{width:150}}>
                                                            <p className="nm"><b>{oficio.folio}</b></p>
                                                            <small>{oficio.numero_oficio}</small>
                                                          </th>
                                                          
                                                          <td className="pointer" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}}>
                                                            <b><small>{oficio.remitente}</small></b>
                                                            <p className="nm"><small>{oficio.asunto}</small></p>
                                                          </td>

                                                          <td className="pointer v" style={{width:450}} onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}}>

                                                            <p className="nm">{oficio.iddepartamento_turnado.departamento}</p>

                                                            {
                                                              // JSON.stringify(oficio.iddepartamento_turnado.departamento)
                                                            }
                                                          </td>
                                                          <td className="pointer v" style={{width:180}} onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}}>
                                                            <p className="nm">{moment(oficio.fecha_recepcion).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</p>
                                                            <p className="nm">{moment(oficio.fecha_oficio).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</p>
                                                          </td>
                                                          <td className="v" style={{width:50}}>
                                                            <Button onClick={()=>{handleShow_ModalEditarOficio(oficio)}} size="sm" variant="link"><i className="fa fa-edit"/></Button>
                                                          </td>
                                                          <td className="v" style={{width:50}}>
                                                            <Button size="sm" onClick={(e:any)=>{eliminarOficio(oficio.uuid)}} variant="danger"><i className="fa fa-trash"/></Button>
                                                          </td>
                                                        </tr>
                                                 )
                                              }

                                              </tbody>
                                            </table>

                                          )
                                        }






                                    </>
                                    )
                                }

                        </div>
                      </div>
              
                  </div>




  {
    show_ModalDetalleOficio && (
      <ModalDetalleOficio show={show_ModalDetalleOficio} handleClose={handleClose_ModalDetalleOficio} oficio={oficio} />
      )

  }

  {
    show_ModalNuevaRecepcion && (
      <ModalNuevaRecepcion show={show_ModalNuevaRecepcion} handleClose={handleClose_ModalNuevaRecepcion}  />
      )

  }


  {
    show_ModalEditarOficio && (
      <ModalEditarOficio show={show_ModalEditarOficio} handleClose={handleClose_ModalEditarOficio} oficio={oficio}  />
      )

  }


          </>

      );
  

}

export default Recepcion
