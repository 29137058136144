import React, { useState, useEffect } from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'

import { PrivateRoute } from './PrivateRoute'

import NotFound from './pages/NotFound'
import Login from './pages/Login'
import Profile from './pages/Profile'

import { ROLE } from './features/auth/role'

import AuthService from './features/auth/auth.service'


import Recursos_humanos from './pages/Recursos_humanos/Recursos_humanos'
import Movimientos from './pages/Recursos_humanos/Movimientos'
import CargarFotosEmpleados from './pages/Recursos_humanos/CargarFotosEmpleados'


import Departamentos from './pages/Departamentos/Departamentos'


import Incidencias from './pages/Incidencias/Incidencias'
import ReporteIncidencias from './pages/Incidencias/ReporteIncidencias'

import ControlFaltasRetardos from './pages/Incidencias/ControlFaltasRetardos'




import Administracion from './pages/Administracion/Administracion'


import Usuarios from './pages/Administracion/Usuarios'


import Recepcion from './pages/Recepcion/Recepcion'
import MisOficios from './pages/Recepcion/MisOficios'

import Contratos from './pages/Contratos/Contratos'


import Pensionados from './pages/Pensionados/Pensionados'


import Puestos from './pages/Puestos/Puestos'


import Archivos from './pages/Archivos/Archivos'

import Control from './pages/Control/Control'

import Capturista from './pages/Oficios/Capturista'
import MisOficiosRecibidos from './pages/Oficios/MisOficiosRecibidos'





import Planeacion from './pages/Planeacion/Planeacion'
import PlaneacionCaptura from './pages/Planeacion/PlaneacionCaptura'
import PlaneacionRevision from './pages/Planeacion/PlaneacionRevision'








import './App.scss';


function BootHeader() {

	const user = AuthService.getCurrentUser()

	const isAuthenticated = AuthService.selectIsAuthenticated
	let location = useLocation();

	let activo_perfil = location.pathname === '/profile' ? 'active' : ''
	// let activo_rh = location.pathname === '/rh' ? 'active' : ''
	// let activo_archivos = location.pathname === '/archivos' ? 'active' : ''
	// let activo_oficios = location.pathname === '/oficios' ? 'active' : ''
	// let activo_recepcion_oficios = location.pathname === '/recepcion_oficios' ? 'active' : ''
	// let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''
	// let activo_control = location.pathname === '/control' ? 'active' : ''
	// let activo_movimientos = location.pathname === '/control' ? 'active' : ''
	// let activo_puestos = location.pathname === '/puestos' ? 'active' : ''

	let activo_capturista = location.pathname === '/capturista' ? 'active' : ''
	let activo_mis_oficios = location.pathname === '/mis_oficios' ? 'active' : ''



	let activo_rh = location.pathname === '/rh' ? 'active' : ''
	let activo_movimientos = location.pathname === '/movimientos' ? 'active' : ''
	let activo_archivos = location.pathname === 'xxxxxxxxxxxxxx' ? 'active' : ''
	let activo_incidencias = location.pathname === '/incidencias' ? 'active' : ''
	let activo_control_faltas_retardos = location.pathname === '/incidencias/control_faltas_retardos' ? 'active' : ''
	let activo_incidencias_reporte = location.pathname === '/incidencias/reporte' ? 'active' : ''
	let activo_departamentos = location.pathname === '/departamentos' ? 'active' : ''
	let activo_puestos = location.pathname === '/puestos' ? 'active' : ''
	let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''
	let activo_oficios = location.pathname === '/oficios' ? 'active' : ''
	let activo_administracion = location.pathname === '/administracion' ? 'active' : ''
	let activo_recepcion_oficios = location.pathname === '/recepcion_oficios' ? 'active' : ''
	let activo_contratos = location.pathname === '/contratos' ? 'active' : ''
	let activo_pensionados = location.pathname === '/pensionados' ? 'active' : ''
	let activo_planeacion = location.pathname === '/planeacion' ? 'active' : ''
	let activo_planeacion_captura = location.pathname === '/planeacion_captura' ? 'active' : ''
	let activo_planeacion_revision = location.pathname === '/planeacion_revision' ? 'active' : ''
	// let activo_capturista = location.pathname === 'xxxxxxxxxxxxxx' ? 'active' : ''
	// let activo_mis_oficios = location.pathname === 'xxxxxxxxxxxxxx' ? 'active' : ''





	const [ ROLE_ADMIN, setAdmin ] = useState(false)


	const [ EJECUTIVO_PRIMARIO, setEjecutivoPrimario ] = useState<boolean>(false)
	const [ MOVIMIENTOS_NOMINA, setMovimientosNomina ] = useState<boolean>(false)
	const [ CONSTANCIAS, setConstancias ] = useState<boolean>(false)
	const [ INCIDENCIAS, setIncidencias ] = useState<boolean>(false)
	const [ EJECUTIVO_SECUNDARIO, setEjecutivoSecundario ] = useState<boolean>(false)
	const [ CONTRATOS, setContratos ] = useState<boolean>(false)
	const [ ARCHIVOS, setArchivos ] = useState<boolean>(false)
	const [ RECEPCION, setRecepcion ] = useState<boolean>(false)
	const [ CAPTURISTA, setCapturista ] = useState<boolean>(false)
	const [ OFICIOS, setOficios ] = useState<boolean>(false)
	const [ NOMINA, setNomina ] = useState<boolean>(false)
	const [ PENSIONADOS, setPensionados ] = useState<boolean>(false)
	const [ PLANEACION, setPlaneacion ] = useState<boolean>(false)
	const [ PLANEACION_REVISION, setPlaneacionRevision ] = useState<boolean>(false)
	const [ PLANEACION_DIRECCIONES, setPlaneacionDirecciones ] = useState<boolean>(false)
	const [ CARGA_FOTOS, setCargaFotos ] = useState<boolean>(false)


	useEffect(() => {

	  if(user.rol === "ROLE_ADMIN") setAdmin(true)



		if(user.rol === "EJECUTIVO_PRIMARIO") setEjecutivoPrimario(true)
		if(user.rol === "MOVIMIENTOS_NOMINA") setMovimientosNomina(true)
		if(user.rol === "CONSTANCIAS") setConstancias(true)
		if(user.rol === "INCIDENCIAS") setIncidencias(true)
		if(user.rol === "EJECUTIVO_SECUNDARIO") setEjecutivoSecundario(true)
		if(user.rol === "CONTRATOS") setContratos(true)
		if(user.rol === "ARCHIVOS") setArchivos(true)
		if(user.rol === "RECEPCION") setRecepcion(true)
		if(user.rol === "CAPTURISTA") setCapturista(true)
		if(user.rol === "OFICIOS") setOficios(true)
		if(user.rol === "NOMINA") setNomina(true)
		if(user.rol === "PENSIONADOS") setPensionados(true)
		if(user.rol === "PLANEACION") setPlaneacion(true)
		if(user.rol === "PLANEACION_REVISION") setPlaneacionRevision(true)
		if(user.rol === "PLANEACION_DIRECCIONES") setPlaneacionDirecciones(true)
		if(user.rol === "CARGA_FOTOS") setCargaFotos(true)



	},[ ROLE_ADMIN, 

		// DEPTO_MOVIMIENTOS, DEPTO_NOMINA, DEPTO_CONTRATOS, DEPTO_RETARDOS, JEFE_DEPTO, RECEPCION, ARCHIVOS ,CONTROL, CAPTURISTA ,OFICIOS , 
			EJECUTIVO_PRIMARIO,
			MOVIMIENTOS_NOMINA,
			CONSTANCIAS,
			INCIDENCIAS,
			EJECUTIVO_SECUNDARIO,
			CONTRATOS,
			ARCHIVOS,
			RECEPCION,
			CAPTURISTA,
			OFICIOS,
			NOMINA,
			PENSIONADOS,
			PLANEACION,
			PLANEACION_REVISION,
			PLANEACION_DIRECCIONES,
			CARGA_FOTOS,

		user]);


  return (
    <React.Fragment>
	    { isAuthenticated && ( 
		      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
		        <div className="container-fluid">
		          <a className="navbar-brand" href="./" style={{fontSize:'20px'}}>
		            SARH
		          </a>

		          <div className="collapse navbar-collapse" id="navbarNav">
		            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

		                { ( 
		                			ROLE_ADMIN || 
		                			EJECUTIVO_PRIMARIO || 
		                			MOVIMIENTOS_NOMINA || 
		                			CONSTANCIAS || 
		                			INCIDENCIAS || 
		                			EJECUTIVO_SECUNDARIO || 
		                			CONTRATOS || 
		                			ARCHIVOS || 
		                			RECEPCION || 
		                			CAPTURISTA || 
		                			OFICIOS  || 
		                			NOMINA || 
		                			PENSIONADOS || 
		                			PLANEACION || 
		                			PLANEACION_REVISION || 
		                			PLANEACION_DIRECCIONES ||
		                			CARGA_FOTOS
		                		) && (
		                  <li className="nav-item">
		                    <Link className={'nav-link ' + activo_perfil } to="/profile"> Perfil </Link>
		                  </li>
		                )}

		                { 
		                // 	( ROLE_ADMIN   ) && (
		                //   <li className="nav-item">
		                //     <Link className={'nav-link ' + activo_usuarios } to="/usuarios"> Usuarios </Link>
		                //   </li>
		                // )
		            }





                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','NOMINA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_rh } to="/rh"> Recursos Humanos </Link>
                              </li>

                        )
                      }

                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','EJECUTIVO_SECUNDARIO','NOMINA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_movimientos } to="/movimientos"> Movimientos </Link>
                              </li>

                        )
                      }





                      {
                        (['ARCHIVOS'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_archivos } to="/archivos"> Archivos </Link>
                              </li>

                        )
                      }

                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_incidencias } to="/incidencias"> Incidencias </Link>
                              </li>


                        )
                      }


                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS','NOMINA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_incidencias_reporte } to="/incidencias/reporte">Reporte de Incidencias </Link>
                              </li>


                        )
                      }

                      {
                        (['ROLE_ADMIN', 'EJECUTIVO_PRIMARIO','INCIDENCIAS','NOMINA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_control_faltas_retardos } to="/incidencias/control_faltas_retardos">Control del Faltas </Link>
                              </li>


                        )
                      }


                      {
                        (['ROLE_ADMIN'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_departamentos } to="/departamentos"> Departamentos </Link>
                              </li>


                        )
                      }



                      {
                        (['ROLE_ADMIN'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_puestos } to="/puestos"> Puestos </Link>
                              </li>


                        )
                      }




                      {
                        (['ROLE_ADMIN'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_usuarios } to="/usuarios"> Usuarios </Link>
                              </li>

                        )
                      }



                      {
                        (['ROLE_ADMIN','EJECUTIVO_PRIMARIO','MOVIMIENTOS_NOMINA','CONSTANCIAS','INCIDENCIAS','EJECUTIVO_SECUNDARIO','CONTRATOS','ARCHIVOS','NOMINA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_oficios } to="/oficios"> Mis Oficios </Link>
                              </li>


                        )
                      }





                      {
                        (['ROLE_ADMIN'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_administracion } to="/administracion"> Administración </Link>
                              </li>


                        )
                      }



                      {
                        (['ROLE_ADMIN','RECEPCION'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_recepcion_oficios } to="/recepcion_oficios"> Recepción </Link>
                              </li>


                        )
                      }



                      {
                        (['ROLE_ADMIN','CONTRATOS'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_contratos } to="/contratos"> Contratos </Link>
                              </li>


                        )
                      }







                      {
                        (['ROLE_ADMIN','PENSIONADOS'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_pensionados } to="/pensionados"> Pensionados </Link>
                              </li>


                        )
                      }











                      {
                        (['CAPTURISTA'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_capturista } to="/capturista"> Captura </Link>
                              </li>


                        )
                      }





                      {
                        (['OFICIOS'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_mis_oficios } to="/mis_oficios"> Mis Oficios </Link>
                              </li>


                        )
                      }








                      {
                        (['PLANEACION'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_planeacion } to="/planeacion"> Planeación </Link>
                              </li>

                        )
                      }


                      {
                        (['PLANEACION_DIRECCIONES'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_planeacion_captura } to="/planeacion_captura"> Captura Planeación </Link>
                              </li>

                        )
                      }


                      {
                        (['PLANEACION_REVISION'].indexOf(user.rol) > -1) && (

                              <li className="nav-item">
                                <Link className={'nav-link ' + activo_planeacion_captura } to="/planeacion_revision"> Revisión </Link>
                              </li>

                        )
                      }








		                { 
		                // 	( ROLE_ADMIN || DEPTO_MOVIMIENTOS  || DEPTO_NOMINA  || DEPTO_RETARDOS  || JEFE_DEPTO || RECEPCION ) && (
		                //   <li className="nav-item">
		                //     <Link className={'nav-link ' + activo_oficios } to="/oficios"> Oficios </Link>
		                //   </li>
		                // )
		                }

		                { 
		                // 	( RECEPCION ) && (
		                //   <li className="nav-item">
		                //     <Link className={'nav-link ' + activo_recepcion_oficios } to="/recepcion_oficios"> Recepción </Link>
		                //   </li>
		                // )
		                }

		            </ul>

		            <span className="navbar-text">
		              Usuario: <Link className="link" to="/profile"> {user?.nombres} </Link>
		            </span>

		          </div>

		        </div>
		      </nav>
	    ) }

    </React.Fragment>
  )

}

function App() {

  return (
    <>
    
      <BootHeader />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />

        <Route path="profile" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.CARGA_FOTOS, ROLE.EJECUTIVO_PRIMARIO, ROLE.MOVIMIENTOS_NOMINA, ROLE.CONSTANCIAS, ROLE.INCIDENCIAS, ROLE.EJECUTIVO_SECUNDARIO, ROLE.CONTRATOS, ROLE.ARCHIVOS, ROLE.RECEPCION, ROLE.CAPTURISTA, ROLE.OFICIOS, ROLE.NOMINA , ROLE.PLANEACION , ROLE.PLANEACION_DIRECCIONES , ROLE.PLANEACION_REVISION  ]} component={Profile} />} />


        <Route path="rh" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.MOVIMIENTOS_NOMINA, ROLE.NOMINA ]} component={Recursos_humanos} />} />
        <Route path="carga_fotos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.CARGA_FOTOS ]} component={CargarFotosEmpleados} />} />
        <Route path="movimientos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.MOVIMIENTOS_NOMINA, ROLE.EJECUTIVO_SECUNDARIO, ROLE.NOMINA ]} component={Movimientos} />} />


        


        <Route path="incidencias" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.INCIDENCIAS ]} component={Incidencias} />} />
        <Route path="incidencias/reporte" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.INCIDENCIAS, ROLE.NOMINA ]} component={ReporteIncidencias} />} />
        <Route path="incidencias/control_faltas_retardos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.INCIDENCIAS, ROLE.NOMINA ]} component={ControlFaltasRetardos} />} />


        <Route path="archivos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ARCHIVOS ]} component={Archivos} />} />


        <Route path="control" element={<PrivateRoute roles={[ROLE.ADMIN ]} component={Control} />} />


        <Route path="contratos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.CONTRATOS ]} component={Contratos} />} />



        <Route path="pensionados" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.PENSIONADOS ]} component={Pensionados} />} />


        <Route path="usuarios" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Usuarios} />} />

        <Route path="puestos" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Puestos} />} />



        <Route path="departamentos" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Departamentos} />} />


        <Route path="recepcion_oficios" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.RECEPCION ]} component={Recepcion} />} />
        <Route path="oficios" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.EJECUTIVO_PRIMARIO, ROLE.MOVIMIENTOS_NOMINA, ROLE.CONSTANCIAS, ROLE.INCIDENCIAS, ROLE.EJECUTIVO_SECUNDARIO, ROLE.CONTRATOS, ROLE.ARCHIVOS, ROLE.RECEPCION, ROLE.CAPTURISTA, ROLE.OFICIOS  ]} component={MisOficios} />} />



        {
        	// ==== ¿
        }

        <Route path="capturista" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.CAPTURISTA]} component={Capturista} />} />
        <Route path="mis_oficios" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.OFICIOS]} component={MisOficiosRecibidos} />} />




        <Route path="planeacion" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.PLANEACION]} component={Planeacion} />} />
        <Route path="planeacion_captura" element={<PrivateRoute roles={[ROLE.PLANEACION_DIRECCIONES]} component={PlaneacionCaptura} />} />
        <Route path="planeacion_revision" element={<PrivateRoute roles={[ROLE.PLANEACION_REVISION]} component={PlaneacionRevision} />} />





        <Route path="administracion" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Administracion} />} />

      </Routes>

    </>
  )
}

export default App
