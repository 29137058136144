import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

const ModalDetalleOficio = ( params : any ) => {

  const { show, handleClose, oficio } = params

  return (
    <>
      <Modal show={show} className="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Oficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(oficio.fecha_respuesta)
          }
          {

            oficio.fecha_respuesta && (
                <>
                  <div className="alert alert-success" role="alert">
                     
                    <p><i className="fa fa-check-circle"/> El oficio se contesto</p>


                    {
                      oficio.folio_respuesta !== '' && (
                          <>
                            <small>Folio de Respuesta</small>
                            <h6>{oficio.folio_respuesta}</h6>
                          </>
                        )
                    }


                    {
                      oficio.comentarios_respuesta !== '' && (
                          <>
                            <small>Fecha de Respuesta</small>
                            <h6>{oficio.comentarios_respuesta}</h6>

                          </>
                        )
                    }

                    <div className="row">
                      <div className="col-sm-6">

                            <small>Fecha de Respuesta</small>
                            <h6>{moment(oficio.fecha_respuesta).format('YYYY-MM-DD')}</h6>
                            
                      </div>
                      <div className="col-sm-6">

                            <small>Tiempo de la Respuesta</small>
                            <h6>{moment(oficio.fecha_respuesta, "YYYYMMDD").fromNow()}</h6>
                            
                      </div>
                    </div>


                  </div>
                </>
              )
          }


          {

            !oficio.fecha_respuesta && (
                <>
                  <div className="alert alert-primary" role="alert">
                    <i className="fa fa-warning"/> Este oficio aún no se ha contestado
                  </div>
                </>
              )
          }

                  <div className="row">
                    <div className="col-sm-6">
                        <small><b>Folio</b></small>
                        <p>{oficio.folio}</p>
                    </div>
                    <div className="col-sm-6">
                        <small><b>Número de Oficio</b></small>
                        <p>{oficio.numero_oficio}</p>
                    </div>
                  </div>


                  <small><b>Remitente</b></small>
                  <p>{oficio.remitente}</p>


                  <small><b>Asunto</b></small>
                  <p>{oficio.asunto}</p>

                  <small><b>Observación</b></small>
                  <p>{oficio.observaciones||'Sin Observación'}</p>


                  <div className="row">
                    <div className="col-sm-6">
                        <small><b>Fecha del Oficio</b></small>
                        <p>{moment(oficio.fecha_oficio).format('YYYY-MM-DD')}</p>
                    </div>
                    <div className="col-sm-6">
                        <small><b>Fecha de Recepción</b></small>
                        <p>{moment(oficio.fecha_recepcion).format('YYYY-MM-DD')}</p>
                    </div>
                  </div>


                  <small><b>Turnado A</b></small>
                  <p>{oficio.iddepartamento_turnado.clave_departamento} {oficio.iddepartamento_turnado.departamento}</p>



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetalleOficio;