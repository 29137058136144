import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import moment from 'moment'
import 'moment/locale/es'




import ModalEditarPuesto from './ModalEditarPuesto'

import ModalAgregarPuesto from './ModalAgregarPuesto'



import { formatearNumeroConComas } from '../../services/funcionesPublicas'


const Puestos = () =>{

        const [allSessionsCount,setCounter] = useState(0);
        const sessionsPerPage = 10
        const [currentPage, setCurrentPage] = useState(1);

        const [ text_busqueda, setTextoBusqueda ] = useState('')

        const [ cargando_, setCargando ] = useState( true )

        const skull = { results: [] }

        const [ resultados, setResultados ] = useState<any>({...skull})

        const cargarMiReporte = async(page:number)=>{
          try{
            setCargando(true)
            let skip = 0
            if(page===0) skip = 0; else skip = (page*10);
            let response : any = await RequestService.getEndPoint(`puestos/lista/busqueda?where=${text_busqueda}&skip=${skip}`)
            setCounter(response.data.totalCount)
            setResultados(response.data.resultados)
            setCargando(false)
          }
          catch(e){
            setCargando(false)
            toast.error('Problemas al intentar cargar tus resultados')
          }
        }


        const busqueda = async () =>{
          cargarMiReporte(0)
          setCurrentPage(1)
        }




      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalAgregarPuesto, setModalAgregarPuesto ] = useState<boolean>(false)
  const handleShow_ModalAgregarPuesto = () => {
    setModalAgregarPuesto(true)
  }
  const handleClose_ModalAgregarPuesto = () => {
    setModalAgregarPuesto(false)
  }



  const [ show_ModalEditarPuesto, setModalEditarPuesto ] = useState<boolean>(false)
  const [ puesto_seleccionado, setPuestoSeleccionado ] = useState<any>({})
  const handleShow_ModalEditarPuesto = (puesto:any) => {
    setPuestoSeleccionado(puesto)
    setModalEditarPuesto(true)
  }
  const handleClose_ModalEditarPuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarPuesto(false)
  }

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Puestos</h4>
                    <hr/>



                        <div className="row">
                        
                            <div className="mb-2">
                                <label className="form-label">Búsqueda</label>
                                <div className="hstack gap-3">
                                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                  <div className="vr"></div>
                                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                                </div>
                            </div>
                            

                              <ul className="list-inline mt-2">
                                <li className="list-inline-item pointer" onClick={handleShow_ModalAgregarPuesto}>Nuevo_usuario</li>
                              </ul>


                        </div>


                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Clave</th>
                                              <th scope="col">Puesto</th>
                                              <th scope="col">Nivel</th>
                                              <th scope="col">Grupo</th>

                                              <th scope="col"><small>Sueldo Báse Mín</small></th>
                                              <th scope="col"><small>Sueldo Báse Máx</small></th>
                                              <th scope="col"><small>Compensación</small></th>
                                              <th scope="col"><small>PSM</small></th>
                                              <th scope="col"><small>Asegurado Mín</small></th>
                                              <th scope="col"><small>Asegurado Máx</small></th>

                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>

                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <p className="nm"><small><b>{cual.clave_nivel_jerarquico}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><b>{cual.puesto}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":350}}>
                                                        <p className="nm"><small> {cual.nivel_jerarquico} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":280}}>
                                                        <p className="nm"><small> {cual.grupo_funcional} </small></p>
                                                    </td>


                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.sueldo_base_min)} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.sueldo_base_max)} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.compensacion_max)} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.psm)} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.total_percepciones_asegurado_min)} </small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":100}}>
                                                        <p className="nm"><small> $ {formatearNumeroConComas(cual.total_percepciones_asegurado_max)} </small></p>
                                                    </td>



                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalEditarPuesto(cual)}} className="btn btn-link"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
                </div>
              </div>

            </div>






  {
    show_ModalEditarPuesto && (
      <ModalEditarPuesto show={show_ModalEditarPuesto} handleClose={handleClose_ModalEditarPuesto} puesto={puesto_seleccionado} />
      )

  }



  {
    show_ModalAgregarPuesto && (
      <ModalAgregarPuesto show={show_ModalAgregarPuesto} handleClose={handleClose_ModalAgregarPuesto}  />
      )

  }


          </>

      );
  

}

export default Puestos
