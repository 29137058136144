import React, { useState } from 'react';

import RequestService from "../../../services/request.service";

import AuthService from '../../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import Dialog from '../../../services/Dialogs'


import { anios_disponibles } from '../../../services/funcionesPublicas'


const ModalEdicionBajas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, movimiento } = params



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          observaciones : yup.string(),
          fecha_movimiento : yup.string().required(mensajes_validacion_generico),


          anio_movimiento: yup.number(),
          quincena_movimiento: yup.number(),

          extraordinaria: yup.bool(),

          otra_quincena: yup.bool(),



  });





  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `movimientos/modificaciones/bajas/${movimiento.uuid_movimiento}` , { ...registro, fecha_movimiento : moment(registro.fecha_movimiento).format('YYYY-MM-DD')  })

          if(res_){
            toast.success('El registro se actualizó correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



const [ validado_por_admin, setValidadoAdmin ] = useState<boolean>(false)

  const cambiarQuincena = async (e:any, setFieldValue:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: 'custom',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Para poder seleccionar alguna quincena diferente a la actual un administrador deberá confirmar el cambio</p>
          <label>Username<input type="text" name="username" value="admin"></label>
          <label>Password<input type="password" name="password" value="admin"></label>`
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.username !=='' && respuestas.password !==''){
          let res = await RequestService.getEndPointPost('verificar/administrador', respuestas )
          // console.log(res.data)
          toast.warning('Ahora puedes seleccionar la quincena y el año. Esto puede afectar al histórico de nóminas generadas.')
          setFieldValue('otra_quincena',true)
          setValidadoAdmin(true)
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar la Información del Movimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


          <h3 className="text-right">{movimiento.movimiento}</h3>


          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{movimiento.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{movimiento.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{movimiento.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{movimiento.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{movimiento.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{movimiento.numero_empleado}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          {
            // JSON.stringify(empleado)
          }

          <h5>Información Laboral</h5>



          <div className="row">
            <div className="col-sm-12">
              <small>Departamento</small>
              <h6>{movimiento.clave_departamento} {movimiento.departamento}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-4">
              <small>Plaza</small>
              <h6 className="nm">{movimiento.nomina}</h6>
              <p className="nm">{}</p>
            </div>
            <div className="col-sm-8">
              <small>Puesto</small>
              <h6>{movimiento.clave_puesto} {movimiento.puesto} </h6>
            </div>
          </div>






          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  observaciones : movimiento.observaciones,
                  fecha_movimiento : new Date(moment(movimiento.fecha_movimiento).toString()),

                  anio_movimiento : movimiento.anio_movimiento,
                  quincena_movimiento : movimiento.quincena_movimiento,

                  otra_quincena: false,

                  extraordinaria: movimiento.extraordinaria,



            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>


              {
                // JSON.stringify(movimiento.fecha_movimiento)
              }
              {
                // JSON.stringify(values)
              }

                    <Row>
                      <Col md="8">

                          <Form.Group className="mb-3" controlId="observaciones">
                            <Form.Label>Observaciones del Movimiento</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              rows={8}
                              name="observaciones"
                              value={values.observaciones}
                              onChange={(e)=>{ setFieldValue('observaciones', (e.target.value || "").toUpperCase() ) }}
                              isInvalid={!!errors.observaciones}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.observaciones}</>} </Form.Control.Feedback>
                          </Form.Group>


           
                          <Form.Group as={Col} md="12"  className="mb-3">
                            <Form.Check
                              name="extraordinaria"
                              label="Nómina Extraordinaria"
                              checked={values.extraordinaria}
                              onChange={handleChange}
                              id="extraordinaria"
                            />
                          </Form.Group> 





                            {
                              !values.otra_quincena && (
                                <p>
                                  <Button variant="link" onClick={(e:any)=>{cambiarQuincena(e,setFieldValue )}} style={{textAlign:'left'}}>¿Este empleado debe ser registrado en una quincena diferente a la actual?</Button>
                                </p>
                              )
                            }


                            {
                              ( values.otra_quincena && validado_por_admin ) && (
                                <>
                                  
                                    <div className="row">
                                      <div className="col-md-6">
                                        {
                                          // JSON.stringify(values.anio_alta)
                                        }

                                            <Form.Group  className="mb-3" controlId="anio_movimiento">
                                              <Form.Label>Año de Alta </Form.Label>
                                                <select className="form-select" value={values.anio_movimiento} required name="anio_movimiento" onChange={(e)=>{ setFieldValue('anio_movimiento', e.target.value) }}>
                                                  <option value={0} disabled>Selecciona una opción</option>
                                                  { anios_disponibles.map( (anio:any) => <option key={ Math.random().toString(36).substr(2, 9) } value={anio}>{anio}</option> ) }
                                                </select>
                                              <Form.Control
                                                type="hidden"
                                                required
                                                name="anio_movimiento"
                                                value={values.anio_movimiento}
                                                isInvalid={!!errors.anio_movimiento}
                                              />
                                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid"> {<>{errors.anio_movimiento}</>} </Form.Control.Feedback>
                                            </Form.Group>


                                      </div>
                                      <div className="col-md-6">

                                            <Form.Group  className="mb-3" controlId="quincena_movimiento">
                                              <Form.Label>Quincena de Alta </Form.Label>
                                                <select className="form-select" value={values.quincena_movimiento} required name="quincena_movimiento" onChange={(e)=>{ setFieldValue('quincena_movimiento', e.target.value) }}>
                                                  <option value={0} disabled>Selecciona una opción</option>
                                                  { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map( (quincena:number) => <option key={ Math.random().toString(36).substr(2, 9) } value={quincena}>{quincena}</option> ) }
                                                </select>
                                              <Form.Control
                                                type="hidden"
                                                required
                                                name="quincena_movimiento"
                                                value={values.quincena_movimiento}
                                                isInvalid={!!errors.quincena_movimiento}
                                              />
                                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                              
                                            </Form.Group>
                                      </div>
                                    </div>


                                </>
                              )
                            }

                      </Col>
                      <Col md="4">
                        <Form.Label>Fecha de Movimiento</Form.Label>
                        <DatePicker selected={values.fecha_movimiento} className="form-control" inline dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
                      </Col>
                    </Row>

 



              </Form>
            )}
          </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Actualizar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEdicionBajas;