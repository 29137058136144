import React, { useState } from "react";
import RequestService from "../../services/request.service";

// import { useNavigate } from "react-router"
// import { Modal, Row, Form, Button, Col, InputGroup } from 'react-bootstrap'
import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'
import { Button, Modal } from 'react-bootstrap'


import { SERVER_ } from  '../../config';

import { numberWithCommas, REG_CURP,REG_RFC,REG_NOMBRES,REG_SS, curp2date } from '../../services/funcionesPublicas'



const ModalAltaCiudadano = ( props: any ) => {

  const dialog = new Dialog();


  const { show, onHide, config } = props


var mensajes_validacion_generico = 'Este campo es obligatorio'


  const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]

// const [startDate, setStartDate] = useState<any>(new Date());

const schema = yup.object().shape({


  numero_empleado: yup.string(),

  curp: yup.string().required(mensajes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  rfc: yup.string().required(mensajes_validacion_generico).matches( REG_RFC , "Formato de RFC invalido").required('El RFC es un campo obligatorio'),
  email: yup.string().email('Formato de Email no valido'),
  celular: yup.string().matches(/^\d{10}$/,"Formato no valido"),

  nombres: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensajes_validacion_generico),
  paterno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensajes_validacion_generico),
  materno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido"),


  uuid_nomina: yup.string().required(mensajes_validacion_generico),
  uuid_puesto: yup.string().required(mensajes_validacion_generico),
  uuid_departamento: yup.string().required(mensajes_validacion_generico),

  p001: yup.number().required(mensajes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= puesto_seleccionado.sueldo_base_min && value <= puesto_seleccionado.sueldo_base_max );
              }),
  p002: yup.number().required(mensajes_validacion_generico),
  p003: yup.number().required(mensajes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= 0 && value <= puesto_seleccionado.compensacion_max );
              }),
  p025: yup.number().required(mensajes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= 0 && value <= puesto_seleccionado.psm );
              }),
  sueldo: yup.number().required(mensajes_validacion_generico)
          .test('is-full-name', 'El Sueldo debe ser mayor a 0 pero no puede pasarse del límite', function (value:number=0) {
                // const nameArr = value.split(" ");
                return value <= puesto_seleccionado.total_percepciones_asegurado_max
              }),
  // aguinaldo: yup.number().required(mensajes_validacion_generico),
  // prima: yup.number().required(mensajes_validacion_generico),
  // pbr: yup.number().required(mensajes_validacion_generico),

  estado_civil: yup.string().required(mensajes_validacion_generico),
  seguro_social: yup.string().matches( REG_SS ,'El formato de Número de Seguro Social no es correcto'),



  observaciones: yup.string(),


  direccion: yup.string() ,
  num_int: yup.string() ,
  num_ext: yup.string() ,
  colonia: yup.string() ,
  cp: yup.string().required(mensajes_validacion_generico),
  cp_fiscal: yup.string().required(mensajes_validacion_generico),
  idregimen: yup.number(),

  fecha_ingreso: yup.string().required(),
  fecha_movimiento: yup.string().required(),

  alta_imss: yup.bool(),
  es_padre: yup.bool(),


  // idsubnomina: yup.number(),
    idsubnomina: yup.number()
    .when(["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d", "50599e55-84f7-471d-bb3a-f5ff4b6d9b40"], {
      is: (esBase:any, esOtro:any) => esBase || esOtro,
      then: yup.number().required('Select product'),
    }),


  anio_alta: yup.number(),
  numero_quincena_alta: yup.number(),

  asegurado: yup.bool(),

  otra_quincena: yup.bool(),


  zona : yup.number(),
  region : yup.number(),


  // terms: yup.bool().required().oneOf([true], 'Los terminos y condiciones deben ser aceptadas'),
});


  const [ regimenes, setRegimenes] = useState<any>([])

  const cargarRegimenes = async() => {

    try{
      let res = await RequestService.getEndPoint('regimenes/lista')
      setRegimenes(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }
  const [ nominas, setNominas] = useState<any>([])

  const cargarNominas = async() => {

    try{
      let res = await RequestService.getEndPoint('nominas/lista')
      setNominas(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }

  const [ departamentos, setDepartamentos] = useState<any>([])

  const cargarDepartamentos = async() => {

    try{
      let res = await RequestService.getEndPoint('departamentos/lista')
      setDepartamentos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de departamentos')
    }

  }


  const [ grupos_funcional, setGruposFuncional] = useState<any>([{ puestos : [] }])

  const cargarPuestos = async() => {

    try{
      let res = await RequestService.getEndPoint('puestos/lista')
      setGruposFuncional(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }


  React.useEffect(() => {
    cargarDepartamentos()
    cargarNominas()
    cargarPuestos()
    cargarRegimenes()
  }, []);

const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{

  // console.log(nuevo_usuario)
  // console.log(nuevo_usuario.uuid_nomina)
  // console.log(JSON.parse(nuevo_usuario.uuid_puesto))


      await dialog.open({
        accept: 'Alta de Empleado',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
          <small>Información Personal</small>
          <dl>
            <dt>Nombre</dt>
            <dd>`+nuevo_usuario.nombres+` `+nuevo_usuario.paterno+` `+nuevo_usuario.materno+`</dd>
            <dt>CURP</dt>
            <dd>`+nuevo_usuario.curp+`</dd>
            <dt>RFC</dt>
            <dd>`+nuevo_usuario.rfc+`</dd>

          </dl>

          <small>Nómina</small>
          <dl>

            <dt>Plaza</dt>
            <dd>`+('No proporcionado')+`</dd> 

            <dt>Departamento</dt>
            <dd>`+('No proporcionado')+`</dd> 

            <dt>Puesto</dt>
            <dd>${ JSON.parse(nuevo_usuario.uuid_puesto).idnivel_jerarquico +' - ' + JSON.parse(nuevo_usuario.uuid_puesto).puesto }</dd> 

            <dt>Sueldo</dt>
            <dd>${

              nuevo_usuario.alta_imss ? ` P001 : $${numberWithCommas(nuevo_usuario.p001)} <br/> P002 : $${numberWithCommas(nuevo_usuario.p002)}<br/> P003 : $${numberWithCommas(nuevo_usuario.p003)}<br/> P025 : $${numberWithCommas(nuevo_usuario.p025)}<br/>  ` : `P302 : $${numberWithCommas(nuevo_usuario.p001)}`

            }</dd> 

            <dt>Fecha de Ingreso</dt>
            <dd>`+moment(nuevo_usuario.fecha_ingreso).format('DD/MMM/YYYY').toUpperCase().replace('.','')+`</dd> 




          </dl>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.create(`registro/alta_empleado/${nuevo_usuario.uuid_nomina}`, {...nuevo_usuario, fecha_ingreso : moment(nuevo_usuario.fecha_ingreso).format('YYYY-MM-DD'), fecha_movimiento : moment(nuevo_usuario.fecha_movimiento).format('YYYY-MM-DD'), fecha_nacimiento : curp2date(nuevo_usuario.curp), sexo : nuevo_usuario.curp.substr(10,1)==='H'?'H':'M' })

        if(res_nuevo_usuario){
          toast.success('El empleado fue dado de alta correctamente')
          setTimeout(()=>{
            onHide()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const [ validado_por_admin, setValidadoAdmin ] = useState<boolean>(false)

  const cambiarQuincena = async (e:any, setFieldValue:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: 'custom',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Para poder seleccionar alguna quincena diferente a la actual un administrador deberá confirmar el cambio</p>
          <label>Username<input type="text" name="username" value=""></label>
          <label>Password<input type="password" name="password" value=""></label>`
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.username !=='' && respuestas.password !==''){
          let res = await RequestService.getEndPointPost('verificar/administrador', respuestas )
          // console.log(res.data)
          toast.warning('Ahora puedes seleccionar la quincena y el año. Esto puede afectar al histórico de nóminas generadas.')
          setFieldValue('otra_quincena',true)
          setValidadoAdmin(true)
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }


  const [ puesto_seleccionado, setPuestoSeleccion ] = useState<any>({})


  const sumaPes = ( p001 : any, p002 : any, p003 : any, p025 : any, setFieldValue : any ) =>{

    setFieldValue('sueldo', (parseFloat(p001) + parseFloat(p002) + parseFloat(p003) + parseFloat(p025)) )

  }


  const [ show_ModalAltaCiudadanoSeleccionColonia, setModalAltaCiudadanoSeleccionColonia ] = useState<boolean>(false)
  const handleShow_ModalAltaCiudadanoSeleccionColonia = () => {
    setModalAltaCiudadanoSeleccionColonia(true)
  }
  const handleClose_ModalAltaCiudadanoSeleccionColonia = () => {
    setModalAltaCiudadanoSeleccionColonia(false)
  }



	return (
		<>

      <Modal show={show} size="lg" backdrop="static" onHide={onHide} animation={true}>

        <Modal.Header closeButton>
          <Modal.Title>
          	<small>ALTAS DE PERSONAL</small>
          </Modal.Title>
        </Modal.Header>


        <Modal.Body>




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{


      numero_empleado : "",

      celular:  "",
      email:  "",

      curp:  "",
      rfc:  "",
      nombres:  "",
      paterno:  "",
      materno:  "",
      estado_civil:  "SOLTERO",
      seguro_social:  "",

      uuid_nomina : "",
      uuid_puesto : "",
      uuid_departamento : "",

      p001 : 0,
      p002 : 0,
      p003 : 0,
      p025 : 0,
      sueldo : 0,



      observaciones : "",

      direccion:  "",
      num_int:  "",
      num_ext:  "",
      colonia:  "",
      cp:  "",
      cp_fiscal:  "",
      idregimen:  605,



        fecha_ingreso:  new Date(),
        fecha_movimiento:  new Date(),

        alta_imss: false,

        idsubnomina : null,


        anio_alta : moment().format('YYYY'),
        numero_quincena_alta : 1,

        otra_quincena: false,


        asegurado: false,
        es_padre: false,


        zona : '',
        region : '',

        // terms: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>

        {
          //JSON.stringify(values)
        }

          <h5>Información Personal</h5>

                <Form.Group as={Col} md="3" className="mb-3" controlId="numero_empleado">
                  <Form.Label>Número de Empleado</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="numero_empleado"
                    value={values.numero_empleado}
                    onChange={(e)=>{  setFieldValue('numero_empleado', e.target.value ); }}
                    isInvalid={!!errors.numero_empleado}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>


          <Row >
            <Form.Group as={Col} md="4" className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                type="text"
                required
                name="nombres"
                value={values.nombres}
                onChange={(e)=>{ setFieldValue('nombres', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.nombres}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.nombres} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="paterno">
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                type="text"
                required
                name="paterno"
                value={values.paterno}
                onChange={(e)=>{ setFieldValue('paterno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.paterno}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.paterno} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="materno">
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                type="text"
                name="materno"
                value={values.materno}
                onChange={(e)=>{ setFieldValue('materno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.materno}
              />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.materno} </Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row >
            <Form.Group as={Col} md="6" className="mb-3" controlId="curp">
              <Form.Label>CURP </Form.Label>
              <Form.Control
                type="text"
                required
                name="curp"
                value={values.curp}
                onChange={(e)=>{ setFieldValue('curp', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.curp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.curp  } </Form.Control.Feedback>
            </Form.Group>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Sexo</label>
                <input type="text" readOnly value={values.curp.substr(10,1)==='H'?'HOMBRE':'MUJER'} className="form-control" />
              </div>
                )
              }
            </Col>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Fecha de Nacimiento</label>
                <input type="text" readOnly value={curp2date(values.curp)} className="form-control" />
              </div>
                )
              }
            </Col>
          </Row>


          <Row >

            <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
              <Form.Label>RFC </Form.Label>
              <Form.Control
                type="text"
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.rfc}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.rfc  } </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp_fiscal">
              <Form.Label>Código Postal Fiscal</Form.Label>
              <Form.Control
                type="text"
                required
                name="cp_fiscal"
                value={values.cp_fiscal}
                onChange={(e)=>{ setFieldValue('cp_fiscal',e.target.value) }}
                isInvalid={!!errors.cp_fiscal}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.cp_fiscal} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="5" className="mb-3" controlId="rfc">
              <Form.Label>Estado Civil </Form.Label>
                <select className="form-select" value={values.estado_civil} required name="estado_civil" onChange={(e)=>{ setFieldValue('estado_civil', (e.target.value || "").toUpperCase()) }}>
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="SOLTERO">SOLTERO</option>
                  <option value="CASADO">CASADO</option>
                  <option value="DIVORCIADO">DIVORCIADO</option>
                  <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                  <option value="VIUDO">VIUDO</option>
                  <option value="CONCUBINATO">CONCUBINATO</option>
                </select>
              <Form.Control
                type="hidden"
                required
                name="estado_civil"
                value={values.estado_civil}
                isInvalid={!!errors.estado_civil}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.estado_civil  } </Form.Control.Feedback>
            </Form.Group>
          </Row>




          <Row>
            <Form.Group as={Col} md="4" className="mb-3" controlId="seguro_social">
              <Form.Label>Seguro Social</Form.Label>
              <Form.Control
                type="text"
                required
                name="seguro_social"
                value={values.seguro_social}
                onChange={(e)=>{ setFieldValue('seguro_social', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.seguro_social}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.seguro_social} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Regimen </Form.Label>
                <select className="form-select" value={values.idregimen} required name="idregimen" onChange={(e)=>{ setFieldValue('idregimen', (e.target.value) ); }}>
                            {
                              regimenes.map( (regimen:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={regimen.id}>{regimen.regimen}</option>
                                )
                            }
                </select>
              <Form.Control
                type="hidden"
                required
                name="idregimen"
                value={values.idregimen}
                isInvalid={!!errors.idregimen}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.idregimen  } </Form.Control.Feedback>
            </Form.Group>
          </Row>


          <Form.Group className="mb-3">
            <Form.Check
              inline
              name="asegurado"
              label="El empleado está asegurado"
              onChange={handleChange}
              isInvalid={!!errors.asegurado}
              feedback={errors.asegurado}
              feedbackType="invalid"
              id="asegurado"
            />

            <Form.Check
              inline
              name="es_padre"
              checked={values.es_padre}
              label="Es padre o madre"
              onChange={handleChange}
              isInvalid={!!errors.es_padre}
              feedbackType="invalid"
              id="es_padre"
            />
          </Form.Group>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          <h5>Nómina</h5>

          <Row>
            <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Plaza </Form.Label>
                <select className="form-select" value={values.uuid_nomina} required name="uuid_nomina" onChange={(e)=>{ setFieldValue('uuid_nomina', e.target.value); setFieldValue('idsubnomina', ( ["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d","50599e55-84f7-471d-bb3a-f5ff4b6d9b40"].indexOf(e.target.value) > -1 ? null : 0 )) }}>
                  <option value="" disabled>Selecciona la nómina en donde se agrega trabajador</option>
                  { nominas.map( (nomina:any)=>  <option key={ Math.random().toString(36).substr(2, 9) } value={nomina.uuid}>{nomina.nomina}</option> ) }
                </select>
              <Form.Control
                type="hidden"
                required
                name="uuid_nomina"
                value={values.uuid_nomina}
                isInvalid={!!errors.uuid_nomina}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.uuid_nomina  } </Form.Control.Feedback>
            </Form.Group>


                <Form.Group as={Col} md="8" className="mb-3" controlId="uuid_departamento">
                  <Form.Label>Selección de Departamento </Form.Label>
                    <select className="form-select" value={values.uuid_departamento} required name="uuid_departamento" onChange={(e)=>{ setFieldValue('uuid_departamento', ( e.target.value ) ) }}>
                      <option value="" disabled>Selecciona un departamento para el trabajador</option>
                      { 
                        departamentos.map( (direccion_deptos:any)=>
                            <optgroup key={ Math.random().toString(36).substr(2, 9) } label={direccion_deptos.direccion}>
                                {
                                  direccion_deptos.departamentos.map( (departamento:any) =>
                                      <option key={ Math.random().toString(36).substr(2, 9) } value={departamento.uuid}>{departamento.clave_departamento} - {departamento.departamento}</option>
                                    )
                                }
                            </optgroup>
                        ) 
                      }
                    </select>
                  <Form.Control
                    type="hidden"
                    required
                    name="uuid_departamento"
                    value={values.uuid_departamento}
                    isInvalid={!!errors.uuid_departamento}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"> {errors.uuid_departamento  } </Form.Control.Feedback>
                </Form.Group>



          </Row>

{
  // JSON.stringify(puesto_seleccionado)
}

            {
              values.uuid_nomina === "50599e55-84f7-471d-bb3a-f5ff4b6d9b40" && ( // OTRO

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',4)}}
                                            type="radio"
                                            label={`PRESIDENCIA`}
                                            id={`disabled-default-PRESIDENCIA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',10)}}
                                            type="radio"
                                            label={`EN TRAMITE DE JUBILACION`}
                                            id={`disabled-default-JUBILACION`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',9)}}
                                            type="radio"
                                            label={`PENSIONADOS`}
                                            id={`disabled-default-PENSIONADOS`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',5)}}
                                            type="radio"
                                            label={`PENSION POR DEP ECONÓMICO`}
                                            id={`disabled-default-DEP`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',6)}}
                                            type="radio"
                                            label={`PENSION POR JUBILACIÓN`}
                                            id={`disabled-default-jubilacion`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',7)}}
                                            type="radio"
                                            label={`PENSION POR ORFANDAD`}
                                            id={`disabled-default-ORFANDAD`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',8)}}
                                            type="radio"
                                            label={`PENSION POR VIUDEZ`}
                                            id={`disabled-default-VIUDEZ`}
                                          />

                    </div>

                )
            }



            {
              values.uuid_nomina === "09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d" && ( // BASE

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',1)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SSTSA`}
                                            id={`disabled-default-SSTSA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            onChange={(e)=>{setFieldValue('idsubnomina',2)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SUTSGM`}
                                            id={`disabled-default-SUTSGM`}
                                          />

                    </div>

                )
            }




          <Row>



            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Puesto </Form.Label>
                <select className="form-select" value={values.uuid_puesto} required name="uuid_puesto" onChange={(e)=>{ setFieldValue('uuid_puesto', (e.target.value) ); setPuestoSeleccion(JSON.parse(e.target.value)) }}>
                  <option value="" disabled>Selecciona un puesto para el trabajador</option>
                  { 
                    grupos_funcional.map( (grupo_funcional:any)=> 
                        <optgroup key={ Math.random().toString(36).substr(2, 9) } label={grupo_funcional.nivel_jerarquico}>
                            {
                              grupo_funcional.puestos.map( (puesto:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(puesto)}>{grupo_funcional.id} - {puesto.clave_puesto} - {puesto.puesto} ({puesto.idgrupo_funcional.grupo_funcional})</option>
                                )
                            }
                        </optgroup>
                    ) 
                  }
                </select>
              <Form.Control
                type="hidden"
                required
                name="uuid_puesto"
                value={values.uuid_puesto}
                isInvalid={!!errors.uuid_puesto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.uuid_puesto  } </Form.Control.Feedback>
            </Form.Group>


                <Form.Group as={Col} md="4" className="mb-3" controlId="sueldo">
                  <Form.Label>Sueldo</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    disabled={true}
                    name="sueldo"
                    value={values.sueldo}
                    onChange={(e)=>{ setFieldValue('sueldo', e.target.value ) }}
                    isInvalid={!!errors.sueldo}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"> {errors.sueldo} </Form.Control.Feedback>
                </Form.Group>

            </Row>



            <div className="row">
              <div className="col">
                {
                  // JSON.stringify(puesto_seleccionado)
                }

                {
                  values.uuid_puesto!=='' && (

                          <div className="alert alert-dark" role="alert">
                              <p className="nm"><small>Puesto</small></p>
                              <h4>{puesto_seleccionado.puesto}</h4>
                              <h6>$ {puesto_seleccionado.total_percepciones_asegurado_min} MIN - {puesto_seleccionado.total_percepciones_asegurado_max} MAX</h6>
                              <div className="row">
                                <div className="col-4">
                                  <small>Sueldo / Honorarios</small>
                                  <p className="nm">$ {puesto_seleccionado.sueldo_base_min} MIN - $ {puesto_seleccionado.sueldo_base_max} MAX </p>
                                  <small><b>P001</b></small>
                                </div>
                                <div className="col-4">
                                  <small>Salario Extraordinario</small>
                                  <p className="nm">$ {puesto_seleccionado.compensacion_max} MAX </p>
                                  <small><b>P003</b></small>
                                </div>
                                <div className="col-4">
                                  <small>PSM</small>
                                  <p className="nm">$ {puesto_seleccionado.psm} MAX </p>
                                  <small><b>P025</b></small>
                                </div>
                              </div>
                          </div>


                    )
                }


              </div>
            </div>



             <Row>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P001">
                  <Form.Label>{values.alta_imss ? 'P001' : 'P302'}</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p001"
                    value={values.p001}
                    onChange={(e)=>{  sumaPes( (e.target.value), (values.p002), (values.p003), (values.p025), setFieldValue ); setFieldValue('p001', e.target.value ); }}
                    isInvalid={!!errors.p001}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"> {errors.p001} </Form.Control.Feedback>
                    <div className="feedback">
                      <small>Sueldo / Honorarios</small>
                    </div>
                </Form.Group>


                {
                  values.alta_imss && (
                      <React.Fragment>

                            <Form.Group as={Col} md="3" className="mb-3" controlId="P002">
                              <Form.Label>P002</Form.Label>
                              <Form.Control
                                type="number"
                                required
                                disabled={values.uuid_puesto===''}
                                name="p002"
                                value={values.p002}
                                onChange={(e)=>{  sumaPes( (values.p001), (e.target.value), (values.p003), (values.p025), setFieldValue ); setFieldValue('p002', e.target.value ); }}
                                isInvalid={!!errors.p002}
                              />
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid"> {errors.p002} </Form.Control.Feedback>
                                <div className="feedback">
                                  <small>Quinquenio</small>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="3" className="mb-3" controlId="P003">
                              <Form.Label>P003</Form.Label>
                              <Form.Control
                                type="number"
                                required
                                disabled={values.uuid_puesto===''}
                                name="p003"
                                value={values.p003}
                                onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (e.target.value), (values.p025), setFieldValue ); setFieldValue('p003', e.target.value ); }}
                                isInvalid={!!errors.p003}
                              />
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid"> {errors.p003} </Form.Control.Feedback>
                                <div className="feedback">
                                  <small>Salario Extraordinario</small>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="3" className="mb-3" controlId="p025">
                              <Form.Label>P025</Form.Label>
                              <Form.Control
                                type="number"
                                required
                                disabled={values.uuid_puesto===''}
                                name="p025"
                                value={values.p025}
                                onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (values.p003), (e.target.value), setFieldValue ); setFieldValue('p025', e.target.value ); }}
                                isInvalid={!!errors.p025}
                              />
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid"> {errors.p025} </Form.Control.Feedback>
                                <div className="feedback">
                                  <small>PSM</small>
                                </div>
                            </Form.Group>

                      </React.Fragment>
                    )
                }



             </Row>



             <Row>
                <Form.Group as={Col} md="3"  className="mb-3">
                  <Form.Check
                    name="alta_imss"
                    label="Registro en el IMSS"
                    onChange={handleChange}
                    isInvalid={!!errors.alta_imss}
                    feedback={errors.alta_imss}
                    feedbackType="invalid"
                    id="validationFormik1"
                  />
                </Form.Group>
            </Row>







                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          <h5>Información de Contacto</h5>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.celular} </Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row>
            <Form.Group as={Col} md="8" className="mb-3" controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.direccion} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-3" controlId="num_int">
              <Form.Label>Núm Interior</Form.Label>
              <Form.Control
                type="text"
                name="num_int"
                value={values.num_int}
                onChange={(e)=>{ setFieldValue('num_int', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.num_int}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.num_int} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-3" controlId="num_ext">
              <Form.Label>Núm Exterior</Form.Label>
              <Form.Control
                type="text"
                name="num_ext"
                value={values.num_ext}
                onChange={(e)=>{ setFieldValue('num_ext', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.num_ext}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.num_ext} </Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row>
            <Form.Group as={Col} md="9" className="mb-3" controlId="colonia">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                type="text"
                required
                name="colonia"
                disabled
                value={values.colonia}
                onChange={(e)=>{ setFieldValue('colonia', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.colonia}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.colonia} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp">
              <Form.Label>Código Postal</Form.Label>
              <Form.Control
                type="text"
                required
                disabled
                name="cp"
                value={values.cp}
                onChange={(e)=>{ setFieldValue('cp',e.target.value) }}
                isInvalid={!!errors.cp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.cp} </Form.Control.Feedback>
            </Form.Group>
          </Row>


          <p><a className="btn btn-sm btn-link" onClick={(e:any)=>{handleShow_ModalAltaCiudadanoSeleccionColonia()}}>Seleccionar Colonia</a></p>


          {
            show_ModalAltaCiudadanoSeleccionColonia && (
              <ModalAltaCiudadanoSeleccionColonia show={show_ModalAltaCiudadanoSeleccionColonia} handleClose={handleClose_ModalAltaCiudadanoSeleccionColonia} setFieldValue={setFieldValue} />
              )

          }

          <Row>
            <Form.Group as={Col} md="3" className="mb-3" controlId="zona">
              <Form.Label>Zona</Form.Label>
              <Form.Control
                type="number"
                required
                name="zona"
                value={values.zona}
                onChange={(e)=>{ setFieldValue('zona', e.target.value ) }}
                isInvalid={!!errors.zona}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" className="mb-3" controlId="region">
              <Form.Label>Región</Form.Label>
              <Form.Control
                type="number"
                required
                name="region"
                value={values.region}
                onChange={(e)=>{ setFieldValue('region', e.target.value ) }}
                isInvalid={!!errors.region}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>






                <hr className="border border-primary border-3 opacity-75 mb-4"/>



          <Row>
            <Col md="8">

                  <Form.Group className="mb-3" controlId="observaciones">
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      type="textarea"
                      required
                      as="textarea"
                      rows={4}
                      name="observaciones"
                      value={values.observaciones}
                      onChange={(e)=>{ setFieldValue('observaciones', e.target.value.toUpperCase() ) }}
                      isInvalid={!!errors.observaciones}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {errors.observaciones} </Form.Control.Feedback>
                  </Form.Group>


                  {
                    !values.otra_quincena && (
                      <p>
                        <Button variant="link" onClick={(e:any)=>{cambiarQuincena(e,setFieldValue )}} style={{textAlign:'left'}}>¿Este empleado debe ser registrado en una quincena diferente a la actual?</Button>
                      </p>
                    )
                  }


                  {
                    ( values.otra_quincena && validado_por_admin ) && (
                      <>
                        
                          <div className="row">
                            <div className="col-md-6">
                              {
                                // JSON.stringify(values.anio_alta)
                              }

                                  <Form.Group  className="mb-3" controlId="anio_alta">
                                    <Form.Label>Año de Alta </Form.Label>
                                      <select className="form-select" value={values.anio_alta} required name="anio_alta" onChange={(e)=>{ setFieldValue('anio_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { anios_disponibles.map( (anio:any) => <option key={ Math.random().toString(36).substr(2, 9) } value={anio}>{anio}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="anio_alta"
                                      value={values.anio_alta}
                                      isInvalid={!!errors.anio_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid"> {errors.anio_alta  } </Form.Control.Feedback>
                                  </Form.Group>


                            </div>
                            <div className="col-md-6">

                                  <Form.Group  className="mb-3" controlId="numero_quincena_alta">
                                    <Form.Label>Quincena de Alta </Form.Label>
                                      <select className="form-select" value={values.numero_quincena_alta} required name="numero_quincena_alta" onChange={(e)=>{ setFieldValue('numero_quincena_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map( (quincena:number) => <option key={ Math.random().toString(36).substr(2, 9) } value={quincena}>{quincena}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="numero_quincena_alta"
                                      value={values.numero_quincena_alta}
                                      isInvalid={!!errors.numero_quincena_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    
                                  </Form.Group>
                            </div>
                          </div>


                      </>
                    )
                  }

            </Col>
            <Col md="4">

              <Form.Label>Fecha de Ingreso</Form.Label>
              <DatePicker selected={values.fecha_ingreso} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_ingreso', date ) } />

              <br/>

              <Form.Label>Fecha de Movimiento</Form.Label>
              <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_movimiento', date ) } />


            </Col>
          </Row>


          {
            // JSON.stringify(values)
          }

          <br/>
{
          // <Form.Group className="mb-3">
          //   <Form.Check
          //     required
          //     name="terms"
          //     label="Acepto los terminos y condiciones"
          //     onChange={handleChange}
          //     isInvalid={!!errors.terms}
          //     feedback={errors.terms}
          //     feedbackType="invalid"
          //     id="validationFormik0"
          //   />
          // </Form.Group>
        }

          {
            // envio_server && (
            //     <Button className="btn-lg" type="submit">Crear Usuario</Button>
            // )
          }
                <Button className="btn-lg" type="submit">ACEPTAR</Button>
          
        </Form>
      )}
    </Formik>



        </Modal.Body>



      </Modal>










		</>
	)

}


const ModalAltaCiudadanoSeleccionColonia = ( params : any ) => {

  const { show, handleClose, setFieldValue } = params

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          colonia : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

            setFieldValue('cp',registro.colonia.split('@')[1])
            setFieldValue('colonia',registro.colonia.split('@')[0])

            handleClose()

          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  const [ colonias, setColonias ] = useState<any>([])
  const cargarColonias = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`colonias_cp/${cp}`)
      toast.success('Puedes seleccionar una Colonia de la lista')
      setColonias(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar la lista de colonias')
    }
  }


  const [ cp_seleccionado, setCPSeleccionado ] = useState<any>(false)
  const [ cp, setCP ] = useState('')



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Selección de Colonia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(colonias)
          }

          <div className="input-group mb-3">
            <input type="text" className="form-control" value={cp} onChange={(e:any)=>{setCP(e.target.value)}} />
            <button onClick={cargarColonias} className="btn btn-outline-secondary" type="button" id="button-addon2">Buscar Colonia</button>
          </div>

              <Formik
                validationSchema={schema}
                onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                initialValues={{
                      colonia : ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form id='my-form-colonia' noValidate onSubmit={handleSubmit}>


                    <Form.Group  className="mb-3" controlId="colonia">
                      <Form.Label>Colonia</Form.Label>
                      <Form.Select required value={values.colonia} onChange={(e)=>{ setFieldValue('colonia',e.target.value) }} isInvalid={!!errors.colonia} >
                        <option>Selecciona una Colonia</option>
                            {
                              colonias.map((colonia:any,indice_colonia:number)=>
                                  <option key={indice_colonia} value={colonia.asentamiento +'@'+colonia.cp}>{colonia.asentamiento}</option>
                                )
                            }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.colonia} </Form.Control.Feedback>
                    </Form.Group>


                  </Form>
                )}
              </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form-colonia' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}





export default ModalAltaCiudadano