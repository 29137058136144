import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'



import moment from 'moment'
import 'moment/locale/es'


import ModalDetalleOficio from './ModalDetalleOficio'


import { SERVER_ } from  '../../config';

const MisOficiosRecibidos = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ reporte, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`control_oficios/${arreglo_estatus}/busqueda_mis_oficios?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }

  const [ oficio, setOficioSeleccionado ] = useState<any>({ })



  const [ show_ModalDetalleOficio, setModalDetalleOficio ] = useState<boolean>(false)
  const handleShow_ModalDetalleOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalDetalleOficio(true)
  }
  const handleClose_ModalDetalleOficio = () => {
    setModalDetalleOficio(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Mis Oficios Recibidos</h4>
                    <hr/>





        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>


            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
          </ul>

        </div>






                          {  (!cargando_) && (

                              <>

                                        {
                                          reporte.length > 0 && (

                                            <table className="table">
                                              <thead style={{fontSize:10}}>
                                                <tr>
                                                  <th className="v" scope="col">Folio <br/> Número de Oficio</th>
                                                  <th className="v" scope="col">Remitente / Asunto</th>
                                                  <th className="v" scope="col">Fecha Recepción</th>
                                                  <th className="v" scope="col">Fecha Oficio </th>
                                                  <th className="v" scope="col"> </th>

                                                </tr>
                                              </thead>
                                              <tbody>
                                              {
                                                reporte.map( (oficio:any)=>
                                                        <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                          <th className="pointer" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}} scope="row" style={{width:'180px'}}>
                                                            <p className="nm"><b>{oficio.folio}</b></p>
                                                            <small>{oficio.numero_oficio}</small>
                                                          </th>
                                                          
                                                          <td className="pointer v" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}}>
                                                            <b><small>{oficio.remitente}</small></b>
                                                            <p className="nm"><small>{oficio.asunto}</small></p>
                                                          </td>
                                                          <td className="pointer v" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}} style={{width:140}}>
                                                            <b><small>{oficio.fecha_recepcion}</small></b>
                                                          </td>
                                                          <td className="pointer v" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}} style={{width:140}}>
                                                            <b><small>{oficio.fecha_oficio}</small></b>
                                                          </td>

                                                          <td className="v" style={{width:40}}>
                                                            {
                                                              oficio.archivo !== null && (
                                                              <a target="_blank" href={`${SERVER_}control_oficios/archivo/${oficio.uuid}`}><i className="fa fa-file-o"/></a>
                                                              )
                                                            }
                                                          </td>

                                                        </tr>
                                                 )
                                              }

                                              </tbody>
                                            </table>

                                          )
                                        }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
                    
                </div>
              </div>

            </div>



  {
    show_ModalDetalleOficio && (
      <ModalDetalleOficio show={show_ModalDetalleOficio} handleClose={handleClose_ModalDetalleOficio} oficio={oficio} />
      )

  }



          </>

      );
  

}

export default MisOficiosRecibidos
