import React, { useState } from 'react';

import RequestService from "../../../services/request.service";

import AuthService from '../../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'
import { curp2date, REG_CURP, REG_RFC, REG_NOMBRES, REG_SS } from '../../../services/funcionesPublicas'


import Dialog from '../../../services/Dialogs'



const ModalEditarMovimientoEmpleado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, movimiento } = params


  const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]



  const mensaes_validacion_generico = 'Este campo es obligatorio'

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
const schema = yup.object().shape({


  curp: yup.string().required(mensaes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  rfc: yup.string().required(mensaes_validacion_generico).matches( REG_RFC , "Formato de RFC invalido").required('El RFC es un campo obligatorio'),
  email: yup.string().email('Formato de Email no valido'),
  celular: yup.string().matches(/^\d{10}$/,"Formato no valido"),

  nombres: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensaes_validacion_generico),
  paterno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensaes_validacion_generico),
  materno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido"),

  estado_civil: yup.string().required(mensajes_validacion_generico),
  seguro_social: yup.string().matches( REG_SS ,'El formato de Número de Seguro Social no es correcto'),

  direccion: yup.string(),
  colonia: yup.string(),
  cp: yup.string(), ///.required(mensaes_validacion_generico),

  cp_fiscal: yup.string(),
  idregimen: yup.number(),

  uuid_nomina: yup.string().required(mensaes_validacion_generico),
  uuid_puesto: yup.string().required(mensaes_validacion_generico),
  uuid_departamento: yup.string().required(mensaes_validacion_generico),

  p001: yup.number(), //.required(mensaes_validacion_generico)
  p002: yup.number(), //.required(mensaes_validacion_generico),
  p003: yup.number(), //.required(mensaes_validacion_generico)
  p025: yup.number(), //.required(mensaes_validacion_generico)
  sueldo: yup.number(), //.required(mensaes_validacion_generico)


  observaciones: yup.string(),
 

  fecha_ingreso: yup.string().required(),
  fecha_movimiento: yup.string().required(),

  alta_imss: yup.bool(),


  // idsubnomina: yup.number(),
    idsubnomina: yup.number().positive().nullable(true).transform(v => v === '' ? null : v)
    .when(["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d", "50599e55-84f7-471d-bb3a-f5ff4b6d9b40"], {
      is: (esBase:any, esOtro:any) => esOtro || esBase,
      then: yup.number().required('Select product'),
    }),


  anio_alta: yup.number(),
  numero_quincena_alta: yup.number(),



  extraordinaria: yup.bool(),

  otra_quincena: yup.bool(),



  // terms: yup.bool().required().oneOf([true], 'Los terminos y condiciones deben ser aceptadas'),
});


  const [ nominas, setNominas] = useState<any>([])

  const cargarNominas = async() => {

    try{
      let res = await RequestService.getEndPoint('nominas/lista')
      setNominas(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }

  const [ departamentos, setDepartamentos] = useState<any>([])

  const cargarDepartamentos = async() => {

    try{
      let res = await RequestService.getEndPoint('departamentos/lista')
      setDepartamentos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de departamentos')
    }

  }


  const [ grupos_funcional, setGruposFuncional] = useState<any>([{ puestos : [] }])

  const cargarPuestos = async() => {

    try{
      let res = await RequestService.getEndPoint('puestos/lista')
      setGruposFuncional(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }



  React.useEffect(() => {
    cargarDepartamentos()
    cargarNominas()
    cargarPuestos()
    cargarRegimenes()
  }, []);


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


    const  [movimientos_detectados, setMovimientosDetectados ] = useState<any>({
          departamento : movimiento.cambio_adscripcion == 1,
          sub_nomina : movimiento.cambio_plaza == 1,
          puesto : movimiento.cambio_puesto == 1,
          nomina : movimiento.cambio_plaza == 1,
          sueldo : movimiento.cambio_sueldo == 1,
          alta_imss : movimiento.cambio_alta_imss == 1,
        })

const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{


  console.log('??????????????')
      await dialog.open({
        accept: 'Aceptar',
        cancel: 'Espera',
        dialogClass: '',
        message: `¿Estás seguro?`,
        target: event.target,
        template: true ? '' : `
          <dl>

            ` +
            (movimientos_detectados.departamento ? `<dt>Departamento</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.puesto ? `<dt>Puesto</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.nomina ? `<dt>Plaza</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.sub_nomina ? `<dt>Clasificación de Plaza</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.sueldo ? `<dt>Sueldo</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.alta_imss ? `<dt>Asegurado</dt><dd>Si</dd>` : ` `) +

            `
          </dl>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update(`movimientos/modificaciones/reingresos/${movimiento.uuid_movimiento}`, { 
                                                                                                                  // , 
                                                                                                                  nuevo_usuario : {
                                                                                                                    ...nuevo_usuario,
                                                                                                                      sueldo : parseFloat(nuevo_usuario.sueldo).toFixed(2),
                                                                                                                      fecha_ingreso : moment(nuevo_usuario.fecha_ingreso).format('YYYY-MM-DD'),
                                                                                                                      fecha_nacimiento : curp2date(nuevo_usuario.curp), 
                                                                                                                      sexo : nuevo_usuario.curp.substr(10,1)==='H'?'H':'M',  

                                                                                                                  },
                                                                                                                  // nuevo_usuario : nuevo_usuario, 

                                                                                                                  movimientos_detectados : movimientos_detectados, fecha_movimiento : moment(nuevo_usuario.fecha_movimiento).format('YYYY-MM-DD') } )

        if(res_nuevo_usuario){
          toast.warning('El Cambio en la información del movimiento fue cambiado correctamente',{ icon : '✅' })
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al crear usuario en el servidor')
          setSeEnvio(true)
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          setSeEnvio(false)
          // console.log(e.response.data)
        } 
        
      }
     
}


const [ validado_por_admin, setValidadoAdmin ] = useState<boolean>(false)

  const cambiarQuincena = async (e:any, setFieldValue:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: 'custom',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Para poder seleccionar alguna quincena diferente a la actual un administrador deberá confirmar el cambio</p>
          <label>Username<input type="text" name="username" value="admin"></label>
          <label>Password<input type="password" name="password" value="admin"></label>`
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.username !=='' && respuestas.password !==''){
          let res = await RequestService.getEndPointPost('verificar/administrador', respuestas )
          // console.log(res.data)
          toast.warning('Ahora puedes seleccionar la quincena y el año. Esto puede afectar al histórico de nóminas generadas.')
          setFieldValue('otra_quincena',true)
          setValidadoAdmin(true)
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }


  const [ puesto_seleccionado, setPuestoSeleccion ] = useState<any>({ ...movimiento.puesto_ })


  const sumaPes = ( p001 : any, p002 : any, p003 : any, p025 : any, setFieldValue : any ) =>{

    setFieldValue('sueldo', (parseFloat(p001) + parseFloat(p002) + parseFloat(p003) + parseFloat(p025)) )

  }





  const [ regimenes, setRegimenes] = useState<any>([])

  const cargarRegimenes = async() => {

    try{
      let res = await RequestService.getEndPoint('regimenes/lista')
      setRegimenes(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }
 




  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>EDITAR MOVIMIENTO *</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(movimiento)
          }


            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{


                  direccion: movimiento.direccion,
                  colonia:  movimiento.colonia,
                  cp:  movimiento.cp,

                  celular:  movimiento.celular || '',
                  email:  movimiento.email || '',

                  curp:  movimiento.curp,
                  rfc:  movimiento.rfc,
                  nombres:  movimiento.nombres,
                  paterno:  movimiento.paterno,
                  materno:  movimiento.materno,


                  estado_civil:  movimiento.estado_civil,
                  seguro_social:  movimiento.seguro_social || '',

                  cp_fiscal:  movimiento.cp_fiscal,
                  idregimen:  movimiento.idregimen,





                  uuid_nomina : movimiento.uuid_nomina,
                  uuid_puesto :  JSON.stringify(movimiento.puesto_),
                  uuid_departamento : movimiento.uuid_departamento,

                  idsubnomina : movimiento.idsubnomina,

                  p001 : movimiento.p001,
                  p002 : movimiento.p002,
                  p003 : movimiento.p003,
                  p025 : movimiento.p025,
                  sueldo : movimiento.sueldo,

                  fecha_ingreso:  new Date(movimiento.fecha_ingreso),
                  fecha_movimiento:  new Date(movimiento.fecha_movimiento),


                  observaciones : movimiento.observaciones,

                  alta_imss: movimiento.alta_imss,

                  anio_alta : movimiento.anio_movimiento,
                  numero_quincena_alta : movimiento.quincena_movimiento,

                  otra_quincena: false,


                  extraordinaria: movimiento.extraordinaria,


              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


                {
                  // JSON.stringify(movimiento)
                }


                {
                  // JSON.stringify(values)
                }





          <h5>Información Personal</h5>

          <Row >
            <Form.Group as={Col} md="4" className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                type="text"
                required
                name="nombres"
                value={values.nombres}
                onChange={(e)=>{ setFieldValue('nombres', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.nombres}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="paterno">
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                type="text"
                required
                name="paterno"
                value={values.paterno}
                onChange={(e)=>{ setFieldValue('paterno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.paterno}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="materno">
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                type="text"
                name="materno"
                value={values.materno}
                onChange={(e)=>{ setFieldValue('materno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.materno}
              />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row >
            <Form.Group as={Col} md="6" className="mb-3" controlId="curp">
              <Form.Label>CURP </Form.Label>
              <Form.Control
                type="text"
                required
                name="curp"
                value={values.curp}
                onChange={(e)=>{ setFieldValue('curp', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.curp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Sexo</label>
                <input type="text" readOnly value={values.curp.substr(10,1)==='H'?'HOMBRE':'MUJER'} className="form-control" />
              </div>
                )
              }
            </Col>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Fecha de Nacimiento</label>
                <input type="text" readOnly value={curp2date(values.curp)} className="form-control" />
              </div>
                )
              }
            </Col>
          </Row>


          <Row >

            <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
              <Form.Label>RFC </Form.Label>
              <Form.Control
                type="text"
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.rfc}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp_fiscal">
              <Form.Label>Código Postal Fiscal</Form.Label>
              <Form.Control
                type="text"
                required
                name="cp_fiscal"
                value={values.cp_fiscal}
                onChange={(e)=>{ setFieldValue('cp_fiscal',e.target.value) }}
                isInvalid={!!errors.cp_fiscal}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="5" className="mb-3" controlId="rfc">
              <Form.Label>Estado Civil </Form.Label>
                <Form.Select className="form-select" value={values.estado_civil} required name="estado_civil" onChange={handleChange} isInvalid={!!errors.estado_civil}>
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="SOLTERO">SOLTERO</option>
                  <option value="CASADO">CASADO</option>
                  <option value="DIVORCIADO">DIVORCIADO</option>
                  <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                  <option value="VIUDO">VIUDO</option>
                  <option value="CONCUBINATO">CONCUBINATO</option>
                </Form.Select> 
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>




          <Row>
            <Form.Group as={Col} md="4" className="mb-3" controlId="direccion">
              <Form.Label>Seguro Social</Form.Label>
              <Form.Control
                type="text"
                name="seguro_social"
                value={values.seguro_social}
                onChange={(e)=>{ setFieldValue('seguro_social', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.seguro_social}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Regimen </Form.Label>
                <select className="form-select" value={values.idregimen} required name="idregimen" onChange={(e)=>{ setFieldValue('idregimen', (e.target.value) ); }}>
                            {
                              regimenes.map( (regimen:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={regimen.id}>{regimen.regimen}</option>
                                )
                            }
                </select>
              <Form.Control
                type="hidden"
                required
                name="idregimen"
                value={values.idregimen}
                isInvalid={!!errors.idregimen}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>


 

                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          <h5>Información de Contacto</h5>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>



            <Form.Group className="mb-3" controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



          <Row>
            <Form.Group as={Col} md="9" className="mb-3" controlId="colonia">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                type="text"
                required
                name="colonia"
                value={values.colonia}
                onChange={(e)=>{ setFieldValue('colonia', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.colonia}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp">
              <Form.Label>Código Postal</Form.Label>
              <Form.Control
                type="text"
                required
                name="cp"
                value={values.cp}
                onChange={(e)=>{ setFieldValue('cp',e.target.value) }}
                isInvalid={!!errors.cp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>






                <hr className="border border-primary border-3 opacity-75 mb-4"/>





                <h5>Nómina</h5>

                <Row>
                  <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
                    <Form.Label>Selección de Plaza</Form.Label>
                      <select className="form-select" value={values.uuid_nomina} name="uuid_nomina" onChange={(e)=>{ setFieldValue('uuid_nomina', e.target.value); setFieldValue('idsubnomina', ( ["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d","50599e55-84f7-471d-bb3a-f5ff4b6d9b40"].indexOf(e.target.value) > -1 ? null : null )) }}>
                        <option value="" disabled>Selecciona la nómina en donde se agrega trabajador</option>
                        { nominas.map( (nomina:any)=>  <option key={ Math.random().toString(36).substr(2, 9) } value={nomina.uuid}>{nomina.nomina}</option> ) }
                      </select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>


                      <Form.Group as={Col} md="8" className="mb-3" controlId="uuid_departamento">
                        <Form.Label>Selección de Departamento </Form.Label>
                          <select className="form-select" value={values.uuid_departamento} required name="uuid_departamento" onChange={(e)=>{ setFieldValue('uuid_departamento', ( e.target.value ) ) }}>
                            <option value="" disabled>Selecciona un departamento para el trabajador</option>
                            { 
                              departamentos.map( (direccion_deptos:any)=>
                                  <optgroup key={ Math.random().toString(36).substr(2, 9) } label={direccion_deptos.direccion}>
                                      {
                                        direccion_deptos.departamentos.map( (departamento:any) =>
                                            <option key={ Math.random().toString(36).substr(2, 9) } value={departamento.uuid}>{departamento.clave_departamento} - {departamento.departamento}</option>
                                          )
                                      }
                                  </optgroup>
                              ) 
                            }
                          </select>
                        <Form.Control
                          type="hidden"
                          required
                          name="uuid_departamento"
                          value={values.uuid_departamento}
                          isInvalid={!!errors.uuid_departamento}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>



                </Row>


            {
              values.uuid_nomina === "50599e55-84f7-471d-bb3a-f5ff4b6d9b40" && ( // OTRO

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===4}
                                            onChange={(e)=>{setFieldValue('idsubnomina',4)}}
                                            type="radio"
                                            label={`PRESIDENCIA`}
                                            id={`disabled-default-PRESIDENCIA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===10}
                                            onChange={(e)=>{setFieldValue('idsubnomina',10)}}
                                            type="radio"
                                            label={`EN TRAMITE DE JUBILACION`}
                                            id={`disabled-default-JUBILACION`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===9}
                                            onChange={(e)=>{setFieldValue('idsubnomina',9)}}
                                            type="radio"
                                            label={`PENSIONADOS`}
                                            id={`disabled-default-PENSIONADOS`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===5}
                                            onChange={(e)=>{setFieldValue('idsubnomina',5)}}
                                            type="radio"
                                            label={`PENSION POR DEP ECONÓMICO`}
                                            id={`disabled-default-DEP`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===6}
                                            onChange={(e)=>{setFieldValue('idsubnomina',6)}}
                                            type="radio"
                                            label={`PENSION POR JUBILACIÓN`}
                                            id={`disabled-default-jubilacion`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===7}
                                            onChange={(e)=>{setFieldValue('idsubnomina',7)}}
                                            type="radio"
                                            label={`PENSION POR ORFANDAD`}
                                            id={`disabled-default-ORFANDAD`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===8}
                                            onChange={(e)=>{setFieldValue('idsubnomina',8)}}
                                            type="radio"
                                            label={`PENSION POR VIUDEZ`}
                                            id={`disabled-default-VIUDEZ`}
                                          />

                    </div>

                )
            }



            {
              values.uuid_nomina === "09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d" && ( // BASE

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===1}
                                            onChange={(e)=>{setFieldValue('idsubnomina',1)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SSTSA`}
                                            id={`disabled-default-SSTSA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===2}
                                            onChange={(e)=>{setFieldValue('idsubnomina',2)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SUTSGM`}
                                            id={`disabled-default-SUTSGM`}
                                          />

                    </div>

                )
            }





          <Row>

            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Puesto </Form.Label>
                <select className="form-select" value={values.uuid_puesto} required name="uuid_puesto" onChange={(e)=>{ setFieldValue('uuid_puesto', (e.target.value) ); setPuestoSeleccion(JSON.parse(e.target.value)) }}>
                  <option value="" disabled>Selecciona un puesto para el trabajador</option>
                  { 
                    grupos_funcional.map( (grupo_funcional:any)=> 
                        <optgroup key={ Math.random().toString(36).substr(2, 9) } label={grupo_funcional.nivel_jerarquico}>
                            {
                              grupo_funcional.puestos.map( (puesto:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(puesto)}>{grupo_funcional.id} - {puesto.clave_puesto} - {puesto.puesto} ({puesto.idgrupo_funcional.grupo_funcional})</option>
                                )
                            }
                        </optgroup>
                    ) 
                  }
                </select>
              <Form.Control
                type="hidden"
                required
                name="uuid_puesto"
                value={values.uuid_puesto}
                isInvalid={!!errors.uuid_puesto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


                <Form.Group as={Col} md="4" className="mb-3" controlId="sueldo">
                  <Form.Label>Sueldo</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    disabled={true}
                    name="sueldo"
                    value={values.sueldo}
                    onChange={(e)=>{ setFieldValue('sueldo', e.target.value ) }}
                    isInvalid={!!errors.sueldo}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

            </Row>



                {
                  // JSON.stringify(puesto_seleccionado)
                }

                {
                  values.uuid_puesto!=='' && (

                        <div className="row">
                          <div className="col">

                                      <div className="alert alert-dark" role="alert">
                                          <p className="nm"><small>Puesto</small></p>
                                          <h4>{puesto_seleccionado.puesto}</h4>
                                          <h6>$ {puesto_seleccionado.total_percepciones_asegurado_min} MIN - {puesto_seleccionado.total_percepciones_asegurado_max} MAX</h6>
                                          <div className="row">
                                            <div className="col-4">
                                              <small>Sueldo / Honorarios</small>
                                              <p className="nm">$ {puesto_seleccionado.sueldo_base_min} MIN - $ {puesto_seleccionado.sueldo_base_max} MAX </p>
                                              <small><b>P001</b></small>
                                            </div>
                                            <div className="col-4">
                                              <small>Salario Extraordinario</small>
                                              <p className="nm">$ {puesto_seleccionado.compensacion_max} MAX </p>
                                              <small><b>P003</b></small>
                                            </div>
                                            <div className="col-4">
                                              <small>PSM</small>
                                              <p className="nm">$ {puesto_seleccionado.psm} MAX </p>
                                              <small><b>P025</b></small>
                                            </div>
                                          </div>
                                      </div>

                          </div>
                        </div>

                    )
                }




             <Row>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P001">
                  <Form.Label>{values.alta_imss ? 'P001' : 'P302'}</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p001"
                    value={values.p001}
                    onChange={(e)=>{  sumaPes( (e.target.value), (values.p002), (values.p003), (values.p025), setFieldValue ); setFieldValue('p001', e.target.value ); }}
                    isInvalid={!!errors.p001}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      {values.alta_imss && (<small>Honorarios</small>)}
                      {!values.alta_imss && (<small>Sueldo</small>)}
                    </div>
                </Form.Group>

                {
                  values.alta_imss && (
                      <React.Fragment>
                        
                <Form.Group as={Col} md="3" className="mb-3" controlId="P002">
                  <Form.Label>P002</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p002"
                    value={values.p002}
                    onChange={(e)=>{  sumaPes( (values.p001), (e.target.value), (values.p003), (values.p025), setFieldValue ); setFieldValue('p002', e.target.value ); }}
                    isInvalid={!!errors.p002}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Quinquenio</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P003">
                  <Form.Label>P003</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p003"
                    value={values.p003}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (e.target.value), (values.p025), setFieldValue ); setFieldValue('p003', e.target.value ); }}
                    isInvalid={!!errors.p003}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Salario Extraordinario</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="p025">
                  <Form.Label>P025</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p025"
                    value={values.p025}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (values.p003), (e.target.value), setFieldValue ); setFieldValue('p025', e.target.value ); }}
                    isInvalid={!!errors.p025}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>PSM</small>
                    </div>
                </Form.Group>


                      </React.Fragment>
                    )
                }


             </Row>



             <Row>
                <Form.Group as={Col} md="3"  className="mb-3">
                  <Form.Check
                    name="alta_imss"
                    label="Registro en el IMSS"
                    checked={values.alta_imss}
                    onChange={handleChange}
                    isInvalid={!!errors.alta_imss}
                    feedbackType="invalid"
                    id="validationFormik1"
                  />
                </Form.Group>
            </Row>






                <hr className="border border-primary border-3 opacity-75 mb-4"/>






          <Row>
            <Col md="8">

                  <Form.Group className="mb-3" controlId="observaciones">
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      type="textarea"
                      required
                      as="textarea"
                      rows={4}
                      name="observaciones"
                      value={values.observaciones}
                      onChange={(e)=>{ setFieldValue('observaciones', e.target.value.toUpperCase() ) }}
                      isInvalid={!!errors.observaciones}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {<>{errors.observaciones}</>} </Form.Control.Feedback>
                  </Form.Group>


           
                          <Form.Group as={Col} md="12"  className="mb-3">
                            <Form.Check
                              name="extraordinaria"
                              label="Nómina Extraordinaria"
                              checked={values.extraordinaria}
                              onChange={handleChange}
                              id="extraordinaria"
                            />
                          </Form.Group> 




                  {
                    !values.otra_quincena && (
                      <p>
                        <Button variant="link" onClick={(e:any)=>{cambiarQuincena(e,setFieldValue )}} style={{textAlign:'left'}}>¿Este empleado debe ser registrado en una quincena diferente a la actual?</Button>
                      </p>
                    )
                  }


                  {
                    ( values.otra_quincena && validado_por_admin ) && (
                      <>
                        
                          <div className="row">
                            <div className="col-md-6">
                              {
                                // JSON.stringify(values.anio_alta)
                              }

                                  <Form.Group  className="mb-3" controlId="anio_alta">
                                    <Form.Label>Año de Alta </Form.Label>
                                      <select className="form-select" value={values.anio_alta} required name="anio_alta" onChange={(e)=>{ setFieldValue('anio_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { anios_disponibles.map( (anio:any) => <option key={ Math.random().toString(36).substr(2, 9) } value={anio}>{anio}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="anio_alta"
                                      value={values.anio_alta}
                                      isInvalid={!!errors.anio_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid"> {<>{errors.anio_alta}</>} </Form.Control.Feedback>
                                  </Form.Group>


                            </div>
                            <div className="col-md-6">

                                  <Form.Group  className="mb-3" controlId="numero_quincena_alta">
                                    <Form.Label>Quincena de Alta </Form.Label>
                                      <select className="form-select" value={values.numero_quincena_alta} required name="numero_quincena_alta" onChange={(e)=>{ setFieldValue('numero_quincena_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map( (quincena:number) => <option key={ Math.random().toString(36).substr(2, 9) } value={quincena}>{quincena}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="numero_quincena_alta"
                                      value={values.numero_quincena_alta}
                                      isInvalid={!!errors.numero_quincena_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    
                                  </Form.Group>
                            </div>
                          </div>


                      </>
                    )
                  }

            </Col>
            <Col md="4">

              <Form.Label>Fecha de Ingreso</Form.Label>
              <DatePicker selected={values.fecha_ingreso} className="form-control" dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_ingreso', date ) } />

              <Form.Label>Fecha de Movimiento</Form.Label>
              <DatePicker selected={values.fecha_movimiento} className="form-control" dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_movimiento', date ) } />



            </Col>
          </Row>


 

                </Form>
              )}
            </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar Cambios <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarMovimientoEmpleado;