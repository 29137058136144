import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'


import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import { anios_disponibles } from '../../services/funcionesPublicas'


const ModalMovimientoEmpleado = ( params : any ) => {

  const { show, handleClose, empleado } = params

  // const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]

  const dialog = new Dialog();


var mensaes_validacion_generico = 'Este campo es obligatorio'


const schema = yup.object().shape({

  // email: yup.string().email('Formato de Email no valido'),
  // celular: yup.string().required(mensaes_validacion_generico).matches(/^\d{10}$/,"Formato no valido"),

  uuid_nomina: yup.string().required(mensaes_validacion_generico),
  uuid_puesto: yup.string().required(mensaes_validacion_generico),
  uuid_departamento: yup.string().required(mensaes_validacion_generico),

  p001: yup.number(), //.required(mensaes_validacion_generico)
            // .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
            //     // console.log(puesto_seleccionado.sueldo_base_min)
            //     // console.log(puesto_seleccionado.sueldo_base_max)
            //     return ( value >= puesto_seleccionado.sueldo_base_min && value <= puesto_seleccionado.sueldo_base_max );
            //   }),
  p002: yup.number(), //.required(mensaes_validacion_generico),
  p003: yup.number(), //.required(mensaes_validacion_generico)
            // .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
            //     // console.log(puesto_seleccionado.sueldo_base_min)
            //     // console.log(puesto_seleccionado.sueldo_base_max)
            //     return ( value >= 0 && value <= puesto_seleccionado.compensacion_max );
            //   }),
  p025: yup.number(), //.required(mensaes_validacion_generico)
            // .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
            //     // console.log(puesto_seleccionado.sueldo_base_min)
            //     // console.log(puesto_seleccionado.sueldo_base_max)
            //     return ( value >= 0 && value <= puesto_seleccionado.psm );
            //   }),
  sueldo: yup.number(), //.required(mensaes_validacion_generico)
          // .test('is-full-name', 'El Sueldo debe ser mayor a 0 pero no puede pasarse del límite', function (value:number=0) {
          //       // const nameArr = value.split(" ");
          //       return value <= puesto_seleccionado.total_percepciones_asegurado_max
          //     }),



  observaciones: yup.string(),


  // direccion: yup.string().required(mensaes_validacion_generico),
  // colonia: yup.string().required(mensaes_validacion_generico),
  // cp: yup.string().required(mensaes_validacion_generico),

  fecha_movimiento: yup.string().required(),

  alta_imss: yup.number(),


  // idsubnomina: yup.number(),
    idsubnomina: yup.number().positive().nullable(true).transform(v => v === '' ? null : v)
    .when(["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d", "50599e55-84f7-471d-bb3a-f5ff4b6d9b40"], {
      is: (esBase:any, esOtro:any) => esOtro || esBase,
      then: yup.number().required('Select product'),
    }),


  anio_alta: yup.number(),
  numero_quincena_alta: yup.number(),



  extraordinaria: yup.bool(),

  otra_quincena: yup.bool(),



  // terms: yup.bool().required().oneOf([true], 'Los terminos y condiciones deben ser aceptadas'),
});





  const [ nominas, setNominas] = useState<any>([])

  const cargarNominas = async() => {

    try{
      let res = await RequestService.getEndPoint('nominas/lista')
      setNominas(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }

  const [ departamentos, setDepartamentos] = useState<any>([])

  const cargarDepartamentos = async() => {

    try{
      let res = await RequestService.getEndPoint('departamentos/lista')
      setDepartamentos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de departamentos')
    }

  }


  const [ grupos_funcional, setGruposFuncional] = useState<any>([{ puestos : [] }])

  const cargarPuestos = async() => {

    try{
      let res = await RequestService.getEndPoint('puestos/lista')
      setGruposFuncional(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }



  React.useEffect(() => {
    cargarDepartamentos()
    cargarNominas()
    cargarPuestos()
  }, []);




const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{
// console.log('====')
    let movimientos_detectados = {
      departamento : false,
      sub_nomina : false,
      puesto : false,
      nomina : false,
      sueldo : false,
      alta_imss : false,
    }

    // console.log(nuevo_usuario.sueldo)
    //console.log(nuevo_usuario )
    // console.log(empleado.sueldo)
    //console.log(empleado)

    if(nuevo_usuario.uuid_departamento !== empleado.departamento.uuid) movimientos_detectados.departamento = true
    if(nuevo_usuario.uuid_puesto !== JSON.stringify(empleado.puesto)) movimientos_detectados.puesto = true
    if(nuevo_usuario.uuid_nomina !== empleado.nomina.uuid) movimientos_detectados.nomina = true
    if(empleado.idsubnomina){
      if(nuevo_usuario.idsubnomina !== empleado.idsubnomina.id) movimientos_detectados.sub_nomina = true
    }
    if(nuevo_usuario.sueldo !== empleado.sueldo && nuevo_usuario.sueldo !== 0) movimientos_detectados.sueldo = true
    if(nuevo_usuario.alta_imss !== 0){
      // if(nuevo_usuario.alta_imss !== empleado.alta_imss) 
        movimientos_detectados.alta_imss = true
    }

      if(!movimientos_detectados.departamento && !movimientos_detectados.puesto && !movimientos_detectados.nomina && !movimientos_detectados.sueldo && !movimientos_detectados.alta_imss && !movimientos_detectados.sub_nomina){
        toast.error('Debes registrar algún movimiento ')
        return
      }

      await dialog.open({
        accept: 'Movimiento de Empleado',
        cancel: 'Espera',
        dialogClass: '',
        message: `Detectamos los siguientes movimientos:`,
        target: event.target,
        template: `
          <dl>

            ` +
            (movimientos_detectados.departamento ? `<dt>Departamento</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.puesto ? `<dt>Puesto</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.nomina ? `<dt>Plaza</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.sub_nomina ? `<dt>Clasificación de Plaza</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.sueldo ? `<dt>Sueldo</dt><dd>Si</dd>` : ` `) +
            (movimientos_detectados.alta_imss ? `<dt>Aseguramiento</dt><dd>Si</dd>` : ` `) +

            `
          </dl>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.create(`personal/movimiento/${empleado.curp}`, {...nuevo_usuario, movimientos_detectados : movimientos_detectados, fecha_movimiento : moment(nuevo_usuario.fecha_movimiento).format('YYYY-MM-DD') } )

        if(res_nuevo_usuario){
          toast.success('El registro del movimiento del trabajador fue correcto')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al crear el movimiento en el servidor')
          setSeEnvio(true)
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          setSeEnvio(false)
          // console.log(e.response.data)
        } 
        
      }
     
}




const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const [ validado_por_admin, setValidadoAdmin ] = useState<boolean>(false)

  const cambiarQuincena = async (e:any, setFieldValue:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: 'custom',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Para poder seleccionar alguna quincena diferente a la actual un administrador deberá confirmar el cambio</p>
          <label>Username<input type="text" name="username" value="admin"></label>
          <label>Password<input type="password" name="password" value="admin"></label>`
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.username !=='' && respuestas.password !==''){
          let res = await RequestService.getEndPointPost('verificar/administrador', respuestas )
          // console.log(res.data)
          toast.warning('Ahora puedes seleccionar la quincena y el año. Esto puede afectar al histórico de nóminas generadas.')
          setFieldValue('otra_quincena',true)
          setValidadoAdmin(true)
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }


  const [ puesto_seleccionado, setPuestoSeleccion ] = useState<any>({ ...empleado.puesto })


  const sumaPes = ( p001 : any, p002 : any, p003 : any, p025 : any, setFieldValue : any ) =>{

    setFieldValue('sueldo', (parseFloat(p001) + parseFloat(p002) + parseFloat(p003) + parseFloat(p025)).toFixed(2) )

  }


  const [ es_asegurado, setEsAsegurado ] = useState<boolean>( empleado.asegurado )


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>MOVIMIENTOS *</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado.nomina.uuid)
          }





    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

          uuid_nomina : empleado.nomina.uuid,
          uuid_puesto :  JSON.stringify(empleado.puesto),
          uuid_departamento :  empleado.departamento.uuid,

      p001 : 0,
      p002 : 0,
      p003 : 0,
      p025 : 0,
      sueldo : 0,



      observaciones : "",


      fecha_movimiento:  new Date(),

      alta_imss: 0,

      idsubnomina : empleado.idsubnomina ? empleado.idsubnomina.id : null,


      anio_alta : moment().format('YYYY'),
      numero_quincena_alta : 1,

      otra_quincena: false,

      extraordinaria: false,





        terms: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }

          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{empleado.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{empleado.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>

{
  //JSON.stringify(empleado.idsubnomina)
}
{
  //JSON.stringify(values)
}



          <h5>Nómina</h5>

          <Row>

              <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
                <Form.Label>Selección de Plaza</Form.Label>
                  <select className="form-select" value={values.uuid_nomina} name="uuid_nomina" onChange={(e)=>{ setFieldValue('uuid_nomina', e.target.value); setFieldValue('idsubnomina', ( ["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d","50599e55-84f7-471d-bb3a-f5ff4b6d9b40"].indexOf(e.target.value) > -1 ? null : null )) }}>
                    <option value="" disabled>Selecciona la nómina en donde se agrega trabajador</option>
                    { nominas.map( (nomina:any)=>  <option key={ Math.random().toString(36).substr(2, 9) } value={nomina.uuid}>{nomina.nomina}</option> ) }
                  </select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="8" className="mb-3" controlId="uuid_departamento">
                <Form.Label>Selección de Departamento </Form.Label>
                  <select className="form-select" value={values.uuid_departamento} required name="uuid_departamento" onChange={(e)=>{ setFieldValue('uuid_departamento', ( e.target.value ) ) }}>
                    <option value="" disabled>Selecciona un departamento para el trabajador</option>
                    { 
                      departamentos.map( (direccion_deptos:any)=>
                          <optgroup key={ Math.random().toString(36).substr(2, 9) } label={direccion_deptos.direccion}>
                              {
                                direccion_deptos.departamentos.map( (departamento:any) =>
                                    <option key={ Math.random().toString(36).substr(2, 9) } value={departamento.uuid}>{departamento.clave_departamento} - {departamento.departamento}</option>
                                  )
                              }
                          </optgroup>
                      ) 
                    }
                  </select>
                <Form.Control
                  type="hidden"
                  required
                  name="uuid_departamento"
                  value={values.uuid_departamento}
                  isInvalid={!!errors.uuid_departamento}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

          </Row>

{
  // JSON.stringify(values.uuid_puesto)
}

            {
              values.uuid_nomina === "50599e55-84f7-471d-bb3a-f5ff4b6d9b40" && ( // OTRO

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===4}
                                            onChange={(e)=>{setFieldValue('idsubnomina',4)}}
                                            type="radio"
                                            label={`PRESIDENCIA`}
                                            id={`disabled-default-PRESIDENCIA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===10}
                                            onChange={(e)=>{setFieldValue('idsubnomina',10)}}
                                            type="radio"
                                            label={`EN TRAMITE DE JUBILACION`}
                                            id={`disabled-default-JUBILACION`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===9}
                                            onChange={(e)=>{setFieldValue('idsubnomina',9)}}
                                            type="radio"
                                            label={`PENSIONADOS`}
                                            id={`disabled-default-PENSIONADOS`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===5}
                                            onChange={(e)=>{setFieldValue('idsubnomina',5)}}
                                            type="radio"
                                            label={`PENSION POR DEP ECONÓMICO`}
                                            id={`disabled-default-DEP`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===6}
                                            onChange={(e)=>{setFieldValue('idsubnomina',6)}}
                                            type="radio"
                                            label={`PENSION POR JUBILACIÓN`}
                                            id={`disabled-default-jubilacion`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===7}
                                            onChange={(e)=>{setFieldValue('idsubnomina',7)}}
                                            type="radio"
                                            label={`PENSION POR ORFANDAD`}
                                            id={`disabled-default-ORFANDAD`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===8}
                                            onChange={(e)=>{setFieldValue('idsubnomina',8)}}
                                            type="radio"
                                            label={`PENSION POR VIUDEZ`}
                                            id={`disabled-default-VIUDEZ`}
                                          />

                    </div>

                )
            }



            {
              values.uuid_nomina === "09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d" && ( // BASE

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===1}
                                            onChange={(e)=>{setFieldValue('idsubnomina',1)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SSTSA`}
                                            id={`disabled-default-SSTSA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===2}
                                            onChange={(e)=>{setFieldValue('idsubnomina',2)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SUTSGM`}
                                            id={`disabled-default-SUTSGM`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===12}
                                            onChange={(e)=>{setFieldValue('idsubnomina',12)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS FUERTEC`}
                                            id={`disabled-default-FUERTEC`}
                                          />

                    </div>

                )
            }




          <Row>

            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Puesto </Form.Label>
                <select className="form-select" value={values.uuid_puesto} required name="uuid_puesto" onChange={(e)=>{ setFieldValue('uuid_puesto', (e.target.value) ); setPuestoSeleccion(JSON.parse(e.target.value)) }}>
                  <option value="" disabled>Selecciona un puesto para el trabajador</option>
                  { 
                    grupos_funcional.map( (grupo_funcional:any)=> 
                        <optgroup key={ Math.random().toString(36).substr(2, 9) } label={grupo_funcional.nivel_jerarquico}>
                            {
                              grupo_funcional.puestos.map( (puesto:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(puesto)}>{grupo_funcional.id} - {puesto.clave_puesto} - {puesto.puesto} ({puesto.idgrupo_funcional.grupo_funcional})</option>
                                )
                            }
                        </optgroup>
                    ) 
                  }
                </select>
              <Form.Control
                type="hidden"
                required
                name="uuid_puesto"
                value={values.uuid_puesto}
                isInvalid={!!errors.uuid_puesto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


                <Form.Group as={Col} md="4" className="mb-3" controlId="sueldo">
                  <Form.Label>Sueldo</Form.Label>
                  <Form.Control
                    type="text" 
                    disabled={true}
                    name="sueldo"
                    value={values.sueldo}
                    onChange={(e)=>{ setFieldValue('sueldo', e.target.value ) }}
                    isInvalid={!!errors.sueldo}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

            </Row>



                {
                  // JSON.stringify(puesto_seleccionado)
                }

                {
                  values.uuid_puesto!=='' && (

                        <div className="row">
                          <div className="col">

                                      <div className="alert alert-dark" role="alert">
                                          <p className="nm"><small>Puesto</small></p>
                                          <h4>{puesto_seleccionado.puesto}</h4>
                                          <h6>$ {puesto_seleccionado.total_percepciones_asegurado_min} MIN - {puesto_seleccionado.total_percepciones_asegurado_max} MAX</h6>
                                          <div className="row">
                                            <div className="col-4">
                                              <small>Sueldo / Honorarios</small>
                                              <p className="nm">$ {puesto_seleccionado.sueldo_base_min} MIN - $ {puesto_seleccionado.sueldo_base_max} MAX </p>
                                              <small><b>P001</b></small>
                                            </div>
                                            <div className="col-4">
                                              <small>Salario Extraordinario</small>
                                              <p className="nm">$ {puesto_seleccionado.compensacion_max} MAX </p>
                                              <small><b>P003</b></small>
                                            </div>
                                            <div className="col-4">
                                              <small>PSM</small>
                                              <p className="nm">$ {puesto_seleccionado.psm} MAX </p>
                                              <small><b>P025</b></small>
                                            </div>
                                          </div>
                                      </div>

                          </div>
                        </div>

                    )
                }





             <Row>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P001">
                  <Form.Label>{values.alta_imss === 1 ? 'P001' : 'P302'}</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p001"
                    value={values.p001}
                    onChange={(e)=>{  sumaPes( (e.target.value), (values.p002), (values.p003), (values.p025), setFieldValue ); setFieldValue('p001', e.target.value ); }}
                    isInvalid={!!errors.p001}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      {values.alta_imss === 1 && (<small>Honorarios</small>)}
                      {values.alta_imss !== 1 && (<small>Sueldo</small>)}
                    </div>
                </Form.Group>

                {
                  es_asegurado && (
                      <React.Fragment>
                        
                <Form.Group as={Col} md="3" className="mb-3" controlId="P002">
                  <Form.Label>P002</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p002"
                    value={values.p002}
                    onChange={(e)=>{  sumaPes( (values.p001), (e.target.value), (values.p003), (values.p025), setFieldValue ); setFieldValue('p002', e.target.value ); }}
                    isInvalid={!!errors.p002}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Quinquenio</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P003">
                  <Form.Label>P003</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p003"
                    value={values.p003}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (e.target.value), (values.p025), setFieldValue ); setFieldValue('p003', e.target.value ); }}
                    isInvalid={!!errors.p003}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Salario Extraordinario</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="p025">
                  <Form.Label>P025</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p025"
                    value={values.p025}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (values.p003), (e.target.value), setFieldValue ); setFieldValue('p025', e.target.value ); }}
                    isInvalid={!!errors.p025}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>PSM</small>
                    </div>
                </Form.Group>


                      </React.Fragment>
                    )
                }




             </Row>

 
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="inlineCheckbox1" checked={values.alta_imss==1} onChange={(e:any)=>{setFieldValue('alta_imss',1);setEsAsegurado(true);}} disabled={empleado.asegurado} />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">Registro en el IMSS</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="inlineCheckbox2" checked={values.alta_imss==2} onChange={(e:any)=>{setFieldValue('alta_imss',2);setEsAsegurado(false);}} disabled={!empleado.asegurado} />
                  <label className="form-check-label" htmlFor="inlineCheckbox2">Baja al IMSS</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="inlineCheckbox3" checked={values.alta_imss==3} onChange={(e:any)=>{setFieldValue('alta_imss',3);setEsAsegurado(false);}} disabled={!empleado.asegurado} />
                  <label className="form-check-label" htmlFor="inlineCheckbox3">Baja al ISSSTECAM</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" id="inlineCheckbox0" checked={values.alta_imss==0} onChange={(e:any)=>{setFieldValue('alta_imss',0);setEsAsegurado(empleado.asegurado);}} />
                  <label className="form-check-label" htmlFor="inlineCheckbox0">Sin Registro IMSS { empleado.asegurado && ( <small>(Actualmente Asegurado <i className="fa fa-star"/>)</small> ) } </label>
                </div>

{
            //  <Row>
            //     <Form.Group as={Col} md="3"  className="mb-3">
            //       <Form.Check
            //         name="alta_imss"
            //         label="Registro en el IMSS"
            //         checked={values.alta_imss}
            //         onChange={handleChange}
            //         isInvalid={!!errors.alta_imss===1}
            //         feedbackType="invalid"
            //         id="validationFormik1"
            //       />
            //     </Form.Group>
            // </Row>

}




                <hr className="border border-primary border-3 opacity-75 mb-4"/>





          <Row>
            <Col md="8">

                  <Form.Group className="mb-3" controlId="observaciones">
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      type="textarea"
                      required
                      as="textarea"
                      rows={4}
                      name="observaciones"
                      value={values.observaciones}
                      onChange={(e)=>{ setFieldValue('observaciones', e.target.value.toUpperCase() ) }}
                      isInvalid={!!errors.observaciones}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {errors.observaciones} </Form.Control.Feedback>
                  </Form.Group>


 
                <Form.Group as={Col} md="12"  className="mb-3">
                  <Form.Check
                    name="extraordinaria"
                    label="Nómina Extraordinaria"
                    checked={values.extraordinaria}
                    onChange={handleChange}
                    id="extraordinaria"
                  />
                </Form.Group> 





                  {
                    !values.otra_quincena && (
                      <p>
                        <Button variant="link" onClick={(e:any)=>{cambiarQuincena(e,setFieldValue )}} style={{textAlign:'left'}}>¿Este empleado debe ser registrado en una quincena diferente a la actual?</Button>
                      </p>
                    )
                  }


                  {
                    ( values.otra_quincena && validado_por_admin ) && (
                      <>
                        
                          <div className="row">
                            <div className="col-md-6">
                              {
                                // JSON.stringify(values.anio_alta)
                              }

                                  <Form.Group  className="mb-3" controlId="anio_alta">
                                    <Form.Label>Año de Alta </Form.Label>
                                      <select className="form-select" value={values.anio_alta} required name="anio_alta" onChange={(e)=>{ setFieldValue('anio_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { anios_disponibles.map( (anio:any) => <option key={ Math.random().toString(36).substr(2, 9) } value={anio}>{anio}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="anio_alta"
                                      value={values.anio_alta}
                                      isInvalid={!!errors.anio_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid"> {errors.anio_alta  } </Form.Control.Feedback>
                                  </Form.Group>


                            </div>
                            <div className="col-md-6">

                                  <Form.Group  className="mb-3" controlId="numero_quincena_alta">
                                    <Form.Label>Quincena de Alta </Form.Label>
                                      <select className="form-select" value={values.numero_quincena_alta} required name="numero_quincena_alta" onChange={(e)=>{ setFieldValue('numero_quincena_alta', e.target.value) }}>
                                        <option value={0} disabled>Selecciona una opción</option>
                                        { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map( (quincena:number) => <option key={ Math.random().toString(36).substr(2, 9) } value={quincena}>{quincena}</option> ) }
                                      </select>
                                    <Form.Control
                                      type="hidden"
                                      required
                                      name="numero_quincena_alta"
                                      value={values.numero_quincena_alta}
                                      isInvalid={!!errors.numero_quincena_alta}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    
                                  </Form.Group>
                            </div>
                          </div>


                      </>
                    )
                  }

            </Col>
            <Col md="4">
              <Form.Label>Fecha de Movimiento</Form.Label>
              <DatePicker selected={values.fecha_movimiento} className="form-control" inline dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
            </Col>
          </Row>

          <br/>





          {
            !envio_server && (
                <Button className="btn-lg" variant="success" type="submit">ACEPTAR LOS CAMBIOS</Button>
            )
          }
          
        </Form>
      )}
    </Formik>




        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalMovimientoEmpleado;