import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Form, Modal, Col, Row, Nav, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import Dialog from '../../services/Dialogs'



import './vacaciones.scss'

const ModalLicencias = ( params : any ) => {

  const { show, handleClose, empleado, config } = params

  const dialog = new Dialog()


  const [ cargando_, setCargando ] = useState( false )
  

  const [ dias_pedidos, setDiasPedidos ] = useState<any>([])


  const cargar = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`licencias/dias_pedidos/${empleado.uuid_ciudadano}`)
      setDiasPedidos(response.data)
      setCargando(false)
    }
    catch(e:any){
      setCargando(false)
      toast.error(e.response.data + '. Problemas al intentar cargar la lista de licancias')
    }
  }



  // const [ config, setConfig ] = useState<any>({})
  // const cargarConfig = async( )=>{
  //   try{
  //     setCargando(true)
  //     let response : any = await RequestService.getEndPoint(`config`)
  //     setConfig(response.data)
  //     if(config.periodo==1) setMonths(periodo_1)
  //     if(config.periodo==2) setMonths(periodo_2)
  //     setCargando(false)
  //   }
  //   catch(e:any){
  //     setCargando(false)
  //     toast.error(e.response.data + '. Problemas al intentar cargar la lista de vacaciones')
  //   }
  // }



      React.useEffect(() => {
        cargar()
        // cargarConfig()
      }, []);


      const MAXIMO_DIAS = 182

    const periodo_1 = [{ mes :"enero", indice : 0 },
      { mes :"febrero", indice : 1 },
      { mes :"marzo", indice : 2 },
      { mes :"abril", indice : 3 },
      { mes :"mayo", indice : 4 },
      { mes :"junio", indice : 5 }]
    const periodo_2 = [{ mes :"julio", indice : 6 },
      { mes :"agosto", indice : 7 },
      { mes :"septiembre", indice : 8 },
      { mes :"octubre", indice : 9 },
      { mes :"noviembre", indice : 10 },
      { mes :"diciembre", indice : 11 }]

  const [months, setMonths ] = useState<any>( config.periodo === 1 ? periodo_1 : periodo_2 );

  const year = 2024;

  // function to check and grey out previous & next months visible dates
  const isExtraDays = (week:any, date:any) => {
    if (week === 0 && date > 10) {
      return true;
    } else if (week === 5 && date < 10) {
      return true;
    } else if (week === 4 && date < 10) {
      return true;
    } else {
      return false;
    }
  };

  //function to get all days by week
  const getDate = (month:any) => {
    var calendar = [];

    const startDate = moment([year, month])
      .clone()
      .startOf("month")
      .startOf("week");

    const endDate = moment([year, month]).clone().endOf("month");

    const day = startDate.clone().subtract(1, "day");

    // looping a month by a week
    while (day.isBefore(endDate, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone().format("DD"))
      );
    }

    if (calendar.length > 0) {
      return calendar.map((week, index) => (
        <tr key={ Math.random().toString(36).substr(2, 9) } className="calender-row">
          {week.map((day) => (
            <td onClick={()=>{agregarDia( moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day  , isExtraDays(index, day) ) }} 
              key={ Math.random().toString(36).substr(2, 9) } className={ `calender-col 
                                                                            ${ dias_seleccionados.indexOf(moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day) > -1 && !isExtraDays(index, day)  ? 'no_seleccionable_picado' : '' } 
                                                                            ${ dias_pedidos.indexOf(moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day) > -1 && !isExtraDays(index, day) ? ('no_seleccionable') : ('') }` } >
              <span className="day-value">
                {isExtraDays(index, day) ? (
                  <span className="isDates-grey">{day}</span>
                ) : (
                   day
                )}
              </span>
            </td>
          ))}
        </tr>
      ));
    }
  };


  const [ dias_seleccionados, setDiasSeleccionados ] = useState<any>([])

  const agregarDia = (dia:any,estan_en_mes:boolean)=>{
    if((dias_seleccionados.length + dias_pedidos.length) > MAXIMO_DIAS){
      let d = []
      for(let D of dias_seleccionados) if( D !== dia) d.push(D)
      setDiasSeleccionados(d)
      return
    }
    let d = [...dias_seleccionados]
    if(dias_seleccionados.indexOf(dia) === -1 && !estan_en_mes && dias_pedidos.indexOf(dia) === -1) d.push(dia);
    else{
      d = []
      for(let D of dias_seleccionados) if( D !== dia) d.push(D)
    }
    setDiasSeleccionados(d)
  }

  const enviar = async()=>{
    if(dias_seleccionados.length===0){ 
        toast.warning('Debes seleccionar al menos un día de licencias')
        return
    }
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.getEndPointPost('licencias/agregar_dias/'+empleado.uuid_ciudadano, { dias_seleccionados : dias_seleccionados, observaciones : detalles, con_goce : retardo })
        if(res.data){
          console.log(dias_seleccionados)
          toast.success('La licencia fue notificado al departamento de Recursos Humanos')        
          handleClose()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar guardar los días')
      }
    }
  }


const [detalles, setDetalles] = useState<string>('');

const [retardo, setRetardo] = useState<boolean>(false);


  // const [ arreglo_nominas, setArregloNominas ] = useState<any>([true,true,true,true,true])


  const todos = () =>{
    setDiasSeleccionados(["2023-01-01","2023-01-02"])
  }
  const ninguno = () =>{
    setDiasSeleccionados([])
  }

  const mes = (mes:number)=>{
    const dias_por_mes = [31,28,31,30,31,30,31,31,30,31,30,31]
    let d = []
    for(let i = 1; i <= dias_por_mes[mes]; i++){
      d.push( moment().format('YYYY')+'-'+((mes+1).toString()).padStart(2,'0')+'-'+(i.toString()).padStart(2,'0') )
    }
    setDiasSeleccionados(d)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de las Licencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(config)
          }


          <h5>Empleado</h5>

              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>


          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>




                    <ul className="list-inline">
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(0)}}>Enero</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(1)}}>Febrero</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(2)}}>Marzo</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(3)}}>Abril</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(4)}}>Mayo</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(5)}}>Junio</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(6)}}>Julio</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(7)}}>Agosto</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(8)}}>Septiembre</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(9)}}>Octubre</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(10)}}>Noviembre</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{mes(11)}}>Diciembre</small></li>
                      <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                    </ul>



{
  // JSON.stringify(dias_seleccionados)
}
{
  (dias_seleccionados.length + dias_pedidos.length) > MAXIMO_DIAS && (

<div className="alert alert-danger" role="alert">
  No puedes agregar más días de descanso
</div>

    )
}


    <div className="row">

        {months.map((month:any, indice:number) => (
        <div key={ Math.random().toString(36).substr(2, 9) } className="col-sm-6">
          <div className="tableContainer">
            <table   className="calender-date">
            <tbody>
              <tr>
                <th className="month-name-col" colSpan={7}>
                  <h4 className="month-name">{month.mes}</h4>
                </th>
              </tr>
              <tr style={{color:'grey'}}>
                <th>Lu</th>
                <th>Ma</th>
                <th>Mi</th>
                <th>Ju</th>
                <th>Vi</th>
                <th>Sa</th>
                <th>Do</th>
              </tr>
              {getDate(month.indice)}
              </tbody>
            </table>
          </div>
        </div>
        ))}

    </div>



<div className="mb-3">
  <Form.Check
    checked={retardo}
    onChange={(e:any)=>{setRetardo(e.target.checked)}}
    type="checkbox"
    id={`default-retardo`}
    label={`Con Goce de Sueldo`}
  />
</div>

                    <div className="mb-3 mt-3">
                      <label htmlFor="razon" className="form-label">Detalles de la solicitud:</label>
                      <textarea className="form-control" name="observaciones" value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={(e)=>{enviar()}} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalLicencias;