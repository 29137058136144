import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'



const ModalAgregarPuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          puesto : yup.string().required(mensajes_validacion_generico),
          idgrupo_funcional : yup.string().required(mensajes_validacion_generico),
          idnivel_jerarquico : yup.string().required(mensajes_validacion_generico),

          sueldo_base_min : yup.number().required(mensajes_validacion_generico),
          sueldo_base_max : yup.number().required(mensajes_validacion_generico),
          compensacion_max : yup.number().required(mensajes_validacion_generico),

          psm : yup.number().required(mensajes_validacion_generico),
          total_percepciones_asegurado_min : yup.number().required(mensajes_validacion_generico),
          total_percepciones_asegurado_max : yup.number().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `puesto/crear` , { ...registro })

          if(res_){
            toast.success('Actualización Correcta')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }





  const [ grupos, setGrupos ] = useState<any>([])
  const cargarGrupos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`grupos`)
      setGrupos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ niveles, setNiveles ] = useState<any>([])
  const cargarNiveles = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`niveles`)
      setNiveles(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }





  React.useEffect(() => {
    cargarGrupos()
    cargarNiveles()
  }, []);
    










  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información de Puesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(puesto)
          }



            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{

                    puesto : "",
                    idgrupo_funcional : "",
                    idnivel_jerarquico : "",

                    sueldo_base_min : 0,
                    sueldo_base_max : 0,
                    compensacion_max : 0,
                    
                    psm :0,
                    total_percepciones_asegurado_min : 0,
                    total_percepciones_asegurado_max : 0,



              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>




                    <Form.Group   className="mb-3" controlId="puesto">
                      <Form.Label>Puesto</Form.Label>
                      <Form.Control
                        required
                        name="puesto"
                        value={values.puesto}
                        onChange={(e)=>{ setFieldValue('puesto', (e.target.value || "").toUpperCase() ) }}
                        isInvalid={!!errors.puesto}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.puesto}</>} </Form.Control.Feedback>
                    </Form.Group>



                    <div className="row">
                      <div className="col">

                          <Form.Group className="mb-3" controlId="idnivel_jerarquico">
                            <Form.Label>Nivel </Form.Label>
                              <select className="form-select" value={values.idnivel_jerarquico} required name="idnivel_jerarquico" onChange={(e)=>{ setFieldValue('idnivel_jerarquico', e.target.value) }}>
                                <option value="" disabled>Selecciona una opción</option>
                                { niveles.map( (nivel:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={nivel.uuid}>{nivel.nivel_jerarquico} </option> ) }
                              </select>
                            <Form.Control
                              type="hidden"
                              required
                              name="idnivel_jerarquico"
                              value={values.idnivel_jerarquico}
                              isInvalid={!!errors.idnivel_jerarquico}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.idnivel_jerarquico}</>} </Form.Control.Feedback>
                          </Form.Group>


                      </div>
                      <div className="col">
                        
                          <Form.Group className="mb-3" controlId="idgrupo_funcional">
                            <Form.Label>Grupo Funcional</Form.Label>
                              <select className="form-select" value={values.idgrupo_funcional} required name="idgrupo_funcional" onChange={(e)=>{ setFieldValue('idgrupo_funcional', e.target.value) }}>
                                <option value="" disabled>Selecciona una opción</option>
                                { grupos.map( (grupo:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={grupo.uuid}>{grupo.grupo_funcional} </option> ) }
                              </select>
                            <Form.Control
                              type="hidden"
                              required
                              name="idgrupo_funcional"
                              value={values.idgrupo_funcional}
                              isInvalid={!!errors.idgrupo_funcional}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.idgrupo_funcional}</>} </Form.Control.Feedback>
                          </Form.Group>

                      </div>
                    </div>



                    <div className="row">
                      <div className="col">

                          <Form.Group className="mb-3" controlId="sueldo_base_min">
                            <Form.Label>Sueldo Báse (Mín)</Form.Label>
                            <Form.Control
                              required
                              name="sueldo_base_min"
                              value={values.sueldo_base_min}
                              onChange={(e)=>{ setFieldValue('sueldo_base_min', (e.target.value) ) }}
                              isInvalid={!!errors.sueldo_base_min}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.sueldo_base_min}</>} </Form.Control.Feedback>
                          </Form.Group>


                      </div>
                      <div className="col">

                          <Form.Group className="mb-3" controlId="sueldo_base_max">
                            <Form.Label>Sueldo Báse (Máx)</Form.Label>
                            <Form.Control
                              required
                              name="sueldo_base_max"
                              value={values.sueldo_base_max}
                              onChange={(e)=>{ setFieldValue('sueldo_base_max', (e.target.value) ) }}
                              isInvalid={!!errors.sueldo_base_max}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.sueldo_base_max}</>} </Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col">

                          <Form.Group className="mb-3" controlId="compensacion_max">
                            <Form.Label>Compensación (Máx)</Form.Label>
                            <Form.Control
                              required
                              name="compensacion_max"
                              value={values.compensacion_max}
                              onChange={(e)=>{ setFieldValue('compensacion_max', (e.target.value) ) }}
                              isInvalid={!!errors.compensacion_max}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.compensacion_max}</>} </Form.Control.Feedback>
                          </Form.Group>

                      </div>
                    </div>




                    <div className="row">
                      <div className="col">

                          <Form.Group className="mb-3" controlId="psm">
                            <Form.Label>PSM</Form.Label>
                            <Form.Control
                              required
                              name="psm"
                              value={values.psm}
                              onChange={(e)=>{ setFieldValue('psm', (e.target.value) ) }}
                              isInvalid={!!errors.psm}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.psm}</>} </Form.Control.Feedback>
                          </Form.Group>


                      </div>
                      <div className="col">

                          <Form.Group className="mb-3" controlId="total_percepciones_asegurado_min">
                            <Form.Label>Percepción Asegurado (Míx)</Form.Label>
                            <Form.Control
                              required
                              name="total_percepciones_asegurado_min"
                              value={values.total_percepciones_asegurado_min}
                              onChange={(e)=>{ setFieldValue('total_percepciones_asegurado_min', (e.target.value) ) }}
                              isInvalid={!!errors.total_percepciones_asegurado_min}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.total_percepciones_asegurado_min}</>} </Form.Control.Feedback>
                          </Form.Group>

                      </div> 
                      <div className="col">

                          <Form.Group className="mb-3" controlId="total_percepciones_asegurado_max">
                            <Form.Label>Percepción Asegurado (Máx)</Form.Label>
                            <Form.Control
                              required
                              name="total_percepciones_asegurado_max"
                              value={values.total_percepciones_asegurado_max}
                              onChange={(e)=>{ setFieldValue('total_percepciones_asegurado_max', (e.target.value) ) }}
                              isInvalid={!!errors.total_percepciones_asegurado_max}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.total_percepciones_asegurado_max}</>} </Form.Control.Feedback>
                          </Form.Group>

                      </div> 
                    </div>






                </Form>
              )}
            </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAgregarPuesto;