import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

const Control = () =>{

      // const [ registro, setRegistro ] = useState<any>([])

      // const cargar = async () =>{
      //   try{
      //       let res = await RequestService.getEndPoint(`URL_REQUEST`)
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }

      // React.useEffect(() => {
      //   cargar()
      // }, []);


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Control</h4>
                    <hr/>
                    
                </div>
              </div>

            </div>

          </>

      );
  

}

export default Control
