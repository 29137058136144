import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Row, Col, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'



const ModalBaja = ( params : any ) => {

  const dialog = new Dialog();




  const { show, handleClose, empleado } = params


  const [ observaciones, setObservaciones ] = useState('') 

  // const altaAseguramiento = async(e:any)=>{
  //   try{

  //       dialog.open({
  //         accept: 'ACEPTAR',
  //         cancel: 'ESPERA',
  //         message: 'ASEGURAMIENTO DEL EMPLEADO',
  //         target: e.target,
  //         template: ``
  //       })
  //       let respuesta : any = await dialog.waitForUser()
  //           let response : any = await RequestService.post(`personal/aseguramiento/${empleado.uuid_ciudadano}`, { fecha_movimiento : fecha_movimiento, observaciones : observaciones } )
  //           toast.warning('Este trabajador fue marcado como asegurado')
  //           handleClose()

  //   }
  //   catch(e){
  //     // console.log(e)
  //     toast.error('Problemas al intentar dar de asegurar este empleado')
  //   }
  // }


  const [ fecha_movimiento, setFechaMovimiento ] = useState<any>(new Date())





  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          seguro_social: yup.string().required(mensajes_validacion_generico),
          observaciones: yup.string(),
          direccion: yup.string(),
          colonia: yup.string(),
          cp: yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          // let res_ = await RequestService.update( `URL_REQUEST` , { ...registro, campo_fecha : moment(registro.campo_fecha).format('YYYY-MM-DD') })
            let response : any = await RequestService.post(`personal/aseguramiento/${empleado.uuid_ciudadano}`, { ...registro, fecha_movimiento : moment(fecha_movimiento).format('YYYY-MM-DD') } )


          if(response){
            toast.warning('Este trabajador fue marcado como asegurado')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }









  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Aseguramiento del Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <h5>Información Personal</h5>


              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{empleado.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{empleado.rfc}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          {
            // JSON.stringify(empleado)
          }

          <h5>Información Laboral</h5>



          <div className="row">
            <div className="col-sm-12">
              <small>Plaza</small>
              <h6 className="nm">{empleado.nomina.nomina}</h6>

              <small>Departamento</small>
              <h6>{empleado.departamento.clave_departamento} {empleado.departamento.departamento}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-12">
              <small>Puesto</small>
              <h6>{empleado.puesto.clave_puesto} {empleado.puesto.puesto} </h6>
            </div>
          </div>



                <hr className="border border-primary border-3 opacity-75 mb-4"/>

          <h5>Detalles del Movimiento</h5>




          <Row>
            <Col md="6">
              <Form.Label>Fecha del Movimiento</Form.Label>
              <DatePicker selected={fecha_movimiento} className="form-control" inline dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFechaMovimiento(date) } />
            </Col>
          </Row>

 




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            seguro_social : empleado.seguro_social,
            observaciones : "",
            direccion : empleado.direccion,
            colonia : empleado.colonia,
            cp : empleado.cp,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>





            <Form.Group className="mb-3 mt-3" controlId="seguro_social">
              <Form.Label>Seguro Social</Form.Label>
              <Form.Control 
                required
                name="seguro_social"
                value={values.seguro_social}
                onChange={(e)=>{ setFieldValue('seguro_social', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.seguro_social}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="observaciones">
              <Form.Label>Observaciones del Movimiento</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="observaciones"
                value={values.observaciones}
                onChange={(e)=>{ setFieldValue('observaciones', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.observaciones}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.observaciones}</>} </Form.Control.Feedback>
            </Form.Group>
 



            <Form.Group className="mb-3" controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



          <Row>
            <Form.Group as={Col} md="9" className="mb-3" controlId="colonia">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                type="text"
                required
                name="colonia"
                value={values.colonia}
                onChange={(e)=>{ setFieldValue('colonia', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.colonia}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp">
              <Form.Label>C.P.</Form.Label>
              <Form.Control
                type="text"
                required
                name="cp"
                value={values.cp}
                onChange={(e)=>{ setFieldValue('cp',e.target.value) }}
                isInvalid={!!errors.cp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>






        </Form>
      )}
    </Formik>














        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Crear Movimiento <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalBaja;