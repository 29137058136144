import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'


const ModalCrearDepartamento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const dialog = new Dialog();

  const { show, handleClose, direccion } = params


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

      departamento : yup.string().required(mensajes_validacion_generico),
      clave_departamento : yup.string().required(mensajes_validacion_generico),
      pbr : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `departamento/nuevo_departamento` , { ...registro, iddireccion : direccion.uuid })

          if(res_){
            toast.success('Los cambios se realizaron')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Departamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            departamento : "",
            clave_departamento : "",
            pbr : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group className="mb-3" controlId="clave_departamento">
              <Form.Label>Clave de Departamento</Form.Label>
              <Form.Control
                required
                name="clave_departamento"
                value={values.clave_departamento}
                onChange={(e)=>{ setFieldValue('clave_departamento', e.target.value ) }}
                isInvalid={!!errors.clave_departamento}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.clave_departamento}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="departamento">
              <Form.Label>Departamento</Form.Label>
              <Form.Control
                required
                name="departamento"
                value={values.departamento}
                onChange={(e)=>{ setFieldValue('departamento', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.departamento}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.departamento}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="pbr">
              <Form.Label>PBR</Form.Label>
              <Form.Control
                required
                name="pbr"
                value={values.pbr}
                onChange={(e)=>{ setFieldValue('pbr', e.target.value ) }}
                isInvalid={!!errors.pbr}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.pbr}</>} </Form.Control.Feedback>
            </Form.Group>


 


        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCrearDepartamento;