import React, { useState } from 'react';

import RequestService from "../../services/request.service";


import {Button, Modal, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';

import moment from 'moment'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



const ModalFichaEmpleado = ( params : any ) => {


  const dialog = new Dialog();


  const [ empleado, setEmpleado ] = useState<any>({ movimientos_nomina : [], metadata : {email:'',celular:''}, usuario : { uuid : '' }, info_nomina : { nomina : { nomina : '' }, puesto : { puesto : '' }, departamento : { departamento : '' }, nivel_jerarquico : { nivel_jerarquico : "" }, grupo_funcional : { grupo_funcional : "" } }  })

  const { show, handleClose, uuid_empleado } = params

  const [ cargando_, setCargando ] = useState( false )

  const cargaFicha = async() => {

    try{
      let res = await RequestService.getEndPoint('incidencias/ficha_trabajador/'+uuid_empleado)
      setEmpleado(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }


  const [key, setKey] = useState<any>('informacion_personal');


  React.useEffect(() => {
    cargaFicha()
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());



  const [_startDate, _setStartDate] = useState(new Date());
  const [_endDate, _setEndDate] = useState(new Date());


  const actualizarGafete = ()=>{
    _setStartDate(startDate)
    _setEndDate(endDate)
  }


   const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

 const eliminarMovimiento = async(uuid_movimiento:string)=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.delete('movimiento/eliminar/'+uuid_movimiento)
        if(res.data){
          toast.warning('Este cambio fue notificado al departamento de Recursos Humanos')        
          cargaFicha()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar los días')
      }
    }
  }






  return (
    <>
      <Modal show={show} className="modal-xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>FICHA DE EMPLEADO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }





    
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">


              <div className="card">
                <div className="card-header">
                  Empleado
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                  <img src={`${SERVER_}empleados/foto/${empleado.uuid}?${Math.random().toString(36).substr(2, 9)}`}   style={{width:'100%'}} />
                    <div className="mt-3">
                      <h4>{empleado.nombres} {empleado.paterno} {empleado.materno} </h4>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div className="col-md-8">





    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="informacion_personal" title="Información">




              <div className="card mb-3">
                <div className="card-header">
                  Información Laboral
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Número de Empleado</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.numero_empleado}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Puesto</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.puesto.puesto}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Grupo Funcional</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.grupo_funcional.grupo_funcional}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Nivel Jerárquico</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.nivel_jerarquico.nivel_jerarquico}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Departamento</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.departamento.clave_departamento} - {empleado.info_nomina.departamento.departamento}
                    </div>
                  </div>


                </div>
              </div>





      </Tab>

      <Tab eventKey="movimientos" title="Registro de Incidencias">
        {
          // JSON.stringify(empleado.movimientos_nomina)
        }

        <h4>Registro de Incidencias de la quincena actual del Empleado</h4>

        {
          empleado.movimientos_nomina.length === 0 && (
                <p><i className="fa fa-warning"/> No tiene movimientos registrados para esta Quincena</p>
            )
        }

        {
          empleado.movimientos_nomina.length > 0 && (

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Movimiento</th>
                      <th scope="col">Observaciones</th>
                      <th scope="col">Formato</th>
                      <th scope="col">Fecha</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>

                      {
                        empleado.movimientos_nomina.map((movimiento:any)=>

                          <tr key={ Math.random().toString(36).substr(2, 9) }>
                            <td className="v" style={{width:'150px'}}>{movimiento.idmovimiento.movimiento}</td>
                            <td className="v">

                              {
                                // [1,3].indexOf(movimiento.idmovimiento.id) > -1 && (

                                //     <>
                                //         <dl className="nm">
                                //           <dt>Plaza</dt>
                                //           <dd><small>{movimiento.idempleado_nomina.idnomina.nomina}</small></dd>
                                //           <dt>Puesto</dt>
                                //           <dd><small>{movimiento.idempleado_nomina.idpuesto.clave_puesto} - {movimiento.idempleado_nomina.idpuesto.puesto}</small></dd>
                                //           <dt>Departamento</dt>
                                //           <dd><small>{movimiento.idempleado_nomina.iddepartamento.clave_departamento} - {movimiento.idempleado_nomina.iddepartamento.departamento} </small></dd>
                                //           <dt>Sueldo</dt>
                                //           <dd><small>$ {movimiento.idempleado_nomina.sueldo} </small></dd>
                                //         </dl>
                                //       {
                                //         // JSON.stringify(movimiento.idempleado_nomina.idnomina.nomina)
                                //       }
                                //     </>

                                // )
                              }


                              {
                                  movimiento.observaciones.toUpperCase()
                              }
                            

                              {
                                movimiento.idmovimiento.id === 4 && (
                                  <>
                                    {
                                      movimiento.idempleado_nomina.cambio_adscripcion && (
                                          <>
                                            <dl className="nm">
                                              <dt>Departamento</dt>
                                              <dd><small>{movimiento.idempleado_nomina.iddepartamento.clave_departamento} - {movimiento.idempleado_nomina.iddepartamento.departamento} </small></dd>
                                            </dl>
                                          </>
                                      )
                                    }
                                    {
                                      movimiento.idempleado_nomina.cambio_puesto && (
                                          <>
                                            <dl className="nm">
                                              <dt>Puesto</dt>
                                              <dd><small>{movimiento.idempleado_nomina.idpuesto.clave_puesto} - {movimiento.idempleado_nomina.idpuesto.puesto}</small></dd>
                                            </dl>
                                          </>
                                      )
                                    }
                                    {
                                      movimiento.idempleado_nomina.cambio_plaza && (
                                          <>
                                            <dl className="nm">
                                              <dt>Plaza</dt>
                                              <dd><small>{movimiento.idempleado_nomina.idnomina.nomina}</small></dd>
                                            </dl>
                                          </>
                                      )
                                    }
                                    {
                                      movimiento.idempleado_nomina.cambio_sueldo && (
                                          <>
                                            <dl className="nm">
                                              <dt>Sueldo</dt>
                                              <dd><small>$ {movimiento.idempleado_nomina.sueldo} </small></dd>
                                            </dl>
                                          </>
                                      )
                                    }
                                  </>
                                )
                              }
                            



                            </td>
                            <td className="text-center v" style={{width:'50px'}}>



                            

                              {
                                [1].indexOf(movimiento.idmovimiento.id) > -1 && ( <a className="btn btn-link btn-primary" target="_blank" href={SERVER_+`fichas/alta/${movimiento.idempleado_nomina.uuid}`} role="button"><i className="fa fa-file-pdf-o"/></a> )
                              }


                              <a className="btn btn-link btn-primary" target="_blank" href={`${SERVER_}ficha/${movimiento.uuid}`} role="button"><i className="fa fa-file-pdf-o"/></a>


                            </td>
                            <td className="text-center v" style={{width:'150px'}}>{moment(movimiento.fecha_movimiento).format('YYYY-MM-DD')}</td>
                            <td className="text-center v" style={{width:'50px'}}>
                              <Button size="sm" onClick={(e:any)=>{eliminarMovimiento(movimiento.uuid)}} variant="danger"><i className="fa fa-trash"/></Button>
                            </td>
                          </tr>

                          )
                      }

                  </tbody>
                </table>


            )
        }





      </Tab>
      <Tab eventKey="gafete" title="Gafete">


        <div className="mb-3">
          <label className="form-label">Vigencia del Gafete</label>
          <div className="text-center">
            <DatePicker onChange={onChangeRango} className="form-control" dateFormat="dd/MM/yyyy" startDate={startDate} endDate={endDate} selectsRange />
          </div>

        </div>

        <button type="submit" onClick={actualizarGafete} className="btn btn-primary">Actualizar Gafete</button>



      <iframe src={`${SERVER_}gafete/${empleado.uuid}/${_startDate}/${_endDate}`} className="mt-4" width={'100%'} height={700} ></iframe>




      </Tab>
    </Tabs>






            </div>
          </div>






        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalFichaEmpleado;