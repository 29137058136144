import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'


const ModalNuevaRecepcion = ( params : any ) => {

  const dialog = new Dialog();

  const mensajes_validacion_generico = 'Este campo es obligatorio'

  const { show, handleClose } = params


  const schema = yup.object().shape({

          // folio : yup.string().required(mensajes_validacion_generico),
          remitente : yup.string().required(mensajes_validacion_generico),
          asunto : yup.string().required(mensajes_validacion_generico),
          observacion : yup.string().nullable(),
          iddepartamento_turnado : yup.string().required(mensajes_validacion_generico),
          numero_oficio : yup.string().required(mensajes_validacion_generico),
          fecha_recepcion : yup.string().required(mensajes_validacion_generico),
          fecha_oficio : yup.string().required(mensajes_validacion_generico),

  });


const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (nuevo_registro:any,event:any,) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.create(`recepcion/registro_oficio`, { ...nuevo_registro, folio : "XXX", fecha_recepcion : moment(nuevo_registro.fecha_recepcion).format('YYYY-MM-DD'), fecha_oficio : moment(nuevo_registro.fecha_oficio).format('YYYY-MM-DD') })

        if(res_nuevo_usuario){
          toast.success('La recepción de oficio de registró Correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  React.useEffect(() => {
    cargarTurnados() 
  }, []);


  const [ turnados, setTurnados] = useState<any>([])

  const cargarTurnados = async() => {

    try{
      let res = await RequestService.getEndPoint('listado_turnados')
      setTurnados(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de turnados')
    }

  }


  const sugerencias = [
`LIC. VICENTE EXIQUIO CRUZ MARTÍNEZ
SECRETARIA DEL AYUNTAMIENTO`,
`LIC. ERIKA ASUNCION CHI ORLAYNETA
TESORERIA MUNICIPAL`,
`LIC. MIJAIL ALBERTO BASTARRACHEA CASTILLO
ORGANO INTERNO DE CONTROL`,
`LIC. CARLOS E MANUEL ROSADO ZETINA
DIRECCIÓN GENERAL DE ADMINISTRACIÓN Y PLANEACIÓN ESTRATEGICA`,
`LIC. ALFONSO ALEJANDRO DURAN REYES
DIRECCIÓN GENERAL DE ASUNTOS JURIDICOS Y TRANSPARENCIA`,
`MAP. JOSELINE DE LA LUZ UREÑA TUZ
DIRECCIÓN GENERAL DE INFRAESTRUCTURA Y SERVICIOS CIUDADANOS`,
`LIC. WENDY AURORA MAGAÑA POLANCO
DIRECCIÓN GENERAL DE DESARROLLO URBANO Y ECONÓMICO SOSTENIBLE`,
`C.P. ARIADNA DE CORDOVA VEGA
DIRECCIÓN GENERAL DE FOMENTO PARTICIPATIVO Y DESARROLLO HUMANO`,
`ING. GIOVANNI PERICO FURNARI
OFICINA DE LA PRESIDENCIA`,
`LIC. REYNALDO OMAR GARMA REUDA
DIRECCIÓN DE GOBERNANZA Y OFICIALIA DE PARTES LEGALES`,
`ING. GUADALUPE DEL CARMEN RODRIGUEZ CHAVEZ
DIRECCIÓN DE PROTECCIÓN CIVIL`,
`M.C. LUIS ABELARDO MENDEZ HAU
DIRECCIÓN DE RECURSOS HUMANOS`,
`LIC. MARGARITA DEL SOCORRO NAVARRETE MONTOY
DIRECCIÓN DE SERVICIOS GENERALES Y RECURSOS MATERIALES`,
`I.C. ELVIRA SARAÍ CAN DZIB
DIRECCIÓN DE PLANEACIÓN Y PROYECTOS DE INVERSIÓN PÚBLICA`,
`LIC. HORACIO GUZMÁN TOMAS
UNIDAD DE TRANSPARENCIA Y ARCHIVOS`,
`LIC. ALAN RAUL VERA MAGAÑA
DIRECCIÓN DE ASESORIA LEGAL`,
`ARQ. JOSÉ LUIS LLOVERA ABREU
DIRECCIÓN DE OBRAS PÚBLICAS`,
`C. JOSÉ MANUEL RIVERO ARROYO
DIRECCIÓN DE SERVICIOS PÚBLICOS`,
`LIC. ELEAZAR HERRERA VÁZQUEZ
DIRECCIÓN DE MERCADOS Y RASTRO`,
`C.P. KARLA GISELLE RODRIGUEZ TEC
DIRECCIÓN DE DESARROLLO URBANO, CATASTRO Y MEDIO AMBIENTE SUSTENTABLE`,
`ING. CARLOS JOSÉ MACOSSAY RODRIGUEZ
DIRECCIÓN DE DESARROLLO ECONÓMICO Y TURISMO`,
`LIC. LUIS FELIPE PALOMO NARVÁEZ
SUBDIRECTOR DE DESARROLLO ARTÍSTICO Y CULTURA`,
`LIC. DANIELA MACOSSAY RODRIGUEZ
DIRECCIÓN DE FOMENTO AL DESARROLLO SOCIAL Y HUMANO`,
`LIC. CARLOS ESTEBAN SOLIS GARCÍA
DIRECCIÓN DE ATENCIÓN Y PARTICIPACIÓN CIUDADANA`,
`C. IVÁN FRANCISCO CU CANTUN
SECRETARIO GENERAL SUPLENTE SUTSGM`,
`C. MIGUEL ÁNGEL GARCÍA AGUILAR
SUPLENTE DEL SECRETARIO GENERAL DEL SSTSA`,
`MTRA. MARÍA ASUNCIÓN CABALLERO MAY
DIRECTORA GENERAL DEL DIF MUNICIPAL`
    ]

const SPLIT = `
`

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Recepción de Oficios</Modal.Title>
        </Modal.Header>
        <Modal.Body>



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            remitente : "",
            asunto : "",
            observacion : "",
            iddepartamento_turnado : "",
            numero_oficio : "",
            fecha_recepcion : new Date(),
            fecha_oficio : new Date(),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }



            <div className="row">
              <div className="col-sm-12">
                  <Form.Group   className="mb-3" controlId="folio">
                    <Form.Label>Número oficio</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="numero_oficio"
                      value={values.numero_oficio}
                      onChange={(e)=>{ setFieldValue('numero_oficio', (e.target.value || "").toUpperCase()) }}
                      isInvalid={!!errors.numero_oficio}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {errors.numero_oficio} </Form.Control.Feedback>
                  </Form.Group>
              </div>
            </div>







            <Form.Group   className="mb-3" controlId="remitente">
              <Form.Label>Remitente</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="remitente"
                value={values.remitente}
                onChange={(e)=>{ setFieldValue('remitente', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.remitente}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.remitente} </Form.Control.Feedback>

                <Form.Text id="passwordHelpBlock" muted>
                        
                  <OverlayTrigger trigger="click" placement="bottom" overlay={
                    <Popover id="popover-basic">
                      <Popover.Header as="h3">Sugerencia de Remitente</Popover.Header>
                      <Popover.Body style={{padding: '3px'}}>
                        <div style={{maxHeight:'250px',overflow:'auto'}}>
                          <ul className="list-group list-group-flush">
                            { sugerencias.map((sugerencia:any)=> <li key={ Math.random().toString(36).substr(2, 9) } className="list-group-item pointer" onClick={()=>{setFieldValue('remitente',sugerencia)}}><small>{sugerencia.split(SPLIT)[0]}</small></li> ) }
                          </ul>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }>
                    <Button size="sm" variant="link">Puede seleccionar un remitente de la siguiente lista sugerida</Button>
                  </OverlayTrigger>

                </Form.Text>


            </Form.Group>

            <Form.Group   className="mb-3" controlId="asunto">
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="asunto"
                value={values.asunto}
                onChange={(e)=>{ setFieldValue('asunto', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.asunto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.asunto} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group   className="mb-3" controlId="asunto">
              <Form.Label>Observación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="observacion"
                value={values.observacion}
                onChange={(e)=>{ setFieldValue('observacion', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.observacion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.observacion} </Form.Control.Feedback>
            </Form.Group>



            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha del Oficio</Form.Label>
                    <DatePicker selected={values.fecha_oficio} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_oficio', date ) } />
                  </div>
              </div>
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Recepción</Form.Label>
                    <DatePicker selected={values.fecha_recepcion} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_recepcion', date ) } />
                  </div>
              </div>
            </div>

            <Form.Group   controlId="iddepartamento_turnado">
              <Form.Label>Turnado A </Form.Label>
                <select className="form-select" value={values.iddepartamento_turnado} required name="iddepartamento_turnado" onChange={(e)=>{ setFieldValue('iddepartamento_turnado', e.target.value) }}>
                  <option value="" disabled>Selecciona una opción</option>
                  { turnados.map( (turnado:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={turnado.uuid}>{turnado.clave_departamento} {turnado.departamento} </option> ) }
                </select>
              <Form.Control
                type="hidden"
                required
                name="iddepartamento_turnado"
                value={values.iddepartamento_turnado}
                isInvalid={!!errors.iddepartamento_turnado}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.iddepartamento_turnado  } </Form.Control.Feedback>
            </Form.Group>


{
  // JSON.stringify(turnados)
}




        </Form>
      )}
    </Formik>










        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaRecepcion;