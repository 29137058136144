import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import SignatureCanvas from 'react-signature-canvas'

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'

import './firma.scss'


const ModalFirmas = ( params : any ) => {

  const ref = React.createRef()

  const dialog = new Dialog();

  const { show, handleClose, tipo_usuario } = params

  const [ canvas, sigCanvas ] = useState<any>({})

  const [ tiene_algo, setTieneAlgo ] = useState(false)




  const actualizarConfig = async (e:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)

          let data_ = {}
          if(tipo_usuario=='director_general') data_ = { director_general_firma : canvas.toDataURL() }
          if(tipo_usuario=='jefe_departamento') data_ = { jefe_departamento_firma : canvas.toDataURL() }
          if(tipo_usuario=='director_rh') data_ = { director_rh_firma : canvas.toDataURL() }
          // if(tipo_usuario=='coordinador_horas_faltas') data_ = { coordinador_hora_faltas_firma : canvas.toDataURL() }
            console.log(data_)
          let res = await RequestService.update('configuracion', data_ )
          toast.warning('La información se actualizó correctamente')
          handleClose()

    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }


  const borrar = () =>{
    canvas.clear()
    setTieneAlgo(false)
  }




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Firma</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          { tipo_usuario == 'director_general' && ( <h6>Director General</h6> ) }
          { tipo_usuario == 'coordinador_planeacion' && ( <h6>Coordinación de Planeación</h6> ) }
          { tipo_usuario == 'director_rh' && ( <h6>Director de Recursos Humanos</h6> ) }
          {/*{ tipo_usuario == 'coordinador_hora' && ( <h6>Director General</h6> ) }*/}

          <SignatureCanvas penColor='#000000' canvasProps={{width: 270, height: 200, className: 'sigCanvas'}} ref={(ref:any) => { sigCanvas(ref) }} onEnd={(a:any)=>{setTieneAlgo(true); }} />





        </Modal.Body>
        {
          tiene_algo && (
                <Modal.Footer>
                  <Button variant="warning" onClick={borrar}>
                    <i className="fa fa-eraser"/>
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button variant="success" onClick={actualizarConfig} type="button">Ok</Button>
                </Modal.Footer>
            )
        }

      </Modal>
    </>
  );
}


export default ModalFirmas;