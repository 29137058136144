import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify'


import moment from 'moment'


import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



const REG_CURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
const REG_RFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
const REG_NOMBRES = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
const REG_SS = /^(\d{2})(\d{2})(\d{2})\d{5}$/

const curp2date = (curp:any) => {
  var m = curp.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
  //miFecha = new Date(año,mes,dia) 
  var anyo = parseInt(m[1],10)+1900;
  if( anyo < 1950 ) anyo += 100;
  var mes = parseInt(m[2], 10)-1;
  var dia = parseInt(m[3], 10);
  return moment(new Date( anyo, mes, dia )).format('YYYY-MM-DD');
}


const ModalCorreccionDatos = ( params : any ) => {

  const { show, handleClose, empleado } = params

  const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

var mensaes_validacion_generico = 'Este campo es obligatorio'

const schema = yup.object().shape({

  curp: yup.string().required(mensaes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  rfc: yup.string().required(mensaes_validacion_generico).matches( REG_RFC , "Formato de RFC invalido").required('El RFC es un campo obligatorio'),
  observaciones: yup.string(),
  fecha_ingreso: yup.string().required(),

});

const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{

    let movimientos_detectados = {
      rfc : false,
      curp : false,
      fecha_movimiento : false,
    }

    if(moment(nuevo_usuario.fecha_ingreso).format('YYYY-MM-DD')!==moment(empleado.fech_alta).format('YYYY-MM-DD')) movimientos_detectados.fecha_movimiento = true
    if(nuevo_usuario.rfc!==empleado.rfc) movimientos_detectados.rfc = true
    if(nuevo_usuario.curp!==empleado.curp) movimientos_detectados.curp = true

      // console.log(movimientos_detectados)

      if(!movimientos_detectados.fecha_movimiento && !movimientos_detectados.curp && !movimientos_detectados.rfc){
        toast.warning('Debes hacer alguna modificación para poder hacer el registro de corrección')
        return
      }

      await dialog.open({
        accept: 'Actualizar los datos de Empleado',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        // soundAccept: 'https://assets.stoumann.dk/audio/accept.mp3',
        // soundOpen: 'https://assets.stoumann.dk/audio/open.mp3',
        target: event.target,
        template: `
          <dl>
            <dt>Nombre</dt>
            <dd>`+empleado.nombres+` `+empleado.paterno+` `+empleado.materno+`</dd>
            <dt>CURP</dt>
            <dd>`+empleado.curp+`</dd>
            <dt>RFC</dt>
            <dd>`+empleado.rfc+`</dd>
            <dt>Fecha de Ingreso</dt>
            <dd>`+moment(empleado.fech_alta).format('DD/MM/YYYY')+`</dd>
          </dl>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.create(`empleado/actualizar_informacion/${empleado.uuid_ciudadano}`, { ...nuevo_usuario, movimientos_detectados : movimientos_detectados  })

        if(res_nuevo_usuario){
          toast.success('La información del empleado fue actualizada correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Corrección de Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }

          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-12">
              <small>Número de Empleado</small>
              <h6>{empleado.numero_empleado}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>




                <hr className="border border-primary border-3 opacity-75 mb-4"/>


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

      curp:  empleado.curp,
      rfc:  empleado.rfc,
      observaciones:  "",
      fecha_ingreso:  empleado.fech_alta ? new Date(moment(empleado.fech_alta).toString()) : new Date(),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values.fecha_ingreso)
}
{
  // JSON.stringify(empleado.fech_alta)
}

          <Row >
            <Form.Group  className="mb-3" controlId="curp">
              <Form.Label>CURP </Form.Label>
              <Form.Control
                type="text"
                required
                name="curp"
                value={values.curp}
                onChange={(e)=>{ setFieldValue('curp', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.curp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Col  >
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Sexo</label>
                <input type="text" readOnly value={values.curp.substr(10,1)==='H'?'HOMBRE':'MUJER'} className="form-control" />
              </div>
                )
              }
            </Col>
            <Col  >
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Fecha de Nacimiento</label>
                <input type="text" readOnly value={curp2date(values.curp)} className="form-control" />
              </div>
                )
              }
            </Col>
          </Row>


          <Row >

            <Form.Group   className="mb-3" controlId="rfc">
              <Form.Label>RFC </Form.Label>
              <Form.Control
                type="text"
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.rfc}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          </Row>




          <Row className="mb-3">
            <Col>
              <Form.Label>Fecha de Ingreso</Form.Label>
              <DatePicker selected={values.fecha_ingreso} className="form-control" dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_ingreso', date ) } />
            </Col>
          </Row>



                  <Form.Group className="mb-3" controlId="observaciones">
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control
                      type="textarea"
                      required
                      as="textarea"
                      rows={4}
                      name="observaciones"
                      value={values.observaciones}
                      onChange={(e)=>{ setFieldValue('observaciones', e.target.value.toUpperCase() ) }}
                      isInvalid={!!errors.observaciones}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {errors.observaciones} </Form.Control.Feedback>
                  </Form.Group>



 




                <Button className="btn-lg" type="submit">Aplicar Movimiento de Corrección</Button>
          
        </Form>
      )}
    </Formik>





        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalCorreccionDatos;