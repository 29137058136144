import moment from 'moment'


export const calcularDiferenciaEnDias = (fechaInicial:any, fechaFinal:any) => {
  const unDia = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día

  const fecha1 = fechaInicial;
  const fecha2 = fechaFinal;

  const diferenciaEnMilisegundos = Math.abs(fecha2 - fecha1);
  const diferenciaEnDias = Math.round(diferenciaEnMilisegundos / unDia);

  return diferenciaEnDias;
}

export const formatearNumeroConComas = (numero:any) => {
  // Convertimos el número a cadena primero para asegurarnos de que es un número válido
  const numeroFormateado = parseFloat(numero).toFixed(2).toString();

  // Separamos los enteros de los decimales
  const partes = numeroFormateado.split(".");

  // Separamos los enteros con comas
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Unimos nuevamente las partes con el punto decimal
  return partes.join(".");
}


export const numberWithCommas = (x:any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}






export const anios_disponibles : any = [ moment().format('YYYY'), ...[ moment().format('YYYY') ].map((anio:any,i:number)=>{ return anio - (i+1) }) ]




export const curp2date = (curp:any) => {
  var m = curp.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
  //miFecha = new Date(año,mes,dia) 
  var anyo = parseInt(m[1],10)+1900;
  if( anyo < 1950 ) anyo += 100;
  var mes = parseInt(m[2], 10)-1;
  var dia = parseInt(m[3], 10);
  return moment(new Date( anyo, mes, dia )).format('YYYY-MM-DD');
}



export const REG_CURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
export const REG_RFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
export const REG_NOMBRES = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
export const REG_SS = /^(\d{2})(\d{2})(\d{2})\d{5}$/
