import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import {Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';


import Dialog from '../../services/Dialogs'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



import { SERVER_ } from  '../../config';


import ModalDetalleOficio from './ModalDetalleOficio'

import ModalResponderOficio from './ModalResponderOficio'


import AuthService from '../../features/auth/auth.service'



const MisOficios = () =>{


  const user = AuthService.getCurrentUser()


  const dialog = new Dialog();

  const [ reporte, setRegistro ] = useState<any>([])

  const [ cargando_, setCargando ] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());





      const cargar = async () =>{
        try{
            setCargando(true)
            let res = await RequestService.getEndPoint(`mis_oficios/${tipo_filtro}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
            setRegistro(res.data)
            setStartDate_(startDate)
            setEndDate_(endDate)
            setCargando(false)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };



  const [ oficio, setOficioSeleccionado ] = useState<any>({ })

  const [ show_ModalNuevaRecepcion, setModalNuevaRecepcion ] = useState<boolean>(false)
  const handleShow_ModalNuevaRecepcion = () => {
    setModalNuevaRecepcion(true)
  }
  const handleClose_ModalNuevaRecepcion = () => {
    setModalNuevaRecepcion(false)
  }

  const [ show_ModalDetalleOficio, setModalDetalleOficio ] = useState<boolean>(false)
  const handleShow_ModalDetalleOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalDetalleOficio(true)
  }
  const handleClose_ModalDetalleOficio = () => {
    setModalDetalleOficio(false)
  }


  const [ show_ModalEditarOficio, setModalEditarOficio ] = useState<boolean>(false)
  const handleShow_ModalEditarOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalEditarOficio(true)
  }
  const handleClose_ModalEditarOficio = () => {
    cargar()
    setModalEditarOficio(false)
  }


 const eliminarOficio = async(uuid_oficio:string)=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.delete('oficio/'+uuid_oficio)
        if(res.data){
          toast.warning('Este oficio fue eliminado de la lista de recepción')        
          cargar()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar los días')
      }
    }
  }

// let addDays = (days) => {
//   let result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result.toISOString().split('T')[0];
// }
  const fecha : any = (new Date()).setDate((new Date()).getDate() + 15 )


  const [ tipo_filtro, setTipoFiltro ] = useState<any>('fecha_recepcion')



  const [ show_ModalResponderOficio, setModalResponderOficio ] = useState<boolean>(false)
  const handleShow_ModalResponderOficio = (oficio:any) => {
    setOficioSeleccionado(oficio)
    setModalResponderOficio(true)
  }
  const handleClose_ModalResponderOficio = () => {
    setModalResponderOficio(false)
  }



      return (
          <>


                  <div className="container">
              
                      <h3>Recepcion de Oficios</h3>

                      <div className="row">
                        <div className="col-sm-3">



                                <label className="form-label">Fecha del Reporte</label>
                                <div className="text-center mb-2">
                                  <DatePicker onChange={onChangeRango} className="form-control" inline dateFormat="dd/MM/yyyy" maxDate={fecha} startDate={startDate} endDate={endDate} selectsRange />
                                </div>

                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={tipo_filtro==='fecha_recepcion'} onChange={(e:any)=>{setTipoFiltro('fecha_recepcion')}}  />
                                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Por Fecha de Recepción
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={tipo_filtro==='fecha_oficio'} value="fecha_oficio" onChange={(e:any)=>{setTipoFiltro('fecha_oficio')}} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Por Fecha de Oficio
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={tipo_filtro==='fecha_creacion'} value="fecha_creacion" onChange={(e:any)=>{setTipoFiltro('fecha_creacion')}} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                                    Por Fecha de Captura
                                  </label>
                                </div>

                                <Button onClick={cargar} variant="link">Actualizar Lista</Button>






                        </div>
                        <div className="col-sm-9">
                          


                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                                {


                                  !cargando_ && (

                                      <>

                                                  <h4 className="nm-">
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                                    }
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                                    }
                                                  </h4>



                                        {

                                          
                                            // JSON.stringify(user.rol)
                                          

                                        }


                                        {
                                          reporte.length === 0 && (

                                              <p><i className="fa fa-warning" /> No hay oficios capturados para ese rango de fecha</p>

                                          )
                                        }



                                        {
                                          reporte.length > 0 && (

                                            <table className="table">
                                              <thead style={{fontSize:10}}>
                                                <tr>
                                                  <th className="v" scope="col">Folio <br/> Número de Oficio</th>
                                                  <th className="v" scope="col">Remitente</th>
                                                  { user.rol ==='ROLE_ADMIN' && ( <th className="v" scope="col">Fecha Respuesta</th> ) }
                                                  <th className="v" scope="col"></th>

                                                </tr>
                                              </thead>
                                              <tbody>
                                              {
                                                reporte.map( (oficio:any)=>
                                                        <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                          <th className="pointer" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}} scope="row" style={{width:'100px'}}>
                                                            <p className="nm"><b>{oficio.folio}</b></p>
                                                            <small>{oficio.numero_oficio}</small>
                                                          </th>
                                                          
                                                          <td className="pointer" onClick={(e)=>{handleShow_ModalDetalleOficio(oficio)}}>
                                                            <b><small>{oficio.remitente}</small></b>
                                                            <p className="nm"><small>{oficio.asunto}</small></p>
                                                          </td>

                                                          {  
                                                            user.rol ==='ROLE_ADMIN' && (
                                                                <td className="v" style={{width:50}}>
                                                                    { oficio.fecha_respuesta && ('SI') }
                                                                    { !oficio.fecha_respuesta && ('') }
                                                                </td> 
                                                              )
                                                          }

                                                          {  
                                                            oficio.fecha_respuesta && user.rol !=='ROLE_ADMIN' && (
                                                                <td className="v" style={{width:50}}>
                                                                  
                                                                </td>
                                                              )
                                                          }


                                                          {  
                                                            !oficio.fecha_respuesta && user.rol !=='ROLE_ADMIN' && (
                                                                <td className="v" style={{width:50}}>
                                                                  <Button size="sm" onClick={(e:any)=>{handleShow_ModalResponderOficio(oficio)}} variant="primary"><i className="fa fa-check"/></Button>
                                                                </td>
                                                              )
                                                          }




                                                        </tr>
                                                 )
                                              }

                                              </tbody>
                                            </table>

                                          )
                                        }






                                    </>
                                    )
                                }

                        </div>
                      </div>
              
                  </div>


  {
    show_ModalDetalleOficio && (
      <ModalDetalleOficio show={show_ModalDetalleOficio} handleClose={handleClose_ModalDetalleOficio} oficio={oficio} />
      )

  }


  {
    show_ModalResponderOficio && (
      <ModalResponderOficio show={show_ModalResponderOficio} handleClose={handleClose_ModalResponderOficio} oficio={oficio} />
      )

  }

          </>

      );
  

}

export default MisOficios
